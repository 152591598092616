import FuelBuyerDashboardPageTitle from "../../../components/PageTitle";
import PlainFuelStationDashboardLayout from "components/layout/FuelStation/plainFuelStationDashboardContainer";
import EmptyTable from "components/pages/authenticated-new/pages/Events/shared/EmptyTable";
import Avatar from "components/shared/Avatar";
import { Button } from "components/shared/Button";
import TableSkeleton from "components/shared/TableSkeleton";
import DefaultDeleteModal from "components/shared/modal/deleteModal";
import DefaultTable from "components/shared/tables/defaultTable";
import TableActionIcons from "components/shared/tables/tableActionIcons";
import { FUEL_BUYER_DRIVER_TABLEHEADER } from "constants/tableHeaderConstants";
import { TABLE_STYLE_ENUMS } from "enums/styleEnums";
import usePagination from "hooks/usePagination";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  deleteDriverRequest,
  fetchPaginatedDriversRequest,
} from "state/slices/fuel";
import { getOneLinerErrorMessage } from "utilities";

const FuelBuyerDriversTablePage = () => {
  const [activeDriver, setActiveDriver] = useState({});
  const [search, setSearch] = React.useState("");
  const { user } = useSelector((state) => state.users.data);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const showDeleteModal = (driver) => {
    setActiveDriver(driver);
    setOpenDeleteModal(true);
  };

  const { data, pager, loading, refetch } = usePagination({
    dataSelector: "",
    request: fetchPaginatedDriversRequest,
    customQueryPayload: {},
    customQueryParams: {
      ...(search ? { search } : {}),
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const deleteDriver = async () => {
    setIsLoading(true);
    const resultAction = await dispatch(deleteDriverRequest(activeDriver));
    if (deleteDriverRequest.fulfilled.match(resultAction)) {
      setIsLoading(false);
      toast.success("Successfully deleted driver");
      refetch();
      setOpenDeleteModal(false);
    } else {
      setIsLoading(false);
      if (resultAction.payload.message) {
        toast.error(resultAction.payload.message);
      } else {
        toast.error(
          getOneLinerErrorMessage(resultAction.payload) ||
            resultAction.error?.message ||
            "An error occurred, please try again."
        );
      }
    }
  };

  const handleSearch = (e) => setSearch(e.target.value);
  return (
    <>
      <DefaultDeleteModal
        isActive={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        onAction={deleteDriver}
        loading={isLoading}
      />

      <div>
        <div className="tw-flex tw-justify-between">
          <FuelBuyerDashboardPageTitle
            title="Registerd Drivers"
            description="list of your registered drivers"
          />

          <div className="tw-flex tw-gap-2">
            <input
              type="search"
              value={search}
              className="!tw-h-full !tw-min-h-full !tw-w-72 tw-border !tw-rounded-md tw-input"
              placeholder="Search"
              onChange={handleSearch}
            />

            <Link to="/dashboard/fuel-station/drivers/create">
              <Button className="tw-bg-green-500 tw-text-white">
                ADD DRIVER
              </Button>
            </Link>
          </div>
        </div>

        <PlainFuelStationDashboardLayout>
          <div className="tw-mt-6">
            <div className="tw-py-4 tw-text-base">
              {loading || data?.length > 0 ? (
                <div>
                  <DefaultTable headerData={FUEL_BUYER_DRIVER_TABLEHEADER}>
                    {loading ? (
                      <TableSkeleton rows={10} columns={6} />
                    ) : (
                      <tbody className={TABLE_STYLE_ENUMS.TABLE_BODY}>
                        {data.map((driver, Idx) => (
                          <tr className="tw-border-b" key={Idx}>
                            <td className="tw-px-3 tw-py-3 tw-w-48">
                              <div className="tw-flex tw-items-center tw-gap-3 ">
                                <Avatar
                                  src={driver.photoUrl}
                                  alt="Drivers Photo"
                                />
                                {driver.name}
                              </div>
                            </td>
                            <td className="tw-px-3 tw-py-3 tw-w-48">
                              {driver.email}
                            </td>
                            <td className="tw-px-3 tw-py-3">
                              {driver.licenseNo}
                            </td>
                            <td className="tw-px-3 tw-py-3">
                              <a
                                href={driver.licenseUrl}
                                rel="noreferrer"
                                target="_blank"
                              >
                                View here
                              </a>
                            </td>

                            <td className="tw-px-3 tw-py-3">
                              <TableActionIcons
                                editPath={`/dashboard/fuel-station/drivers/edit/${driver.id}`}
                                onDelete={() => showDeleteModal(driver.id)}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </DefaultTable>

                  {pager}
                </div>
              ) : (
                <EmptyTable
                  title="No Driver(s) Available"
                  description=" Once any driver(s) is added, they will appear here."
                  className="tw-mb-6"
                />
              )}
            </div>
          </div>
        </PlainFuelStationDashboardLayout>
      </div>
    </>
  );
};

export default FuelBuyerDriversTablePage;
