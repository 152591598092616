import VehiclesRegPanel from "../../../components/shared/drivers/vehiclesRegPanel";
import {
  ContentCopyIcon,
  FuelSuccessShake,
  PrintIcon,
  ShareIcon,
} from "assets/icons";
import Avatar from "components/shared/Avatar";
import { Button } from "components/shared/Button";
import React from "react";
import usePagination from "hooks/usePagination";
import { fetchFuelPurchaseByDetailsWithTxnIdRequest } from "state/slices/fuel";
import { useSearchParams } from "react-router-dom-v5-compat";
import { toast } from 'react-toastify';
import useCopyToClipboard from "hooks/useCopyToClipboard";

const FuelBuyerGenerateCode = () => {
  const initWindow = typeof window !== "undefined" && window;
  const printPage = () => initWindow.print().search; 

  const [searchParams] = useSearchParams();

  const tx_ref = searchParams.get('tx_ref');
  const transactionId = searchParams.get('transaction_id');
  const status = searchParams.get('status');

  const { data , isLoading } = usePagination({
    dataSelector: "",
    request: fetchFuelPurchaseByDetailsWithTxnIdRequest,
    customQueryPayload: {
       id: typeof tx_ref === 'string' && tx_ref !== '' ? tx_ref : undefined
    },
  });

  const { driver , customer } = data || {}

  const [_, copy] = useCopyToClipboard();

  const handleCopyClick = async () => {
    if (data?.code) {
      const isCopied = await copy(data.code);
      if (isCopied) {
        toast.success("Code copied to clipboard");
      } else {
        toast.error("Failed to copy code");
      }
    } else {
      toast.error("No code available to copy");
    }
  };

  const handleShareClick = async () => {
    if (data?.code) {
      if (navigator.share) {
        try {
          await navigator.share({
            title: 'Fuel Purchase Code',
            text: `Here's your fuel purchase code: ${data.code}`,
            url: window.location.href
          });
          toast.success("Code shared successfully");
        } catch (error) {
          console.error('Error sharing:', error);
          toast.error("Failed to share code");
        }
      } else {
       
        await handleCopyClick();
        toast.info("Share option not available. Code copied to clipboard instead.");
      }
    } else {
      toast.error("No code available to share");
    }
  };

  console.log(data)

  return (
    <div className="tw-w-full tw-flex tw-mt-10 tw-justify-center tw-mx-auto md:tw-w-[538px] tw-bg-white tw-py-8 tw-px-5 md:tw-px-8 tw-rounded-lg">
      <div>
        <h4 className="tw-text-secondary-800 tw-text-md tw-text-medium tw-text-center">
          Transaction Successful!
        </h4>
        <center>
          <FuelSuccessShake />
        </center>

        <div
          className="tw-bg-white tw-w-full tw-flex tw-items-center tw-justify-between tw-py-5 tw-px-5 tw-rounded-lg"
          style={{
            boxShadow: "0 4px 16px  0 rgba(0, 0, 0, 0.1)",
          }}
        >
          <div className="tw-m-0 tw-flex tw-items-center tw-gap-3 tw-cursor-pointer" onClick={handleShareClick}>
            <ShareIcon />
            <p className="tw-text-semibold tw-m-0">Share</p>
          </div>

          <h4 className="tw-font-semibold tw-m-0">{data?.code || 'Loading code...'}</h4>

          <div className="tw-cursor-pointer" onClick={handleCopyClick}>
            <ContentCopyIcon />
          </div>
        </div>

        <div className="tw-w-[80%] tw-mx-auto tw-my-8 ">
          <p className=" sm:tw-text-sm md:tww-text-md tw-text-secondary-700 tw-text-center">
            Here is your order details, copy and share to the respective
            reciepient
          </p>
        </div>

        <div className="tw-flex tw-items-center tw-mb-5 tw-gap-5">
          <VehiclesRegPanel bg="white" licenseNumber={driver?.licenseNo || 'Loading...'} />

          <div className="  tw-border-1-gray-200 tw-w-full tw-h-[124px] tw-flex tw-justify-center  tw-w-[120px] tw-h-full tw-rounded-lg tw-border tw-px-3 tw-py-3">
            <div className="tw-text-center ">
              <center>
                <Avatar
                  size="md"
                  className={"tw-my-3"}
                  src={driver?.photoUrl || 'https://api.dicebear.com/9.x/icons/svg?seed=Mimi'}
                />
              </center>
              <h6 className="tw-font-semibold">{data?.driver?.name}</h6>
            </div>
          </div>
        </div>

        {/* selected fuel station section */}
        <div
          className="tw-w-full tw-py-6 tw-rounded-lg"
          style={{
            boxShadow: "0 4px 16px  0 rgba(0, 0, 0, 0.1)",
          }}
        >
          <h5 className="tw-font-medium tw-px-6  tw-pb-">
            Selected Petrol Station
          </h5>
          <hr />
          <div className="tw-mt-5 tw-px-6 ">
            <p className="tw-text-sm">
              Here are the lst of fuel stations that your code is eligible for.
              Note that you cannot use the above code more than once.
            </p>

            <div className="tw-mt-5"></div>
          </div>
        </div>

        <center>
          <Button
            onClick={printPage}
            className="tw-mt-8 tw-bg-secondary-800 tw-text-white"
          >
            <PrintIcon />
            Print Page{" "}
          </Button>
        </center>
      </div>
    </div>
  );
};

export default FuelBuyerGenerateCode;
