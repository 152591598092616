export const ROUTE_ENUMS = {
  HOME: "/",
  LOGIN: "/login",
  REGISTER: "/register",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  ACCOUNT_SETTINGS: "/account-settings",
  DASHBOARD: "/dashboard",
  DASHBOARD_BUSINESS_WALLET: "/dashboard/business-wallet",
  DASHBOARD_FUEL_STATION: "/dashboard/fuel-station",
  DASHBOARD_FUEL_STATION_SETUP: "/dashboard/fuel-station/setup",
  DASHBOARD_FUEL_STATION_VEHICLES: "/dashboard/fuel-station/vehicles",
  DASHBOARD_FUEL_STATION_DRIVERS: "/dashboard/fuel-station/drivers",
  DASHBOARD_FUEL_STATION_PURCHASE: "/dashboard/fuel-station/purchase",
  DASHBOARD_FUEL_STATION_VENDORS: "/dashboard/fuel-vendors",
};
