import { Misc as MiscV2 } from "services/network-v2";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const initialState = {};

export const uploadSingleImageRequest = createAsyncThunk(
  "miscellaneous/uploadSingleImage",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await MiscV2.uploadSingleImage(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const uploadMultipleImagesRequest = createAsyncThunk(
  "miscellaneous/uploadMultipleImages",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await MiscV2.uploadMultipleImages(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchBanksRequest = createAsyncThunk(
  "miscellaneous/fetchBanks",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await MiscV2.fetchBanks(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const validateBankAccountRequest = createAsyncThunk(
  "miscellaneous/validateBankAccount",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await MiscV2.validateBankAccount(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchBusinessWalletTransactionsRequest = createAsyncThunk(
  "events/fetchBusinessWalletTransactions",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await MiscV2.fetchBusinessWalletTransactions(payload);

      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const reportWalletTransactionRequest = createAsyncThunk(
  "events/reportWalletTransaction",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await MiscV2.reportWalletTransaction(payload);

      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const withdrawWalletAmountRequest = createAsyncThunk(
  "miscellaneous/withdrawWalletAmount",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await MiscV2.withdrawWalletAmount(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

const miscSlice = createSlice({
  name: "miscellaneous",
  initialState,
  reducers: {},
  extraReducers: {},
});

export default miscSlice.reducer;
