import PlainFuelStationDashboardLayout from "components/layout/FuelStation/plainFuelStationDashboardContainer";
import { Button } from "components/shared/Button";
import TextInput from "components/shared/forms/TextInput";
import { Field, Form, Formik } from "formik";
import React from "react";

const IndividualFuelStationSetup = () => {
  return (
    <PlainFuelStationDashboardLayout header="Setup">
      <div className="tw-my-5 ">
        <Formik>
          <Form>
            <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-8 tw-gap-y-8">
              <Field
                label="First Name"
                component={TextInput}
                className="tw-input"
                type="text"
                placeholder="Enter fullname"
              />

              <Field
                label="Contact Phone Number"
                component={TextInput}
                className="tw-input"
                type="tel"
                placeholder="Contact Phone Number"
              />
            </div>
          </Form>
        </Formik>
      </div>
      <div className="tw-flex tw-w-full tw-gap-2 tw-justify-end tw-items-center">
        <Button variant="outline ">Cancel</Button>
        <Button variant="primary">Create</Button>
      </div>
    </PlainFuelStationDashboardLayout>
  );
};

export default IndividualFuelStationSetup;
