import InputHelpTooltip from "./InputHelpTooltip";
import { Icon } from "@iconify/react";
import React, { Fragment, useState } from "react";
import { cn } from "utilities/cn";

const TextInput = ({
  className,
  field,
  form: { touched, errors },
  variant,
  appendedComponent: AppendedComponent,
  type,
  label,
  labelClassName,
  helpProps,
  helpDescription,
  required = false,
  asTextArea,
  prependComponent,
  ...props
}) => {
  const hasError = touched[field.name] && errors[field.name];

  const [showPassword, setShowPassword] = useState(false);

  return (
    <Fragment>
      <div>
        {label ? (
          <label
            htmlFor={field.name}
            className={cn("tw-gap-1 tw-flex tw-items-center", labelClassName)}
          >
            <>
              {label}

              <InputHelpTooltip
                helpDescription={helpDescription}
                {...helpProps}
              />

              {required ? <span className="tw-text-red-500">*</span> : ""}
            </>
          </label>
        ) : (
          ""
        )}
        <div className="tw-relative">
          {!asTextArea ? (
            <>
              {prependComponent && (
                <div className="tw-absolute tw-left-0 tw-h-full tw-px-3 tw-py-1 tw-flex tw-items-center tw-justify-center">
                  {prependComponent}
                </div>
              )}
              <input
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                type={showPassword ? "text" : type}
                className={`${
                  className || "form-control font-weight-normal text-base"
                } ${prependComponent && "!tw-pl-8"}`}
                id="description"
                placeholder="Description"
                required={required}
                {...props}
              />
            </>
          ) : (
            <textarea
              name={field.name}
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              className={`${
                className || "form-control font-weight-normal text-base"
              }`}
              id="description"
              placeholder="Description"
              rows="3"
              required={required}
              {...props}
            />
          )}
          {type === "password" && (
            <div className="tw-absolute tw-right-0 tw-top-[35%] tw-mx-3 tw-px-2 password-toggle">
              <span
                onClick={() => setShowPassword(!showPassword)}
                style={{ cursor: "pointer" }}
              >
                {showPassword ? (
                  <Icon icon="ph:eye-light" height={20} />
                ) : (
                  <Icon icon="clarity:eye-hide-line" height={20} />
                )}
              </span>
            </div>
          )}
        </div>
      </div>

      {hasError && (
        <div className="text-danger text-sm font-weight-normal pt-1">
          {errors[field.name]}
        </div>
      )}
    </Fragment>
  );
};

export default TextInput;
