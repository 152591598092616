const { getAllISOCodes } = require("iso-country-currency");

export const countryList = getAllISOCodes().map((each) => {
  return each.countryName;
});

export const ADDRESS_FIELDS = [
  "city",
  "country",
  "state",
  "postalCode",
  "street",
  "address",
];
