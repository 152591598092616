import { RESELL_TICKET_PATH } from "./constants";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchUserEventByIdRequest } from "state/slices/events";
import { getOneLinerErrorMessage } from "utilities";

export const EventFormContext = createContext(null);

const EventFormProvider = ({ children }) => {
  const { user } = useSelector((state) => state.users.data);

  const { pathname = "" } = useLocation();

  const [event, setEvent] = useState({});

  const dispatch = useDispatch();

  const [isSettingUp, setIsSettingUp] = useState(false);

  const params = useParams();

  const history = useHistory();

  const getEventById = useCallback(async () => {
    const payload = { eventId: params.id, userId: user?.id };

    setIsSettingUp(true);

    const resultAction = await dispatch(fetchUserEventByIdRequest(payload));
    if (fetchUserEventByIdRequest.fulfilled.match(resultAction)) {
      setEvent(resultAction?.payload.data);
      setIsSettingUp(false);
    } else {
      setIsSettingUp(false);
      toast.error(
        getOneLinerErrorMessage(resultAction.payload) ||
          resultAction.error?.message ||
          "An error occurred, please try again."
      );
    }
  }, [dispatch, params.id, user]);

  useEffect(() => {
    if (params.id) getEventById();
  }, [getEventById, params.id]);

  const updateEvent = useCallback((newEvent) => {
    setEvent((oldEvent) => ({ ...oldEvent, ...newEvent }));
  }, []);

  const exitFunction = useCallback(() => {
    history.push("/dashboard/organiser/events");
  }, [history]);

  const data = useMemo(() => {
    return {
      asEdit: !!event.id,
      asResellTicket: pathname === RESELL_TICKET_PATH,
      exitFunction,
      isEditPage: params.id,
      event,
      setEvent,
      updateEvent,
      isSettingUp,
    };
  }, [event, exitFunction, isSettingUp, params.id, pathname, updateEvent]);

  return (
    <EventFormContext.Provider value={data}>
      {children}
    </EventFormContext.Provider>
  );
};

export const useEventFormContext = () => {
  const context = useContext(EventFormContext);

  if (context === null) {
    throw new Error(
      "useEventFormContext must be used inside EventFormProvider!"
    );
  }

  return context;
};

export default EventFormProvider;
