import { Button } from "../Button";
import { UserGroup } from "assets/images";
import React from "react";
import { cn } from "utilities/cn";

const EmptyEventData = ({
  title = "No Data",
  className,
  description = "",
  value,
  onClick,
}) => {
  return (
    <div
      className={cn(
        "tw-flex tw-justify-center tw-items-center tw-h-full tw-w-full ",
        className
      )}
    >
      <div className=" tw-w-96  tw-py-6  tw-bg-tertiary-100 tw-rounded-2xl tw-flex tw-flex-col tw-gap-2 tw-justify-center tw-items-center">
        <img src={UserGroup} />
        <span>{title}</span>
        <div className="tw-w-64 tw-text-gray-500 tw-text-sm tw-text-center">
          <span className="tw-text-gray-500 tw-text-sm tw-text-center">
            {description}
          </span>
        </div>

        {value && (
          <Button
            onClick={onClick}
            className="tw-bg-red-800 tw-text-white tw-w-[80%] tw-mb-3"
          >
            {value}
          </Button>
        )}
      </div>
    </div>
  );
};

export default EmptyEventData;
