import { ImageUploadIllustration } from "assets/images";
import { Button } from "components/shared/Button";
import ImageUpload from "components/shared/forms/ImageUpload";
import React from "react";
import { cn } from "utilities/cn";

const LicenseImageForm = ({ photoUrl, addPhotoUrl }) => {
  return (
    <section>
      <div className="tw-mt-8 tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-3 tw-gap-4">
        <div>
          <p className="tw-text-md">Add License Image*</p>
          <ImageUpload
            addNewImages={addPhotoUrl}
            singleImageUrl={photoUrl?.url}
            isSingle
            className="tw-rounded-md tw-border-none"
            maxFiles={1}
          >
            {!photoUrl?.url ? (
              <div className="tw-border tw-border-dashed tw-rounded-lg tw-bg-[rgba(32,80,118,0.2)] tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-6 tw-p-10">
                <img src={ImageUploadIllustration} />

                <p>Browse your photos</p>

                <Button
                  className="tw-bg-secondary-800 tw-text-white"
                  type="button"
                >
                  Browse File
                </Button>
              </div>
            ) : (
              <div
                className={cn(
                  "tw-rounded-md tw-relative tw-max-h-80 tw-overflow-auto tw-overflow-y-hidden"
                )}
              >
                <img
                  src={photoUrl?.url}
                  width="100%"
                  className="tw-rounded-md"
                />
              </div>
            )}
          </ImageUpload>
        </div>
      </div>
    </section>
  );
};

export default LicenseImageForm;
