const FUEL_BUYER_DRIVER_TABLEHEADER = [
  "Driver Name",
  "Driver Email Id",
  "Driver License No",
  "Driver Id Proof",
  "Actions",
];

const FUEL_BUYER_VEHICLES_TABLEHEADER = [
  "Vehicle Type",
  "Manufacturer Name",
  "Reg/License No",
  "Vehicle Model",
  "Mfg Year",
  "Fuel Type",
  "Actions",
];

const FUEL_BUYER_ORDER_TABLEHEADER = [
  "Driver Name",
  "Vehicle Details",
  "Fuel Station",
  "Amount/Ltrs",
  "Status",
  "Actions",
];

const FUEL_VENDOR_PRODUCTS_TABLEHEADER = [
  "S/N",
  "Name",
  "Price",
  "Units",
  "Type",
  "Actions",
];

export {
  FUEL_BUYER_DRIVER_TABLEHEADER,
  FUEL_BUYER_ORDER_TABLEHEADER,
  FUEL_BUYER_VEHICLES_TABLEHEADER,
  FUEL_VENDOR_PRODUCTS_TABLEHEADER,
};
