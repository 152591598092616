import Account from "./components/Account";
import CategoriesPlaceSearch from "./components/CategoriesPlaceSearch";
import CreateEvent from "./components/CreateEvent";
import Help from "./components/Help";
import LocationSelector from "./components/LocationSelector";
import Search from "./components/Search";
import SearchAndNavbar from "./components/SearchAndNavbar";
import SideBar from "./components/SideBar";
import "./styles.scss";
import { Icon } from "@iconify/react";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { CloseIcon } from "assets/icons";
import Logo from "assets/images/logo.svg";
import Hamburger from "components/pages/authenticated-new/components/Hamburger";
import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";

const MainHeader = ({
  defaultNameSearchKeyword,
  defaultPlaceSearchKeyword,
}) => {
  const [isHeaderFixed, setHeaderFixed] = useState(false);
  const FIX_HEADER_POSITION_Y = -138;

  useScrollPosition(({ _prevPos, currPos }) => {
    setHeaderFixed(currPos.y < FIX_HEADER_POSITION_Y);
  }, []);

  const [showSideNav, setShowSideNav] = useState(false);

  return (
    <Fragment>
      <nav
        className={`md:tw-px-6 main-header-component tw-m-auto tw-w-full ${
          isHeaderFixed ? "fixed-header tw-w-screen" : "shadow-none"
        }`}
        id="header-nav"
      >
        <div className="tw-max-w-10xl tw-m-auto tw-w-full">
          <div className="navbar navbar-light nav-max-width p-0 m-0">
            <div className="tw-flex tw-justify-between tw-h-[80px] tw-w-full ">
              <div className="tw-flex tw-items-center tw-gap-1 md:tw-gap-3">
                <div className="tw-flex tw-items-center tw-h-full">
                  <div className="tw-inline md:tw-hidden tw-px-6 md:tw-px-0 tw-bg-[#FFB9BB33] tw-h-full">
                    <div className=" tw-flex tw-items-center tw-mx-auto tw-h-full">
                      <a
                        className="navbar-toggler border-0 px-0 text-dark"
                        onClick={() => setShowSideNav(!showSideNav)}
                      >
                        <Hamburger className="tw-w-6" />
                      </a>
                    </div>
                  </div>
                  <Link className="tw-px-2 md:tw-px-4 tw-flex-shrink-0" to="/">
                    <img
                      src={Logo}
                      alt="Triple7events"
                      className="tw-w-24 md:tw-w-28 lg:tw-w-36"
                    />
                  </Link>
                </div>

                <LocationSelector />

                <Search />
              </div>
              <div className="tw-flex tw-items-center md:tw-mr-6 tw-gap-3 md:tw-gap-6 lg:tw-gap-10 tw-bg-[#74DDF533] md:tw-bg-transparent tw-px-6 md:tw-px-0">
                <div className="tw-hidden md:tw-inline">
                  <Help />
                </div>

                <div className="tw-hidden md:tw-inline">
                  <CreateEvent />
                </div>

                <div className="tw-flex-shrink-0">
                  <Account />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`overlay md:hidden ${showSideNav ? "w-100 start-0" : ""}`}
          id="navbar-responsive"
        >
          <SideBar setShowSideNav={setShowSideNav} showSideNav={showSideNav} />
        </div>

        {showSideNav && (
          <button
            className="tw-cursor-pointer tw-text-white tw-absolute tw-right-16 tw-top-8 mr-auto tw-z-50"
            onClick={() => {
              setShowSideNav(!showSideNav);
            }}
          >
            <CloseIcon className="tw-text-white tw-fixed" />
          </button>
        )}
      </nav>
    </Fragment>
  );
};

export default MainHeader;
