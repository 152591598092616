import FormContainer from "../../../FormContainer";
import { SELECT_CUSTOM_CONTROL_STYLES } from "../../EventDetails/constants";
import {
  DRESS_CODES,
  INVITATION_REQUIREMENTS,
  restrictionNumber,
} from "./constants";
import DatePicker from "components/shared/forms/DatePicker";
import ThemedSelect from "components/shared/forms/ThemedSelect";
import { Field } from "formik";
import React from "react";
import {
  convertArrayToSelectOptions,
  convertModelArrayToSelectOptions,
} from "utilities";

const ObservationsForm = ({ setFieldValue }) => {
  return (
    <FormContainer>
      <p className="tw-font-medium">Observations</p>

      <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-3 tw-gap-6 tw-mt-6">
        <div>
          <Field
            component={ThemedSelect}
            name="ageLimit"
            id="ageLimit"
            placeholder="Select Age Limit"
            options={convertModelArrayToSelectOptions(
              [{ id: "all", label: "All" }, ...restrictionNumber],
              "id",
              "label",
              true
            )}
            label="Age Limit"
            labelClassName="tw-ml-4"
            customControlStyle={SELECT_CUSTOM_CONTROL_STYLES}
          />
        </div>

        <div>
          <Field
            component={DatePicker}
            placeholder="00:00"
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
            className="tw-input !tw-border-gray-300 !tw-w-full"
            name="lateEntryTime"
            id="lateEntryTime"
            type="date"
            label="Late Time Entry"
            labelClassName="tw-ml-4"
            onChange={(time) => {
              setFieldValue(`lateEntryTime`, time);
            }}
          />
        </div>
      </div>

      <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-3 tw-gap-6 tw-mt-6">
        <div>
          <Field
            component={ThemedSelect}
            name="dressCode"
            id="dressCode"
            size="sm"
            placeholder="Select Dress Code"
            options={convertArrayToSelectOptions(DRESS_CODES)}
            label="Dress Code"
            labelClassName="tw-ml-4"
            customControlStyle={SELECT_CUSTOM_CONTROL_STYLES}
          />
        </div>

        <div>
          <Field
            component={ThemedSelect}
            name="invitationId"
            id="invitationId"
            size="sm"
            placeholder="Select Invitation Type"
            options={convertModelArrayToSelectOptions(
              INVITATION_REQUIREMENTS,
              "id",
              "label",
              true
            )}
            label="ID/Invitation"
            labelClassName="tw-ml-4"
            customControlStyle={SELECT_CUSTOM_CONTROL_STYLES}
          />
        </div>
      </div>
    </FormContainer>
  );
};

export default ObservationsForm;
