import FuelBuyerDashboardPageTitle from "../../../components/PageTitle";
import { EditIcon, EyeIcon, TrashIcon } from "assets/icons";
import EmptyTable from "components/pages/authenticated-new/pages/Events/shared/EmptyTable";
import { Button } from "components/shared/Button";
import TableSkeleton from "components/shared/TableSkeleton";
import DefaultDeleteModal from "components/shared/modal/deleteModal";
import DefaultTable from "components/shared/tables/defaultTable";
import { FUEL_BUYER_ORDER_TABLEHEADER } from "constants/tableHeaderConstants";
import { TABLE_STYLE_ENUMS } from "enums/styleEnums";
import usePagination from "hooks/usePagination";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  fetchCustomersOrderRequest,
  getFuelBuyerOrdersRequest,
} from "state/slices/fuel";
import { cn } from "utilities/cn";

const FuelBuyerOrdersTablePage = () => {
  const [activeOrder, setActiveOrder] = useState({});

  const { user } = useSelector((state) => state.users.data);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const showDeleteModal = (order) => {
    setActiveOrder(order);
    setOpenDeleteModal(true);
  };

  const [search, setSearch] = React.useState("");
  const { data, refetch, isLoading } = usePagination({
    dataSelector: "",
    request: fetchCustomersOrderRequest,
    customQueryPayload: {},
    customQueryParams: {
      ...(search ? { search } : {}),
    },
  });

  return (
    <>
      <DefaultDeleteModal
        isActive={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
      />

      <div>
        <div className="tw-flex tw-justify-between">
          <FuelBuyerDashboardPageTitle title="Order List" />

          <div className="tw-flex tw-gap-2">
            <input
              className="!tw-h-full !tw-min-h-full !tw-w-72 tw-border !tw-rounded-md tw-input"
              placeholder="Search"
            />

            {/* <Link to="/dashboard/fuel-station/vehicles/create">
              <Button className="tw-bg-green-500 tw-text-white">
                NEW PURCHASE
              </Button>
            </Link> */}
          </div>
        </div>

        <div className="tw-mt-6">
          <div className="tw-py-4 tw-text-base">
            {data?.length !== 0 ? (
              <DefaultTable headerData={FUEL_BUYER_ORDER_TABLEHEADER}>
                {isLoading ? (
                  <TableSkeleton rows={10} columns={5} />
                ) : (
                  <tbody className={TABLE_STYLE_ENUMS.TABLE_BODY}>
                    <tr className="tw-border-b">
                      <td className="tw-px-3 tw-py-3 tw-w-48">John Doe</td>
                      <td className="tw-px-3 tw-py-3 tw-w-48">
                        johndoe@gmail.com
                      </td>
                      <td className="tw-px-3 tw-py-3">johndoe@gmail.com</td>
                      <td className="tw-px-3 tw-py-3">NGN 400.00</td>
                      <td
                        className={cn(
                          "tw-px-3 tw-py-3 tw-w-48 tw-font-semibold",
                          "tw-text-green-500",
                          "tw-text-red-500"
                        )}
                      >
                        ACTIVE
                      </td>
                      <td className="tw-px-3 tw-py-3">
                        <div className="tw-flex tw-gap-4">
                          <Link to="/dashboard/fuel-station/orders/id">
                            <EyeIcon className="tw-w-5 tw-h-5 tw-cursor-pointer hover:tw-text-secondary-500" />
                          </Link>
                          <Link to="/dashboard/fuel-station/orders/edit/id">
                            <EditIcon className="tw-w-5 tw-h-5 tw-cursor-pointer hover:tw-text-secondary-500" />
                          </Link>
                          <TrashIcon
                            onClick={() => showDeleteModal("me")}
                            className="tw-w-5 tw-h-5 tw-cursor-pointer hover:tw-text-secondary-500"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </DefaultTable>
            ) : (
              <EmptyTable
                title="No Orders(s) Available"
                description=" Once any order(s) is created, they will appear here."
                className="tw-mb-6"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FuelBuyerOrdersTablePage;
