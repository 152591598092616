import * as yup from "yup";

export const validationSchema = yup.object().shape({
  firstName: yup.string().label("First name").required(),
  lastName: yup.string().label("Last name").required(),
  email: yup.string().label("Email").required(),
  password: yup
    .string()
    .label("Password")
    .min("8")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
      "The password should consist of a minimum of one lowercase letter, one uppercase letter, and one digit, with a length of more than 8 characters."
    )
    .required(),
  confirmPassword: yup
    .string()
    .label("Password confirmation")
    .oneOf([null, yup.ref("password")], "Both passwords needs to be same")
    .required(),
});

export const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
};

export const ACCOUNT_TYPES = {
  individual: "individual",
  organization: "organization",
};
