import accountReducer from "./slices/account";
import eventsReducer from "./slices/events";
import fuelReducer from "./slices/fuel";
import locationReducer from "./slices/location";
import miscReducer from "./slices/misc";
import storeReducer from "./slices/stores";
import ticketReducer from "./slices/tickets";
import usersReducer, {
  fuelCustomerReducer,
  fuelVendorsReducer,
} from "./slices/users";
import { combineReducers } from "redux";

export default combineReducers({
  account: accountReducer,
  users: usersReducer,
  events: eventsReducer,
  location: locationReducer,
  tickets: ticketReducer,
  stores: storeReducer,
  fuelCustomers: fuelCustomerReducer,
  fuelVendors: fuelVendorsReducer,
  fuel: fuelReducer,
  misc: miscReducer,
});
