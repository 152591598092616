import { UserIcon } from "assets/icons";
import ImageUpload from "components/shared/forms/ImageUpload";
import React from "react";

const ProfileImageForm = ({ photoUrl, addPhotoUrl }) => {
  return (
    <section>
      <div className="tw-flex tw-items-center tw-p-3 tw-gap-5">
        <div className="tw-rounded-full tw-p-2 tw-h-full tw-overflow-hidden tw-border-none">
          {photoUrl?.url ? (
            <img
              src={photoUrl?.url}
              className="tw-min-h-full tw-w-[100px] tw-h-[100px] tw-rounded-[50%] tw-object-fit"
            />
          ) : (
            <div className="tw-w-full tw-h-full tw-flex tw-justify-center">
              <UserIcon className="tw-w-24 tw-h-24" />
            </div>
          )}
        </div>

        <div className="tw-max-w-[200px] tw-mt-4">
          <ImageUpload
            addNewImages={addPhotoUrl}
            singleImageUrl={photoUrl?.url}
            isSingle
            className="tw-rounded-full tw-p-3 tw-px-6 tw-bg-white tw-border-gray-100 tw-text-center"
          >
            {photoUrl?.url ? "Change Profile Photo" : "Upload Profile Photo"}
          </ImageUpload>
        </div>
      </div>
    </section>
  );
};

export default ProfileImageForm;
