import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSavedEventsByUserRequest } from "state/slices/events";
import { fetchUserWalletsRequest } from "state/slices/users";

const AppContainer = ({ children }) => {
  const { token } = useSelector((state) => state.users.data);

  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      dispatch(fetchSavedEventsByUserRequest({}));
      dispatch(fetchUserWalletsRequest({}));
    }
  }, [dispatch, token]);

  return (
    <div className="tw-overflow-auto tw-h-full tw-m-auto tw-relative">
      {children}
    </div>
  );
};

export default AppContainer;
