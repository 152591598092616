import { PlusIcon } from "assets/icons";
import { Button } from "components/shared/Button";
import DatePicker from "components/shared/forms/DatePicker";
import TextInput from "components/shared/forms/TextInput";
import { Field, FieldArray } from "formik";
import { DateTime } from "luxon";
import React from "react";
import { toast } from "react-toastify";

const dateObject = {
  startDate: "",
  startTime: "",
  endDate: "",
  endTime: "",
};

const EventDates = ({ values, setFieldValue }) => {
  return (
    <FieldArray
      name="dates"
      render={(arrayHelpers) => (
        <div className="tw-flex tw-flex-col tw-gap-6">
          {/* ADD DEFAULT DATES */}
          {(!values.dates || values.dates?.length === 0) &&
            arrayHelpers.push({ ...dateObject })}
          {/*  */}

          <div className="tw-flex tw-flex-col tw-gap-6">
            {values?.dates?.map((date, index) => (
              <div key={index} className="tw-flex tw-items-center tw-gap-8">
                <div className="tw-w-1/4">
                  <Field
                    component={DatePicker}
                    placeholder="DD-MM-YYYY"
                    minDate={new Date()}
                    className="tw-input !tw-border-gray-300 !tw-w-full"
                    name={`dates.${index}.startDate`}
                    id={`startDate.${index}`}
                    type="date"
                    label="Start Date"
                    labelClassName="tw-ml-4"
                    onChange={(date) => {
                      setFieldValue(`dates.${index}.startDate`, date);
                      setFieldValue(`dates.${index}.endDate`, date);
                    }}
                    required
                  />
                </div>

                <div className="tw-w-1/4">
                  <Field
                    component={DatePicker}
                    placeholder="00:00"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    className="tw-input !tw-border-gray-300 !tw-w-full"
                    name={`dates.${index}.startTime`}
                    id={`startTime.${index}`}
                    type="date"
                    label="Start Time"
                    labelClassName="tw-ml-4"
                    onChange={(time) => {
                      setFieldValue(`dates.${index}.startTime`, time);
                      const endTime = new Date(time);
                      endTime.setHours(endTime.getHours() + 1);
                      setFieldValue(`dates.${index}.endTime`, endTime);
                    }}
                    required
                  />
                </div>

                <div className="tw-w-1/4">
                  <Field
                    component={DatePicker}
                    placeholder="DD-MM-YYYY"
                    minDate={new Date()}
                    className="tw-input !tw-border-gray-300 !tw-w-full"
                    name={`dates.${index}.endDate`}
                    id={`endDate.${index}`}
                    type="date"
                    label="End Date"
                    labelClassName="tw-ml-4"
                    onChange={(date) => {
                      setFieldValue(`dates.${index}.endDate`, date);
                      setFieldValue(`dates.${index}.endTime`, "");
                    }}
                    required
                  />
                </div>

                <div className="tw-w-1/4">
                  <Field
                    component={DatePicker}
                    placeholder="00:00"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    className="tw-input !tw-border-gray-300 !tw-w-full"
                    name={`dates.${index}.endTime`}
                    id={`endTime.${index}`}
                    type="date"
                    label="End Time"
                    labelClassName="tw-ml-4"
                    onChange={(time) => {
                      const endT = new Date(time).getTime();

                      const startT = new Date(
                        values.dates[index].startTime
                      ).getTime();
                      const startDate = DateTime.fromJSDate(
                        values.dates[index].startDate
                      ).toFormat("yyyy LLL dd");
                      const endDate = DateTime.fromJSDate(
                        values.dates[index].endDate
                      ).toFormat("yyyy LLL dd");

                      if (endDate == startDate && endT <= startT) {
                        toast.error(
                          "The end and start date are the same, you need to enter a valid end time"
                        );
                      } else {
                        setFieldValue(`dates.${index}.endTime`, time);
                      }
                    }}
                    required
                  />
                </div>
              </div>
            ))}
          </div>

          <div className="tw-flex tw-justify-end">
            <Button
              type="button"
              onClick={() => arrayHelpers.push(dateObject)}
              className="tw-flex tw-gap-3 tw-items-center tw-bg-primary-800 !tw-rounded-full tw-shadow-none tw-text-white"
            >
              <PlusIcon />
              Add multiple dates
            </Button>
          </div>
        </div>
      )}
    />
  );
};

export default EventDates;
