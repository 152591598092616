import React from "react";
import { MdArrowBackIosNew } from "react-icons/md";
import { useNavigate } from "react-router-dom-v5-compat";

const GoToBackButton = () => {
  const navigate = useNavigate();
  const navigateToBack = () => navigate(-1);
  return (
    <div
      className="tw-flex tw-gap-2 tw-cursor-pointer tw-items-center tw-justify-center"
      onClick={navigateToBack}
    >
      <MdArrowBackIosNew size={"1.5rem"} className="m-0" />
      <h5 className="tw-font-semibold tw-m-0">Back</h5>
    </div>
  );
};

export default GoToBackButton;
