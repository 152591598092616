import { useEventFormContext } from "../../../context";
import { unwrapResult } from "@reduxjs/toolkit";
import Switch from "components/shared/Switch";
import { thousandFormatter } from "lib/number";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { fetchEventCommissionRequest } from "state/slices/events";
import { getOneLinerErrorMessage } from "utilities";

const CommissionAndRemainingTickets = ({ setFieldValue, values }) => {
  const { event } = useEventFormContext();

  const [isLoading, setIsLoading] = useState(false);

  const [commission, setCommission] = useState({});

  const dispatch = useDispatch();

  const fetchCommission = useCallback(async () => {
    setIsLoading(true);

    const resultAction = await dispatch(fetchEventCommissionRequest(event.id));
    if (fetchEventCommissionRequest.fulfilled.match(resultAction)) {
      const response = unwrapResult(resultAction);
      setIsLoading(false);
      setCommission(response.data);
    } else {
      setIsLoading(false);
      // if (resultAction.payload.message) {
      //   toast.error(resultAction.payload.message);
      // } else {
      //   toast.error(
      //     getOneLinerErrorMessage(resultAction.payload) ||
      //       resultAction.error?.message ||
      //       "An error occurred, please try again."
      //   );
      // }
    }
  }, [dispatch, event.id]);

  useEffect(() => {
    fetchCommission();
  }, [fetchCommission, event.ticketTypes]);

  const commissionStatement = `${thousandFormatter(
    commission?.totalCommissionPercentage || 0
  )}% + ${commission?.totalAdditionalCommissionPercentage || 0}`;

  return (
    <div className="tw-border tw-rounded-md tw-bg-gray-50 tw-py-8 tw-px-4 md:tw-px-10 tw-flex tw-flex-col tw-gap-8">
      <div className="tw-flex tw-justify-between tw-gap-8">
        <div>
          <span>Absorb Policy</span>
          <br />
          <span className="tw-gray-600 tw-text-base">
            Absorb Triple7Events Commission: Seller pays Triple7events
            commission and payment processing fee
          </span>
          <br />
          <p className="tw-mt-2">
            Absorb Retail Price:{" "}
            {isLoading ? "Loading..." : commissionStatement}
          </p>
        </div>
        <div className="tw-pt-3 tw-px-8">
          <Switch
            onChange={(value) => setFieldValue("absorbPolicy", value)}
            checked={values.absorbPolicy}
          />
        </div>
      </div>

      <div className="tw-flex tw-justify-between tw-gap-8">
        <div>
          <span>Pass on Policy</span>
          <br />
          <span className="tw-gray-600 tw-text-base">
            Pass On Triple7Events Commission: Buyer pays Triple7events
            commission and payment processing fee
          </span>
          <br />
          <p className="tw-mt-2">Absorb Retail Price: 0.00</p>
        </div>
        <div className="tw-pt-3 tw-px-8">
          <Switch
            onChange={(value) => setFieldValue("absorbPolicy", !value)}
            checked={!values.absorbPolicy}
          />
        </div>
      </div>

      <div className="tw-flex tw-justify-between tw-gap-8">
        <div>
          <span>Remaining Tickets</span>
          <br />
          <span className="tw-gray-600 tw-text-base">
            Show Remaining Tickets
          </span>
        </div>
        <div className="tw-pt-3 tw-px-8">
          <Switch
            onChange={(value) => setFieldValue("showRemainingTickets", value)}
            checked={values.showRemainingTickets}
          />
        </div>
      </div>
    </div>
  );
};

export default CommissionAndRemainingTickets;
