import { ACCOUNT_NAV } from "./constants";
import "./styles.scss";
import { Icon } from "@iconify/react";
import { EllipseIcon, PowerIcon } from "assets/icons";
import CustomNavDropdown from "components/shared/etc/CustomNavDropdown";
import React from "react";
import { NavDropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { logoutUser } from "state/slices/account";
import { logoutUserData } from "state/slices/users";
import { cn } from "utilities/cn";

const Account = () => {
  const { user } = useSelector((state) => state.users.data);

  const dispatch = useDispatch();

  const logout = () => {
    dispatch(logoutUser());
    dispatch(logoutUserData());
  };

  return (
    <div className="navbar-nav flex-row font-weight-normal">
      {user ? (
        <CustomNavDropdown
          id="user-account-dropdown"
          className="tw-text-gray-900 tw-max-w-[100px] md:tw-max-w-[150px] lg:tw-max-w-[200px] text-gray-300 text-black-link"
          title={
            <>
              <div className="tw-relative">
                {user.pictureUrl ? (
                  <div className="tw-max-w-[2.5rem] tw-max-h-[2.5rem] tw-rounded-full tw-overflow-hidden">
                    <img
                      src={user.pictureUrl}
                      className="tw-w-full tw-h-full tw-rounded-full"
                    />
                  </div>
                ) : (
                  <Icon
                    icon="carbon:user-avatar-filled"
                    className="text-gray-300 font-weight-bold text-black-link"
                    fontSize={"25px"}
                  />
                )}
                <div
                  className={cn(
                    "tw-absolute tw-bottom-0 tw-right-0 tw-bg-white tw-p-.5 tw-rounded-full",
                    user.pictureUrl && "p-0"
                  )}
                >
                  <EllipseIcon className="tw-text-green-500 tw-w-4 tw-h-4" />
                </div>
              </div>
              <Icon
                icon="fa:angle-down"
                className="tw-hidden md:tw-inline  ml-2"
              />
            </>
          }
        >
          <div className="tw--my-8 tw--mx-2 tw-py-5">
            {ACCOUNT_NAV.map((nav, index) => (
              <NavDropdown.Item
                as={Link}
                to={
                  user.hasCreatedEvent && nav.vendorLink
                    ? nav.vendorLink
                    : nav.link
                }
                key={index}
                className={cn(
                  "nav-link-item tw-text-lg tw-text-white hover:!tw-bg-gray-200"
                )}
              >
                <div className="tw-py-3 tw-pl-4 tw-flex tw-gap-3 tw-items-center">
                  {nav.icon} {nav.name}
                </div>
              </NavDropdown.Item>
            ))}
            <NavDropdown.Item
              className={cn(
                "nav-link-item tw-text-lg tw-text-white hover:!tw-bg-gray-200 tw-rounded-b-lg  hover:tw-rounded-b-lg !tw-bg-primary-800 !tw-text-white hover:!tw-bg-primary-800"
              )}
              onClick={logout}
            >
              <div className="tw-py-3 tw-pl-4 tw-flex tw-gap-3 tw-items-center">
                <PowerIcon className="tw-h-6 tw-w-6" /> Logout
              </div>
            </NavDropdown.Item>
          </div>
        </CustomNavDropdown>
      ) : (
        <CustomNavDropdown
          id="sign-in-dropdown"
          title={
            <>
              <span className="md:ml-2 font-weight-bold tw-text-gray-800">
                <span className="tw-hidden md:tw-inline tw-text-gray-800">
                  Sign In
                </span>
                <Icon
                  icon="carbon:user-avatar-filled"
                  className="text-gray-300 font-weight-bold text-black-link md:tw-hidden tw-inline"
                  fontSize={"25px"}
                />
              </span>
              <Icon
                icon="fa:angle-down"
                className="ml-2 md:tw-inline tw-hidden"
              />
            </>
          }
          className="text-gray-300 text-black-link tw-text-gray-800"
        >
          <NavDropdown.Item as={Link} to="/auth/sign-in">
            Sign In
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/auth/sign-in">
            Sign Up
          </NavDropdown.Item>
        </CustomNavDropdown>
      )}
    </div>
  );
};

export default Account;
