import SearchModalPopup from "./MobilePopup";
import SearchResults from "./SearchResults";
import { SearchIcon } from "assets/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDebounce } from "use-debounce";

const Search = () => {
  const history = useHistory();

  const [showOverlay, setShowOverlay] = useState(false);
  const [search, setSearch] = useState("");
  const [debouncedSearch] = useDebounce(search, 1000);
  const [showSearchSuggestions, setShowSearchSuggestions] = useState(false);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const searchEvents = (e) => {
    e.preventDefault();
    history.push(`/events?search=${search}`);
    setShowSearchSuggestions(false);
  };

  useEffect(() => {
    if (search) setShowSearchSuggestions(true);
  }, [search]);

  return (
    <form onSubmit={(e) => searchEvents(e)}>
      <div className="tw-z-10 tw-hidden md:tw-flex xl:tw-w-screen tw-max-w-sm md:tw-max-w-sm lg:tw-max-w-lg tw-mx-3 tw-h-14 tw-bg-gray-100 tw-rounded-l-lg tw-relative">
        <input
          className="tw-z-10 tw-bg-transparent tw-outline-none focus-visible:tw-outline-none tw-border-0 tw-h-full lg:tw-w-full tw-text-sm tw-px-4 tw-py-2"
          placeholder="Enter event name, category or city"
          name="header-search"
          onChange={handleSearchChange}
          value={search}
        />
        <button
          type="submit"
          className="tw-z-10 md:tw-w-32 lg:tw-w-48 tw-h-full tw-bg-secondary-800 tw-text-white tw-rounded-r-md tw-text-base"
          onClick={searchEvents}
        >
          Search
        </button>

        {showSearchSuggestions && (
          <>
            <div
              onClick={() => setShowSearchSuggestions(false)}
              className="tw-fixed tw-z-0 tw-top-0 tw-left-0 tw-w-screen tw-h-screen tw-bg-black tw-bg-opacity-20 tw-transition-all"
            ></div>

            <div className="tw-absolute tw-mt-16 tw-w-full">
              <div className="tw-z-20 tw-bg-white tw-rounded-md tw-w-full tw-min-h-full tw-border tw-p-2 tw-flex tw-flex-col tw-gap-3 tw-max-h-96 tw-overflow-y-auto">
                <SearchResults
                  search={debouncedSearch}
                  setShowSearchSuggestions={setShowSearchSuggestions}
                />
              </div>
            </div>
          </>
        )}
      </div>

      <div
        className="md:tw-hidden"
        onClick={() => setShowOverlay((oldShowOverlay) => !oldShowOverlay)}
      >
        <SearchIcon />
      </div>
      <div
        className={`overlay md:hidden tw-flex tw-items-center tw-justify-center ${
          showOverlay ? "w-100 start-0" : ""
        }`}
        id="navbar-responsive"
      >
        <SearchModalPopup
          showOverlay={showOverlay}
          setShowOverlay={setShowOverlay}
        />
      </div>
    </form>
  );
};

export default Search;
