import { DEFAULT_LIMIT, DEFAULT_PAGE_RANGE_DISPALYED } from "./constants";
import Pager from "components/shared/Pager";
import React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getOneLinerErrorMessage } from "utilities";

const usePagination = ({
  customQueryParams = {},
  customQueryPayload,
  pageRangeDisplayed,
  request,
  dataSelector = "data",
  limit,
  showDataCount = true,
  initialPage,
  CSVGenerator,
}) => {
  const [dataPerPage, setDataPerPage] = useState(limit || DEFAULT_LIMIT);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [dataCount, setDataCount] = useState(0);
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [displayedPages, setDisplayedPages] = useState(
    pageRangeDisplayed || DEFAULT_PAGE_RANGE_DISPALYED
  );
  const [currentPage, setCurrentPage] = useState(
    initialPage ? Number(initialPage) : 1
  );

  const dispatch = useDispatch();

  const customQueryString = useMemo(() => {
    const queryString = new URLSearchParams(customQueryParams)?.toString();
    return queryString ? `&${queryString}` : "";
  }, [customQueryParams]);

  const generatePayload = useCallback(
    ({ requestPage = currentPage, requestLimit = limit }) => ({
      page: requestPage,
      perPage: dataPerPage,
      limit: requestLimit,
      customParameters: customQueryString,
      ...customQueryPayload,
    }),
    [currentPage, customQueryPayload, customQueryString, dataPerPage, limit]
  );

  const getPaginatedData = useCallback(async () => {
    const payload = generatePayload({});

    setIsLoading(true);
    const resultAction = await dispatch(request(payload));
    if (request.fulfilled.match(resultAction)) {
      setError(false);
      setIsLoading(false);
      setData(
        (dataSelector
          ? resultAction?.payload.data?.[dataSelector]
          : resultAction?.payload.data) || []
      );
      setDataCount(
        dataSelector
          ? resultAction?.payload.data?.totalCount
          : resultAction?.payload?.data?.length
      );
    } else {
      setError(true);
      setIsLoading(false);
      toast.error(
        getOneLinerErrorMessage(resultAction.payload) ||
          resultAction.error?.message ||
          "An error occurred, please try again."
      );
    }
  }, [dataSelector, dispatch, generatePayload, request]);

  useEffect(() => {
    getPaginatedData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, customQueryString, dataPerPage, request]);

  const goToPage = (page) => {
    if (page > 0) setCurrentPage(page);
  };

  const generateCSVData = async () => {
    const payload = generatePayload({
      requestPage: 1,
      requestLimit: dataCount,
    });
    const resultAction = await dispatch(request(payload));
    if (request.fulfilled.match(resultAction)) {
      return CSVGenerator?.(
        (dataSelector
          ? resultAction?.payload.data?.[dataSelector]
          : resultAction?.payload.data) || []
      );
    } else {
      throw new Error(
        getOneLinerErrorMessage(resultAction.payload) ||
          resultAction.error?.message ||
          "An error occurred, please try again."
      );
    }
  };

  const templateData = useMemo(
    () => ({
      currentPage,
      dataPerPage,
      dataCount,
      displayedPages,
      goToPage,
      setDataPerPage,
      showDataCount,
    }),
    [currentPage, dataCount, dataPerPage, displayedPages, showDataCount]
  );

  const loading = isLoading;

  const template = <Pager templateData={templateData} loading={loading} />;

  return {
    data,
    pager: template,
    dataPerPage,
    templateData,
    loading,
    error,
    refetch: getPaginatedData,
    generateCSVData,
  };
};

export default usePagination;
