import "./styles.scss";
import {
  faBars,
  faEllipsisV,
  faLock,
  faUser,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Triple7EventsLogo from "assets/images/logo.svg";
import { EventDashboardSidebar } from "components/pages/authenticated/Event-dashboard/sharedComponents/event-dash-sidebar";
import { FuelBuyerSidebar } from "components/pages/authenticated/Fuel-dashboard/Buyers/sharedComponents/Sidebar";
import { FuelVendorSidebar } from "components/pages/authenticated/Fuel-dashboard/Vendors/sharedComponents/Sidebar";
import { StoreSidebar } from "components/pages/authenticated/Store-dasboard/SharedComponents/Sidebar";
import { DashboardSidebar } from "components/pages/authenticated/user-dashboard/sharedcomponents/Sidebar";
import CustomNavDropdown from "components/shared/etc/CustomNavDropdown";
import { accountNav, moreNav } from "components/shared/navbar/navItems";
import React, { Fragment, useEffect, useState } from "react";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { logoutUser } from "state/slices/account";
import { logoutUserData } from "state/slices/users";

const AlternateNavBar = ({
  isDashboard,
  isEventDashboard,
  isStoreDashboard,
  isFuelDashboard,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.data.detail);
  const token = localStorage.getItem("token");
  const logout = () => {
    dispatch(logoutUser());
    dispatch(logoutUserData());
  };
  const [onUserDashboard, setUserDashboard] = useState(false);
  const [onEventDashboard, setEventDashboard] = useState(false);
  const [onStoreDashboard, setStoreDashboard] = useState(false);
  const [onFuelDashboard, setFuelDashboard] = useState("");
  const [isOpen, setisOpen] = useState(false);

  useEffect(() => {
    if (isDashboard) {
      setEventDashboard(false);
      setStoreDashboard(false);
      setUserDashboard(true);
    }
    if (isEventDashboard) {
      setUserDashboard(false);
      setStoreDashboard(false);
      setEventDashboard(true);
    }
    if (isStoreDashboard) {
      setEventDashboard(false);
      setUserDashboard(false);
      setStoreDashboard(true);
    }
    if (isFuelDashboard) {
      setEventDashboard(false);
      setUserDashboard(false);
      setStoreDashboard(false);
      setFuelDashboard(isFuelDashboard);
    }
  }, []);

  return (
    <nav
      // className="alternate-navbar navbar navbar-expand-md navbar-light fixed-top bg-white p-0"
      className={`alternate-navbar navbar navbar-expand-md navbar-light ${
        onUserDashboard ||
        onEventDashboard ||
        onStoreDashboard ||
        onFuelDashboard
          ? "fixed-top"
          : "sticky-top"
      } bg-white p-0`}
    >
      {onUserDashboard ? (
        <>
          <DashboardSidebar
            isOpen={isOpen}
            toggleSidebar={() => setisOpen(!isOpen)}
          />
          <div className="d-flex align-items-center toggle-logo-wrapper">
            <div id="menu-toggle" onClick={() => setisOpen(!isOpen)}>
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar">
                <FontAwesomeIcon icon={faBars} />
              </span>
            </div>
            <a className="navbar-brand p-0" href="/">
              <img src={Triple7EventsLogo} alt="Triple7events" width="120" />
            </a>
          </div>
        </>
      ) : onEventDashboard ? (
        <>
          <EventDashboardSidebar
            isOpen={isOpen}
            toggleSidebar={() => setisOpen(!isOpen)}
          />
          <div className="d-flex align-items-center toggle-logo-wrapper">
            <div id="menu-toggle" onClick={() => setisOpen(!isOpen)}>
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar">
                <FontAwesomeIcon icon={faBars} />
              </span>
            </div>
            <a className="navbar-brand p-0" href="/">
              <img src={Triple7EventsLogo} alt="Triple7events" width="120" />
            </a>
          </div>
        </>
      ) : onStoreDashboard ? (
        <>
          <StoreSidebar
            isOpen={isOpen}
            toggleSidebar={() => setisOpen(!isOpen)}
          />
          <div className="d-flex align-items-center toggle-logo-wrapper">
            <div id="menu-toggle" onClick={() => setisOpen(!isOpen)}>
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar">
                <FontAwesomeIcon icon={faBars} />
              </span>
            </div>
            <a className="navbar-brand p-0" href="/">
              <img src={Triple7EventsLogo} alt="Triple7events" width="120" />
            </a>
          </div>
        </>
      ) : onFuelDashboard ? (
        <>
          {onFuelDashboard == "vendor" ? (
            <FuelVendorSidebar
              isOpen={isOpen}
              toggleSidebar={() => setisOpen(!isOpen)}
            />
          ) : (
            <FuelBuyerSidebar
              isOpen={isOpen}
              toggleSidebar={() => setisOpen(!isOpen)}
            />
          )}
          <div className="d-flex align-items-center toggle-logo-wrapper">
            <div id="menu-toggle" onClick={() => setisOpen(!isOpen)}>
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar">
                <FontAwesomeIcon icon={faBars} />
              </span>
            </div>
            <a className="navbar-brand p-0" href="/">
              <img src={Triple7EventsLogo} alt="Triple7events" width="120" />
            </a>
          </div>
        </>
      ) : (
        <a className="navbar-brand p-0" href="/">
          <img src={Triple7EventsLogo} alt="Triple7events" width="120" />
        </a>
      )}

      <ul className="navbar-nav flex-row font-weight-normal ml-auto">
        <CustomNavDropdown
          id="more-dropdown"
          title={
            <Fragment>
              <FontAwesomeIcon icon={faEllipsisV} className="text-gray-200" />
              <span className="d-none tw-text-black d-md-block ml-2">More</span>
            </Fragment>
          }
        >
          {moreNav.map((nav) => (
            <NavDropdown.Item as={Link} to={nav.link} key={nav.link}>
              {nav.name}
            </NavDropdown.Item>
          ))}
        </CustomNavDropdown>

        {token ? (
          <CustomNavDropdown
            id="user-account-dropdown"
            title={
              <Fragment>
                <FontAwesomeIcon
                  icon={faUserCircle}
                  className="text-gray-200"
                />
                <span className="d-none tw-text-black d-md-block ml-2">
                  {user?.name}
                </span>
              </Fragment>
            }
          >
            {accountNav.map((nav) => (
              <NavDropdown.Item as={Link} to={nav.link} key={nav.link}>
                {nav.name}
              </NavDropdown.Item>
            ))}
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
          </CustomNavDropdown>
        ) : (
          <Fragment>
            <li className="nav-item tw-text-black">
              <Link to="/auth/login" className="nav-link" href="#">
                <FontAwesomeIcon icon={faLock} />
                <span className="d-none d-md-block ml-2">Login</span>
              </Link>
            </li>

            <li className="nav-item tw-text-black">
              <Link to="/auth/login" className="nav-link" href="#">
                <FontAwesomeIcon icon={faUser} />
                <span className="d-none d-md-block ml-2">Sign Up</span>
              </Link>
            </li>
          </Fragment>
        )}

        <li className="nav-item bg-accent">
          <Link to="/dashboard/create" className="nav-link text-white px-3">
            Create Event / Sell Ticket
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default AlternateNavBar;
