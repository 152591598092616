import { initializeImagesArray } from "./functions";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { uploadSingleImageRequest } from "state/slices/misc";
import { getOneLinerErrorMessage } from "utilities";

const useMedia = ({ isSingle, defaultImages = [] }) => {
  const [images, setImages] = useState(initializeImagesArray(defaultImages));

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const updateImages = useCallback(
    (newImages) => setImages(initializeImagesArray(newImages)),
    []
  );

  const generateNewImageObject = (image) => {
    return {
      file: image,
      url: URL.createObjectURL(image),
    };
  };

  const addImages = (newImages) => {
    setImages((oldImages) =>
      isSingle
        ? newImages?.map((image) => generateNewImageObject(image))
        : [
            ...oldImages,
            ...newImages.map((image) => generateNewImageObject(image)),
          ]
    );
  };

  const removeImage = (url) => {
    setImages((oldImages) => {
      const findImageIndex = images.findIndex(
        (imageItem) => imageItem.url === url
      );
      if (findImageIndex > -1) {
        oldImages.splice(findImageIndex, 1);
      }

      return [...oldImages];
    });
  };

  const throwUploadError = (error = "") => {
    toast.error(error?.toString() || "Couldn't Upload the image(s)");
    throw new Error("An error occurred - couldn't upload the images");
  };

  const uploadSingleImage = async (image = images[0]) => {
    if (!image) return "";

    if (!image.file) return image.url;

    setIsLoading(true);

    const formData = new FormData();

    formData.append("file", image.file);

    const resultAction = await dispatch(uploadSingleImageRequest(formData));
    if (uploadSingleImageRequest.fulfilled.match(resultAction)) {
      setIsLoading(false);
      return resultAction?.payload?.url;
    } else {
      setIsLoading(false);
      return throwUploadError(
        getOneLinerErrorMessage(resultAction.payload) ||
          resultAction.error?.message ||
          "An error occurred, please try again."
      );
    }
  };

  const uploadMultipleImages = async () => {
    setIsLoading(true);

    await Promise.all(
      images
        .filter((imageItem) => !!imageItem.file)
        .map(async (image) => {
          const url = await uploadSingleImage(image);

          setImages((oldImages) => {
            const findImageIndex = images.findIndex(
              (imageItem) => imageItem.url === image.url
            );
            if (findImageIndex > -1) {
              oldImages[findImageIndex].url = url;
              oldImages[findImageIndex].file = null;
            }

            return [...oldImages];
          });

          return 0;
        })
    );

    setIsLoading(false);

    return images.map((image) => image.url);
  };

  return {
    images,
    singleImage: images[0],
    addImages,
    uploadMultipleImages,
    uploadSingleImage,
    isLoading,
    removeImage,
    updateImages,
  };
};

export default useMedia;
