import React from "react";
import ReactSwitch from "react-switch";

const Switch = ({ onChange, checked, ...props }) => {
  return (
    <ReactSwitch
      onColor="#94c3e5"
      onChange={(state) => onChange?.(state)}
      checked={checked}
      checkedIcon={false}
      uncheckedIcon={false}
      height={10}
      width={35}
      handleDiameter={20}
      onHandleColor="#205076"
      offHandleColor="#6b7280"
      offColor="#d1d5db"
      {...props}
    />
  );
};

export default Switch;
