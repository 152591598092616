import DriversRegPanel from "./driversRegPanel";
import VehiclesRegPanel from "./vehiclesRegPanel";
import { EmptyVehicleData } from "components/shared/fallback/emptyData";
import { ROUTE_ENUMS } from "enums";
import usePagination from "hooks/usePagination";
import React from "react";
import { Link } from "react-router-dom-v5-compat";
import {
  fetchPaginatedDriversRequest,
  fetchPaginatedVehiclesRequest,
} from "state/slices/fuel";

const VehiclesAndDrivers = () => {
  const { data } = usePagination({
    dataSelector: "",
    request: fetchPaginatedVehiclesRequest,
    customQueryPayload: {},
  });

  const { data: driversRequestData } = usePagination({
    dataSelector: "",
    request: fetchPaginatedDriversRequest,
    customQueryPayload: {},
  });

  return (
    <div>
      <div className="tw-flex tw-items-center tw-justify-between">
        <h5 className="tw-font-semibold">Your registered vehicle</h5>
        <div className="tw-flex tw-items-center tw-gap-2 tw-cursor-pointer">
          <Link to={`${ROUTE_ENUMS.DASHBOARD_FUEL_STATION_VEHICLES}`}>
            <h6 className="tw-text-gray-500 tw-font-semibold hover:tw-text-primary-800">
              See All
            </h6>
          </Link>
        </div>
      </div>

      <div>
        {data?.length >= 1 ? (
          <div>
            {data?.slice(0, 2)?.map((data, key) => (
              <VehiclesRegPanel
                model={data?.model}
                licenseNumber={data?.licenseNo}
                key={key}
              />
            ))}
          </div>
        ) : (
          <EmptyVehicleData />
        )}
      </div>

      <div className="tw-mt-[2rem]">
        <div className="tw-flex tw-items-center tw-justify-between">
          <h5 className="tw-font-semibold">Your registered Drivers</h5>
          <div className="tw-flex tw-items-center tw-gap-2 tw-cursor-pointer">
            <Link to={`${ROUTE_ENUMS.DASHBOARD_FUEL_STATION_DRIVERS}`}>
              <h6 className="tw-text-gray-500 tw-font-semibold hover:tw-text-primary-800">
                See All
              </h6>
            </Link>
          </div>
        </div>

        <div>
          {driversRequestData?.length >= 1 ? (
            <div>
              {driversRequestData?.slice(0, 2)?.map((data, key) => (
                <DriversRegPanel
                  name={data?.name}
                  licenseNumber={data?.licenseNo}
                  driversPhoto={data?.photoUrl}
                  key={key}
                />
              ))}
            </div>
          ) : (
            <EmptyVehicleData />
          )}
        </div>
      </div>
    </div>
  );
};

export default VehiclesAndDrivers;
