import GoToBackButton from "components/shared/Button/goToBackButton";
import React from "react";

const PageTitleHeader = ({ title, description, withBackNavigation }) => {
  return (
    <div className="tw-flex tw-items-start tw-gap-[3rem]">
      {withBackNavigation && <GoToBackButton />}
      <div className="tw-mb-5 tw-w-full lg:tw-w-[70%] md:tw-w-[70%]">
        <h4 className="tw-font-semibold tw-text-large lg:tw-text-[20px]">
          {title}
        </h4>
        <p className=" tw-text-[#687588] tw-text-sm sm:tw-text-sm md:tw-text-md lg:tw-text-[14px] tw-mt-5 ">
          {description}
        </p>
      </div>
    </div>
  );
};

export default PageTitleHeader;
