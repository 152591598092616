import { Icon } from "@iconify/react";
import { ArrowForwardIcon, BackArrowIcon, CloseIcon } from "assets/icons";
import LottieParty from "assets/lottie/party.json";
import { Button } from "components/shared/Button";
import Modal from "components/shared/modal";
import React from "react";
import Lottie from "react-lottie";
import { Link } from "react-router-dom";
import { hyphenate } from "utilities";

const PublishSuccessModal = ({ isOpen, closeModal, event }) => {
  return (
    <Modal
      isActive={isOpen}
      onClose={closeModal}
      classNames={{
        root: "tw-max-w-[600px] tw-m-auto",
        modal: "tw-w-full tw-max-h-[680px]",
      }}
      closeIcon={
        <CloseIcon
          className="tw-mt-1.5 tw-mr-1 text-white tw-z-10"
          onClick={closeModal}
        />
      }
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <div className="tw-text-lg">
        <div className="tw-relative modal-body tw-min-h-screen/2.5 tw-items-center tw-justify-center tw-text-base tw-overflow-x-hidden">
          <div className="tw-p-6">
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: LottieParty,
              }}
              height={250}
              width={400}
            />

            <div className="tw-mt-2 tw-mx-auto tw-text-center">
              <p className="tw-text-xl tw-font-medium tw-text-green-700">
                Congratulations! 🎉{" "}
              </p>
              <p className="text-xl font-weight-normal">
                You have successfully published an event on our platform.
              </p>
              <Link
                to={`/event/${event.eventSerial}/${hyphenate(event.title)}`}
                className="tw-mt-3"
              >
                <Button className="tw-flex tw-flex-row tw-bg-secondary-700 tw-h-12 tw-px-3 tw-py-6 tw-gap-2 tw-items-center tw-text-[#fff] tw-rounded-[5px]">
                  <Icon icon="material-symbols:language" height={20} />
                  <span className="tw-text-lg tw-hidden sm:tw-inline">
                    Preview&nbsp;Event
                  </span>
                </Button>
              </Link>
              <div className="tw-mt-4">
                <Link to={`/dashboard/organiser/events`} className="tw-mt-3">
                  <div className="tw-flex tw-items-center tw-gap-2 tw-justify-center">
                    Manage Events
                    <ArrowForwardIcon className="tw-w-4" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PublishSuccessModal;
