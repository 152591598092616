import { ChevronRightIcon } from "assets/icons";
import React from "react";
import { memo } from "react";
import Pagination from "react-js-pagination";
import { cn } from "utilities/cn";

const Pager = ({
  loading,
  templateData: {
    currentPage,
    dataCount,
    dataPerPage,
    displayedPages,
    goToPage,
    showDataCount,
  },
}) => {
  const rowsPerPage = dataCount < dataPerPage ? dataCount : dataPerPage;

  return (
    <div
      className={cn(
        "tw-flex tw-flex-col-reverse md:tw-flex-row tw-justify-center tw-items-center md:tw-justify-center tw-py-4 tw-px-3 tw-w-full tw-gap-3 tw-transition-all",
        showDataCount && "md:tw-justify-between"
      )}
    >
      {showDataCount && (
        <div>
          <div className="tw-flex tw-items-center">
            Showing &nbsp;
            {rowsPerPage}
            &nbsp;of&nbsp;{dataCount}
          </div>
        </div>
      )}
      {!loading && dataCount > 0 ? (
        <Pagination
          type="button"
          activePage={currentPage}
          itemsCountPerPage={dataPerPage}
          totalItemsCount={dataCount}
          pageRangeDisplayed={displayedPages?.length}
          activeLinkClass="tw-text-white"
          linkClass="group-hover:tw-text-white tw-text-gray-800"
          itemClassFirst="tw-mr-4"
          itemClassLast="tw-ml-4"
          activeClass="tw-px-2 tw-bg-primary-800 tw-text-gray-800 tw-rounded-full tw-border-primary-800 tw-border"
          itemClass="tw-py-[0.3rem] tw-px-3.5 tw-rounded-full tw-border tw-mx-1 hover:tw-bg-primary-800 hover:tw-border-800 hover:tw-text-white tw-cursor-pointer tw-group tw-max-h-[32px] tw-w-[32px]"
          nextPageText={
            <ChevronRightIcon className="tw-w-3 tw-h-3 tw-mt-2 tw-ml-[0.01rem] tw--mx-1" />
          }
          prevPageText={
            <ChevronRightIcon className="tw-w-3 tw-h-3 tw-mt-2 tw-ml-[0.001rem] tw-rotate-180 tw--mx-1" />
          }
          hideDisabled={true}
          onChange={(e) => {
            goToPage(e);
          }}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default memo(Pager);
