import SignUp from "../SignUp";
import SocialAuth from "./SocialAuth";
import { initialValues, validationSchema } from "./constants";
import "./styles.scss";
import { Logo } from "assets/images";
import andersen from "assets/images/loginAndersen.png";
import loginLogo from "assets/images/loginLogo.png";
import loginRight from "assets/images/loginRightFullImg.png";
import Button from "components/shared/forms/Button";
import TextInput from "components/shared/forms/TextInput";
import LocationSelector from "components/shared/partials/MainHeader/components/LocationSelector";
import PlaceSuggestionWrapper from "components/shared/placeSuggestionWrapper";
import { Field, Form, Formik } from "formik";
import produce from "immer";
import queryString from "query-string";
import React, {
  Fragment,
  useLayoutEffect,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Slider from "react-slick";
import { toast } from "react-toastify";
import { loginRequest } from "state/slices/account";
import { getUserProfileRequest } from "state/slices/users";
import { getOneLinerErrorMessage } from "utilities";

const Login = ({ history }) => {
  const loginBanners = [
    {
      id: 1,
      image: andersen,
    },
    {
      id: 2,
      image: loginRight,
    },
  ];

  const dispatch = useDispatch();
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/" } };

  useLayoutEffect(() => {
    document.title = "Triple7 Events - Login";
  }, []);

  const [state, setState] = useState({
    isLoginLoading: false,
  });

  const [activeNav, setActiveNav] = useState("logIn");

  const getProfile = useCallback(async () => {
    setState(produce((draft) => void (draft.isLoginLoading = true)));
    const resultAction = await dispatch(getUserProfileRequest());
    if (getUserProfileRequest.fulfilled.match(resultAction)) {
      history.push(from);
    } else {
      setState(produce((draft) => void (draft.isLoginLoading = false)));
      toast.error(
        getOneLinerErrorMessage(resultAction.payload) ||
          resultAction.error?.message ||
          "An error occurred, please try again."
      );
    }
  }, [dispatch, from, history]);

  const handleLogin = async (formValues, formikHelpers) => {
    setState(produce((draft) => void (draft.isLoginLoading = true)));

    const resultAction = await dispatch(loginRequest(formValues));

    if (loginRequest.fulfilled.match(resultAction)) {
      setState(produce((draft) => void (draft.isLoginLoading = false)));
      if (!resultAction.payload.data?.admin) {
        localStorage.setItem(
          "token",
          resultAction.payload.data?.tokens?.accessToken
        );
        getProfile();
      } else {
        toast.error("You are an admin, pls login from the admin portal!");
      }
    } else {
      formikHelpers.setFieldValue("password", "", false);
      setState(produce((draft) => void (draft.isLoginLoading = false)));

      toast.error(
        getOneLinerErrorMessage(resultAction.payload) ||
          resultAction.payload.message ||
          "An error occurred, please try again."
      );
    }
  };

  return (
    <Fragment>
      <section className="loginPage">
        <div className="container-fluid loginPageContainer">
          <div className="row loginPageRow">
            <div className="col-lg-6 col-md-12 lg:tw-max-w-[50vw] tw-relative">
              <div className="tw-absolute tw-top-0 tw-left-0 tw-m-2 md:tw-m-4">
                <LocationSelector displayCountryName={false} />
              </div>
              <div className="loginLeftColumn ">
                <Link to="/" className="logo">
                  <Logo className="tw-w-48" />
                </Link>
                <p className="head">
                  Welcome to <span>Triple7 Events</span>
                </p>
                <div className="loginInPageNav tw-text-xl">
                  <a
                    onClick={() => setActiveNav("logIn")}
                    className={activeNav === "logIn" ? "active" : ""}
                  >
                    Sign In
                  </a>
                  <a
                    onClick={() => setActiveNav("register")}
                    className={activeNav === "register" ? "active" : ""}
                  >
                    Register
                  </a>
                </div>
                <div className="inPageNavSelect">
                  {activeNav === "logIn" ? (
                    <>
                      <div className="inPageLoginSection">
                        <div className="loginFormBigDiv col container mx-auto">
                          <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleLogin}
                          >
                            {({ handleSubmit, isValid }) => {
                              return (
                                <Form onSubmit={handleSubmit}>
                                  <div className="loginEmailSec pt-4">
                                    <label
                                      htmlFor="email"
                                      className="tw-text-lg"
                                    >
                                      Email
                                    </label>
                                    <div className="loginFormDiv">
                                      <Field
                                        component={TextInput}
                                        type="text"
                                        name="email"
                                        id="email"
                                        className="formLoginCtrl"
                                        placeholder="Email Address."
                                      />
                                    </div>
                                  </div>
                                  <div className="loginPassSec pt-4">
                                    <label
                                      htmlFor="password"
                                      className="tw-text-lg"
                                    >
                                      Password
                                    </label>
                                    <div className="loginFormDiv">
                                      <Field
                                        component={TextInput}
                                        type="password"
                                        name="password"
                                        id="password"
                                        className="formLoginCtrl"
                                        placeholder="Enter your password"
                                      />
                                    </div>
                                  </div>
                                  <div className="loginRMFP mx-4 pt-4">
                                    <div className="loginRememberMe">
                                      <input
                                        type="checkbox"
                                        className="pt-4"
                                        name="checkbox"
                                        id="checkbox"
                                      />
                                      <label
                                        htmlFor="checkbox"
                                        className="tw-text-lg"
                                      >
                                        Remember Me
                                      </label>
                                    </div>
                                    <div className="loginForgotPassword">
                                      <Link
                                        to="/auth/forgot-password"
                                        className="tw-text-lg tw-text-black hover:tw-underline"
                                      >
                                        Forgot Password?
                                      </Link>
                                    </div>
                                  </div>
                                  <div className="loginBtnDiv">
                                    <Button
                                      type="submit"
                                      className="loginBtn"
                                      title="Login"
                                      loadingTitle="Logging in..."
                                      loading={state.isLoginLoading}
                                      disabled={
                                        state.isLoginLoading || !isValid
                                      }
                                    />
                                  </div>
                                  <div className="orSIO">
                                    <hr className="" />{" "}
                                    <small className="tw-text-lg">
                                      Continue with
                                    </small>{" "}
                                    <hr className="" />
                                  </div>

                                  <SocialAuth
                                    successCallback={getProfile}
                                    className="otherSignInOptions"
                                    setState={setState}
                                  />

                                  <small className="signInCR">
                                    © {new Date().getFullYear()} Triple7 Events.
                                  </small>
                                </Form>
                              );
                            }}
                          </Formik>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="inPageRegisterSection container-fluid">
                        <SignUp successAction={getProfile} />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div
              className="loginRightColumn col-lg-6 col-md-none tw-right-0"
              style={{ position: "fixed" }}
            >
              <Slider
                speed={1000}
                dots={true}
                arrows={false}
                autoplay={true}
                className="loginSlider"
              >
                {loginBanners.map(({ id, image }) => {
                  return (
                    <div className="lsArticle" key={id}>
                      <div
                        className="images"
                        style={{
                          background: `linear-gradient(90deg, #941C1F 0%, #205076 100%), lightgray 50% / center center no-repeat`,
                          backgroundImage: `url(${image})`,
                          backgroundSize: "cover",
                        }}
                      ></div>
                    </div>
                  );
                })}
              </Slider>
              <div
                className=""
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: "0px",
                  background: "rgba(0, 0, 0, 0.3)",
                }}
              ></div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Login;
