import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, useLocation } from "react-router-dom";

const PrivateRoute = ({ ...props }) => {
  const location = useLocation();
  const token = useSelector((state) => state.users.data).token;
  const online = navigator.onLine;

  // return;
  //  !online ? (
  //   <Redirect
  //     to={{
  //       pathname: "/offline",
  //       state: { from: location },
  //     }}
  //   />
  // ) :
  return token ? (
    <Route path={props.path} exact={props.exact} component={props.component} />
  ) : (
    <Redirect
      to={{
        pathname: "/auth/login",
        state: { from: location },
      }}
    />
  );
};

export default PrivateRoute;
