import React from "react";

export const DefaultDashboardIcon = () => {
  return;
};

export const DefaultEventOrganizerIcon = () => {
  return;
};

export const DefaultWalletIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_18568_21432)">
        <path
          d="M17.5 6.06667V4.16667C17.5 3.25 16.75 2.5 15.8333 2.5H4.16667C3.24167 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.24167 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V13.9333C17.9917 13.6417 18.3333 13.1167 18.3333 12.5V7.5C18.3333 6.88333 17.9917 6.35833 17.5 6.06667ZM16.6667 7.5V12.5H10.8333V7.5H16.6667ZM4.16667 15.8333V4.16667H15.8333V5.83333H10.8333C9.91667 5.83333 9.16667 6.58333 9.16667 7.5V12.5C9.16667 13.4167 9.91667 14.1667 10.8333 14.1667H15.8333V15.8333H4.16667Z"
          fill="#A7B4C4"
        />
        <path
          d="M13.334 11.25C14.0243 11.25 14.584 10.6904 14.584 10C14.584 9.30964 14.0243 8.75 13.334 8.75C12.6436 8.75 12.084 9.30964 12.084 10C12.084 10.6904 12.6436 11.25 13.334 11.25Z"
          fill="#323232"
        />
      </g>
      <defs>
        <clipPath id="clip0_18568_21432">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const GasStationActiveIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.1793 2.82L11.186 2.81333L8.70602 0.333333L7.99935 1.04L9.40602 2.44667C8.77935 2.68667 8.33268 3.28667 8.33268 4C8.33268 4.21887 8.37579 4.4356 8.45955 4.63781C8.54331 4.84002 8.66607 5.02375 8.82084 5.17851C9.1334 5.49107 9.55732 5.66667 9.99935 5.66667C10.2393 5.66667 10.4593 5.61333 10.666 5.52667V10.3333C10.666 10.5101 10.5958 10.6797 10.4708 10.8047C10.3457 10.9298 10.1762 11 9.99935 11C9.82254 11 9.65297 10.9298 9.52794 10.8047C9.40292 10.6797 9.33268 10.5101 9.33268 10.3333V7.33333C9.33268 6.97971 9.19221 6.64057 8.94216 6.39052C8.69211 6.14048 8.35297 6 7.99935 6H7.33268V1.33333C7.33268 0.979711 7.19221 0.640573 6.94216 0.390524C6.69211 0.140476 6.35297 0 5.99935 0H1.99935C1.64573 0 1.30659 0.140476 1.05654 0.390524C0.806491 0.640573 0.666016 0.979711 0.666016 1.33333V12H7.33268V7H8.33268V10.3333C8.33268 10.7754 8.50828 11.1993 8.82084 11.5118C9.1334 11.8244 9.55732 12 9.99935 12C10.2182 12 10.4349 11.9569 10.6372 11.8731C10.8394 11.7894 11.0231 11.6666 11.1779 11.5118C11.3326 11.3571 11.4554 11.1733 11.5391 10.9711C11.6229 10.7689 11.666 10.5522 11.666 10.3333V4C11.666 3.54 11.4793 3.12 11.1793 2.82ZM5.99935 7V10.6667H1.99935V6H5.99935V7ZM5.99935 4.66667H1.99935V1.33333H5.99935V4.66667ZM9.99935 4.66667C9.82254 4.66667 9.65297 4.59643 9.52794 4.4714C9.40292 4.34638 9.33268 4.17681 9.33268 4C9.33268 3.82319 9.40292 3.65362 9.52794 3.5286C9.65297 3.40357 9.82254 3.33333 9.99935 3.33333C10.1762 3.33333 10.3457 3.40357 10.4708 3.5286C10.5958 3.65362 10.666 3.82319 10.666 4C10.666 4.17681 10.5958 4.34638 10.4708 4.4714C10.3457 4.59643 10.1762 4.66667 9.99935 4.66667Z"
        fill="#941C1F"
      />
    </svg>
  );
};

export const VehicleActiveIcon = () => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00065 0.667969H2.33398C1.22732 0.667969 0.333984 1.5613 0.333984 2.66797V8.0013C0.333984 9.10797 1.22732 10.0013 2.33398 10.0013L1.66732 10.668V11.3346H2.33398L3.66732 10.0013H5.00065V6.66797H1.66732V2.0013H7.66732V3.33464H9.00065V2.66797C9.00065 1.5613 8.10732 0.667969 7.00065 0.667969ZM2.33398 7.33463C2.70065 7.33463 3.00065 7.63463 3.00065 8.0013C3.00065 8.36797 2.70065 8.66797 2.33398 8.66797C1.96732 8.66797 1.66732 8.36797 1.66732 8.0013C1.66732 7.63463 1.96732 7.33463 2.33398 7.33463ZM12.714 4.4413C12.6207 4.17464 12.3673 4.0013 12.0673 4.0013H7.27398C6.96732 4.0013 6.72065 4.17464 6.62065 4.4413L5.67398 7.1813V10.8546C5.67398 11.108 5.88065 11.3346 6.13398 11.3346H6.54732C6.80065 11.3346 7.00065 11.0813 7.00065 10.828V10.0013H12.334V10.828C12.334 11.0813 12.5407 11.3346 12.794 11.3346H13.2007C13.454 11.3346 13.6607 11.108 13.6607 10.8546L13.6673 9.9413V7.1813L12.714 4.4413ZM7.27398 4.66797H12.0673L12.754 6.66797H6.58732L7.27398 4.66797ZM7.00065 8.66797C6.63398 8.66797 6.33398 8.36797 6.33398 8.0013C6.33398 7.63463 6.63398 7.33463 7.00065 7.33463C7.36732 7.33463 7.66732 7.63463 7.66732 8.0013C7.66732 8.36797 7.36732 8.66797 7.00065 8.66797ZM12.334 8.66797C11.9673 8.66797 11.6673 8.36797 11.6673 8.0013C11.6673 7.63463 11.9673 7.33463 12.334 7.33463C12.7007 7.33463 13.0007 7.63463 13.0007 8.0013C13.0007 8.36797 12.7007 8.66797 12.334 8.66797Z"
        fill="#941C1F"
      />
    </svg>
  );
};

export const DashboaradActiveAvatarIcon = () => {
  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.00065 4.9987C6.28732 4.9987 7.33398 3.95203 7.33398 2.66536C7.33398 1.3787 6.28732 0.332031 5.00065 0.332031C3.71398 0.332031 2.66732 1.3787 2.66732 2.66536C2.66732 3.95203 3.71398 4.9987 5.00065 4.9987ZM5.00065 1.66536C5.55398 1.66536 6.00065 2.11203 6.00065 2.66536C6.00065 3.2187 5.55398 3.66536 5.00065 3.66536C4.44732 3.66536 4.00065 3.2187 4.00065 2.66536C4.00065 2.11203 4.44732 1.66536 5.00065 1.66536ZM5.03398 8.33203H2.18065C2.84065 7.9987 3.98065 7.66536 5.00065 7.66536C5.07398 7.66536 5.15398 7.67203 5.22732 7.67203C5.45398 7.18536 5.84732 6.78536 6.32065 6.46536C5.83398 6.3787 5.37398 6.33203 5.00065 6.33203C3.44065 6.33203 0.333984 7.11203 0.333984 8.66537V9.66537H5.00065V8.66537C5.00065 8.55203 5.01398 8.4387 5.03398 8.33203ZM10.0007 6.66536C8.77398 6.66536 6.33398 7.3387 6.33398 8.66537V9.66537H13.6673V8.66537C13.6673 7.3387 11.2273 6.66536 10.0007 6.66536ZM10.8073 5.45203C11.314 5.16536 11.6673 4.62536 11.6673 3.9987C11.6673 3.0787 10.9207 2.33203 10.0007 2.33203C9.08065 2.33203 8.33398 3.0787 8.33398 3.9987C8.33398 4.62536 8.68732 5.16536 9.19398 5.45203C9.43398 5.58536 9.70732 5.66536 10.0007 5.66536C10.294 5.66536 10.5673 5.58536 10.8073 5.45203Z"
        fill="#941C1F"
      />
    </svg>
  );
};
