import { useEventFormContext } from "../../../../context";
import { EditIcon, TrashIcon } from "assets/icons";
import { TicketTypeBackgound } from "assets/images";
import { Button } from "components/shared/Button";
import { findCurrencySymbolByCode } from "lib/country";
import { thousandFormatter } from "lib/number";
import React from "react";

const FormTicket = ({ ticket, updateTicket, deleteTicket }) => {
  const { event, asResellTicket } = useEventFormContext();

  return (
    <div
      style={{
        background: `url(${TicketTypeBackgound}) no-repeat`,
      }}
      className="tw-p-10 tw-px-10 tw-flex tw-flex-col tw-gap-6 tw-rounded-t-xl tw-relative !tw-bg-[length:100%_100%]"
    >
      <div className="tw-right-0 tw-absolute tw-mx-4 tw--my-6 tw-flex tw-gap-3">
        <Button
          className="!tw-rounded-full !tw-p-3 tw-border tw-border-black"
          onClick={updateTicket}
        >
          <EditIcon className="tw-w-6 tw-h-6" />
        </Button>
        <Button
          className="!tw-rounded-full !tw-p-3 tw-border tw-border-black"
          onClick={deleteTicket}
        >
          <TrashIcon className="tw-w-6 tw-h-6" />
        </Button>
      </div>

      <div className="tw-text-base tw-flex tw-gap-4 tw-items-center tw-mt-6">
        <span>Ticket Name:</span>
        <span className="tw-text-lg tw-font-medium">{ticket.name}</span>
      </div>
      <div className="tw-text-base tw-flex tw-gap-4 tw-items-center">
        <span>Price:</span>
        <span className="tw-text-lg tw-font-medium">
          {findCurrencySymbolByCode(event.currency)}
          {thousandFormatter(ticket.price)}
        </span>
      </div>
      <div className="tw-flex tw-gap-8">
        <div className="tw-text-base tw-flex tw-gap-4 tw-items-center">
          <span>Qty:</span>
          <span className="tw-text-lg tw-font-medium">{ticket.quantity}</span>
        </div>
        <div className="tw-text-base tw-flex tw-gap-4 tw-items-center">
          <span>Time Slot:</span>
          <span className="tw-text-lg tw-font-medium">{ticket.timeSlot}</span>
        </div>
      </div>

      {asResellTicket && (
        <div className="tw-bg-secondary-300 tw-w-fit tw-p-4 tw-px-8 tw-rounded-lg">
          <div className="tw-text-base tw-flex tw-gap-4 tw-items-center">
            <span>Face Value:</span>
            <span className="tw-text-lg tw-font-medium">
              {findCurrencySymbolByCode(event.currency)}
              {thousandFormatter(ticket.faceValue || 0)}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormTicket;
