import "./styles.scss";
import React from "react";

const EventDashRestaurants = () => {
  return (
    <>
      <div className="eventRest ml-1 mt-4">
        <div className="topNav">
          <h1>Restaurants</h1>
        </div>
        <div className="restCarousel row"></div>
      </div>
    </>
  );
};

export default EventDashRestaurants;
