import { SELECT_CUSTOM_CONTROL_STYLES } from "../constants";
import ThemedSelect from "components/shared/forms/ThemedSelect";
import { Field } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllEventCategoriesV2Request } from "state/slices/events";
import { convertArrayToSelectOptions } from "utilities";

const FormCategoryInput = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllEventCategoriesV2Request({ page: 1, perPage: 100 }));
  }, [dispatch]);

  const eventCategories = useSelector(
    (state) => state.events.newCategoriesData
  );

  return (
    <Field
      component={ThemedSelect}
      type="text"
      name="category"
      id="category"
      size="sm"
      placeholder="Choose Category"
      options={convertArrayToSelectOptions(
        eventCategories?.map((category) => category.name) || []
      )}
      required
      label="Category"
      labelClassName="tw-ml-4"
      customControlStyle={SELECT_CUSTOM_CONTROL_STYLES}
    />
  );
};

export default FormCategoryInput;
