import "./styles.scss";
import RecentTrans from "assets/images/recentTrans.png";
import React from "react";

const EventRecentTransaction = () => {
  return (
    <>
      <div className="mt-4 dashTrans">
        <h1>Transactions</h1>
        <div className="recentTrans">
          <img src={RecentTrans} alt="" />
          <h3>No Transaction Yet</h3>
          <span>
            After your first transaction <br /> you will able to view it here
          </span>
          <p>Got it</p>
        </div>
      </div>
    </>
  );
};

export default EventRecentTransaction;
