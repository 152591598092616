import "./styles.scss";
import { Icon } from "@iconify/react";
import Calendar from "assets/images/calendar4-week.svg";
import React from "react";

const PastEvents = () => {
  return (
    <>
      <div className="dashPastEvents">
        <div className="topNav">
          <h1>Past Events</h1>
          <div className="arrows">
            <div className="arrow">
              <Icon icon="ep:arrow-up" height={11} />
            </div>
            <div className="arrow">
              <Icon icon="ep:arrow-down" height={11} />
            </div>
          </div>
        </div>
        <div className="content">
          <img src={Calendar} alt="" />
          <h3>No Past Event</h3>
          <p>
            You currently don’t have any past <br /> events under your account{" "}
          </p>
          <span>Got it</span>
        </div>
      </div>
    </>
  );
};

export default PastEvents;
