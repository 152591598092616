import "./styles.scss";
import notFound from "assets/images/not_found.png";
import React from "react";
import { useHistory } from "react-router";

export const NotFoundPage = () => {
  const history = useHistory();
  const goHome = () => {
    history.push("/");
  };
  return (
    <section className="page-content">
      <div className="container">
        <div className="notFound_page">
          <img src={notFound} alt="" />
          <p>Oops...page not found. Let’s try again</p>
          <div className="d-flex justify-content-center" onClick={goHome}>
            <button className="btn btn-green btn-lg px-5">Go Home</button>
          </div>
        </div>
      </div>
    </section>
  );
};
