import React from "react";

const FuelBuyerDashboardPageTitle = ({ title, description }) => {
  return (
    <div>
      <h5 className="tw-text-2xl tw-font-medium">{title}</h5>
      <p className="tw-text-gray-normal">{description}</p>
    </div>
  );
};

export default FuelBuyerDashboardPageTitle;
