import * as yup from "yup";

export const initialValues = {
  enableStepOut: true,
  refundPolicy: "Flexible",
};

export const validationSchema = yup.object().shape({});

export const SELECT_FORM_KEYS = ["dressCode", "ageLimit", "invitationId"];

export const LATE_TIME_ENRTY_FORMAT = "hh:mm a";
