import FormEventDetails from "./Steps/EventDetails";
import SettingsAndMedia from "./Steps/SettingsAndMedia";
import FormTicketInformation from "./Steps/TicketInformation";
import { STEPS, STEPS_VALUES } from "./constants";
import EventFormProvider, { EventFormContext } from "./context";
import { BackArrowIcon, ChevronRightIcon } from "assets/icons";
import DashboardFooter from "components/pages/authenticated-new/components/Footer";
import TopBar from "components/pages/authenticated-new/components/TopBar";
import { Button } from "components/shared/Button";
import Loader from "components/shared/Loader";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { cn } from "utilities/cn";

const EventForm = () => {
  const [currentStep, setCurrentStep] = useState(STEPS[0]?.value);

  const [maximumStep, setMaximumStep] = useState(1);

  const goToStep = (number) => {
    if (number <= maximumStep) setCurrentStep(number);
  };

  const history = useHistory();

  const location = useLocation();

  const { from } = location.state || { from: { pathname: "/" } };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  return (
    <EventFormProvider>
      <EventFormContext.Consumer>
        {({ asResellTicket, isSettingUp, isEditPage }) =>
          isSettingUp ? (
            <div className="tw-w-screen tw-h-screen tw-flex tw-justify-center tw-items-center">
              <Loader title="Fetching Event.." />
            </div>
          ) : (
            <div className="tw-min-h-screen">
              <div className=" tw-bg-white tw-shadow-2xl">
                <div className="tw-px-6 !tw--mt-4 tw-mx-auto tw-max-w-[108rem]">
                  <div className="tw-relative tw-w-full">
                    <div className="tw-mt-4">
                      <TopBar
                        pageTitle={
                          asResellTicket
                            ? `${
                                !isEditPage
                                  ? "Resell Ticket"
                                  : "Edit Resell Ticket"
                              }`
                            : `${!isEditPage ? "Create" : "Edit"} Event`
                        }
                      />
                    </div>
                  </div>

                  <div className="tw-my-6">
                    <Button
                      onClick={() => history.push(from || "/")}
                      className="tw-flex tw-gap-4 tw-shadow-none tw-border !tw-rounded-full tw-border-black"
                    >
                      <BackArrowIcon /> <span>Go Back</span>
                    </Button>
                  </div>

                  <div className="tw-px-6 tw-max-w-8xl tw-mx-auto tw-mt-12 tw-pb-6">
                    <div className="tw-flex tw-justify-between tw-items-center tw-gap-12">
                      {STEPS.map((step, Idx) => (
                        <div
                          key={Idx}
                          className={cn(
                            "tw-flex tw-items-center tw-justify-between tw-max-w-1/3 tw-w-full tw-cursor-pointer tw-opacity-70",
                            maximumStep >= step.value && "!tw-opacity-100"
                          )}
                          onClick={() => goToStep(step.value)}
                        >
                          <div className="tw-flex tw-items-center tw-gap-8 tw-transition-all ">
                            <div
                              className={cn(
                                "tw-rounded-full tw-p-3 tw-px-5 tw-bg-gray-200",
                                Idx === 0 && "tw-px-6",
                                maximumStep >= step.value &&
                                  "tw-bg-primary-800 tw-text-white"
                              )}
                            >
                              {Idx + 1}
                            </div>
                            <div>{step.title}</div>
                          </div>
                          {Idx !== STEPS.length - 1 && (
                            <div>
                              <ChevronRightIcon className="tw-text-gray-700 tw-w-4 tw-h-4" />
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className="">
                {currentStep === STEPS_VALUES.EventDetails && (
                  <FormEventDetails
                    successCallback={() => {
                      setCurrentStep(2);
                      setMaximumStep((oldMaxStep) =>
                        oldMaxStep < STEPS_VALUES.TicketInformation
                          ? STEPS_VALUES.TicketInformation
                          : oldMaxStep
                      );
                    }}
                  />
                )}

                {currentStep === STEPS_VALUES.TicketInformation && (
                  <FormTicketInformation
                    asEdit={false}
                    successCallback={() => {
                      setCurrentStep(STEPS_VALUES.SettingsAndMedia);
                      setMaximumStep((oldMaxStep) =>
                        oldMaxStep < STEPS_VALUES.SettingsAndMedia
                          ? STEPS_VALUES.SettingsAndMedia
                          : oldMaxStep
                      );
                    }}
                    goToPreviousStep={() =>
                      setCurrentStep(STEPS_VALUES.EventDetails)
                    }
                  />
                )}

                {currentStep === STEPS_VALUES.SettingsAndMedia && (
                  <SettingsAndMedia
                    goToPreviousStep={() =>
                      setCurrentStep(STEPS_VALUES.TicketInformation)
                    }
                  />
                )}
              </div>

              <DashboardFooter />
            </div>
          )
        }
      </EventFormContext.Consumer>
    </EventFormProvider>
  );
};

export default EventForm;
