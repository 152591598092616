import OGD from "../../../../assets/images/OGD.png";
import { findCountryByISO3, findIso2ByISO3 } from "../../../../lib/country";
import { setLocationParameters } from "../../../../state/slices/location";
import "./styles.scss";
import { Icon } from "@iconify/react";
import AppStoreImage from "assets/images/appstore.svg";
import PlayStoreImage from "assets/images/google-play-badge.svg";
import logo from "assets/images/logo.svg";
import PlaceSuggestionWrapper from "components/shared/placeSuggestionWrapper";
import React, { useState, useRef, useEffect } from "react";
import { Button } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import ReactCountryFlag from "react-country-flag";
import { useDispatch, useSelector } from "react-redux";
import { fetchRelatedEventsByCountryRequest } from "state/slices/events";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div ref={ref} onClick={(e) => onClick(e)}>
    {children}
  </div>
));

CustomToggle.displayName = "CustomToggle";

export default function Footer(
  { onlyCopyright, setShowDropdown, showDropdown },
  props
) {
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // Clicked outside the dropdown, so close it
        props.onToggle(false);
      }
    }

    // Attach event listener to document
    document.addEventListener("click", handleClickOutside);

    // Detach event listener when component is unmounted
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [props]);

  const dispatch = useDispatch();
  const location = useSelector((state) => state.location);
  const events = useSelector((state) => state.events);
  const cities = events.relatedEventsData.slice(0, 7);
  const [searchQuery, setSearchQuery] = useState(location.region || "");

  React.useEffect(() => {
    window.scrollTo(0, 0);
    setSearchQuery(location.region);
  }, [location.region]);

  React.useEffect(() => {
    dispatch(fetchRelatedEventsByCountryRequest(location.countryISO3));
  }, [location.countryISO3]);

  function onCityClick(city) {
    return () => {
      const country = findCountryByISO3(city.countryIso_3);
      const payload = {
        latitude: city.lat,
        longitude: city.long,
        region: city.region,
        countryISO3: country.iso3,
        countryLanguage: country.language,
        countryCurrency: country.currency,
        country: country.name,
      };

      dispatch(setLocationParameters(payload));
    };
  }

  const updateLocation = (payload) => {
    dispatch(setLocationParameters(payload));
  };

  return (
    <footer className="footer" id="page-footer">
      {!onlyCopyright ? (
        <div className="main tw-max-w-10xl tw-m-auto">
          <div className="container-fluid main-page-container--padding">
            <div className="subscribe-wrapper">
              <h5 className="pt-2 font-weight-bold">
                Subscribe to our newsletter, and get updated
              </h5>

              <div className="input-group pt-2">
                <input
                  type="text"
                  className="form-control inpt-ee py-4 my-auto bg-gray-50"
                  placeholder="Enter email"
                />
                <button
                  className="btn btn-sub !tw-bg-primary-800 text-white input-group-text"
                  type="button"
                >
                  SUBSCRIBE
                </button>
              </div>
            </div>

            <div className="row pb-4 pt-8">
              <div className="col-xs-12 col-sm-6 col-md-3">
                <div className="d-flex flex-column h-100 py-4 !tw-items-stretch">
                  <img
                    src={logo}
                    alt=""
                    className="logo mb-4 tw-mx-auto sm:tw-mx-0"
                  />
                  <div className="location-language-wrapper mt-auto tw-mx-auto sm:tw-mx-0">
                    <div className="label">ENG</div>
                    <Dropdown>
                      <Dropdown.Toggle
                        as={CustomToggle}
                        variant="success"
                        id="dropdown-basic"
                      >
                        <div className="label country">
                          <ReactCountryFlag
                            countryCode={
                              findIso2ByISO3(location.countryISO3) || ""
                            }
                            svg
                            style={{
                              fontSize: "43px",
                            }}
                          />
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item as={"div"}>
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <PlaceSuggestionWrapper
                              value={searchQuery}
                              onChange={setSearchQuery}
                              onSelect={updateLocation}
                              inputClassName="form-control form-control-sm ml-3 px-0 text-base font-weight-normal location-input overflow-ellipsis"
                              showIcon
                            />
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <div className="label currency">
                      {`${location.countryCurrency?.symbol}`}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-6 col-md-3">
                <h4 className="font-weight-bold">Support</h4>
                <ul>
                  <li className="overflow-ellipsis">
                    <a href="/about">About Us</a>
                  </li>
                  <li className="overflow-ellipsis">
                    <a href="/contact">Contact Us</a>
                  </li>
                  <li className="overflow-ellipsis">
                    <a href="/faq">FAQs</a>
                  </li>
                </ul>
              </div>

              <div className="col-6 col-md-3">
                <h4 className="font-weight-bold">More Links</h4>
                <ul>
                  <li className="overflow-ellipsis">
                    <a href="/login">Sign in</a>
                  </li>
                  <li className="overflow-ellipsis">
                    <a href="/dashboard/create">Sell Ticket</a>
                  </li>
                  <li className="overflow-ellipsis">
                    <a href="/dashboard/create">Create Event</a>
                  </li>
                </ul>
              </div>

              <div className="col-6 col-md-3">
                <h4 className="font-weight-bold">Legal</h4>
                <ul>
                  <li className="overflow-ellipsis">
                    <a href="/privacy">Privacy Policy</a>
                  </li>
                  <li className="overflow-ellipsis">
                    <a href="/terms">Terms & Conditions</a>
                  </li>
                </ul>
                <div className="social-icon-container mb-3 pt-3">
                  <a
                    href="https://www.facebook.com/triple7events/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon className="social-icon" icon="iconoir:facebook" />
                  </a>
                  <a
                    href="https://www.instagram.com/triple7events/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon className="social-icon" icon="cib:instagram" />
                  </a>
                  <a
                    href="https://twitter.com/Triple7events/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon className="social-icon" icon="fa:twitter" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/triple7events-com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon className="social-icon" icon="fa:linkedin" />
                  </a>
                </div>
              </div>
            </div>
            <div className="appstore-icons d-flex flex-row justify-content-center flex-wrap">
              <img src={AppStoreImage} alt="" />
              <img src={PlayStoreImage} alt="" />
            </div>
          </div>
        </div>
      ) : null}

      <div className="bg-gray-400 text-white text-center">
        <div className="container-fluid main-page-container--padding">
          {!onlyCopyright && Array.isArray(cities) && cities.length ? (
            <div className="border-bottom border-gray-300 py-4 font-weight-bold text-sm">
              {cities.map((city, index) => (
                <React.Fragment key={index}>
                  <span
                    onClick={onCityClick(city)}
                    className="pointer text-gray-100 px-2"
                  >
                    {city.name}
                  </span>
                  {index !== cities.length - 1 && (
                    <span className="text-white-50">|</span>
                  )}
                </React.Fragment>
              ))}
            </div>
          ) : null}
          <div className="py-4_5 text-base">
            Triple7events is the trade name of Hobnika Limited ©{" "}
            {new Date().getFullYear()} Triple7events, All Rights Reserved.
          </div>
        </div>
      </div>
    </footer>
  );
}
