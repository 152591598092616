export const RECURRING_FREQUENCY_VALUES = {
  Daily: "Daily",
  Weekly: "Weekly",
  Monthly: "Monthly",
};

export const RECURRING_FREQUENCIES = [
  RECURRING_FREQUENCY_VALUES.Daily,
  RECURRING_FREQUENCY_VALUES.Weekly,
  RECURRING_FREQUENCY_VALUES.Monthly,
];

export const RECURRING_TYPE_VALUES = {
  Week: "Week",
  Date: "Date",
};

export const MONTHLY_OCCURRENCE = ["First", "Second", "Third", "Fourth"];

export const RECURRING_REAPEAT_DAYS = [
  {
    value: "Sunday",
    label: "Sun",
  },
  {
    value: "Monday",
    label: "Mon",
  },
  {
    value: "Tuesday",
    label: "Tue",
  },
  {
    value: "Wednesday",
    label: "Wed",
  },
  {
    value: "Thursday",
    label: "Thur",
  },
  {
    value: "Friday",
    label: "Fri",
  },
  {
    value: "Saturday",
    label: "Sat",
  },
];

export const THIRTY_ONE_DAYS = [...Array(31)].map((each, index) => {
  return index + 1 == 1
    ? { id: index + 1, label: `${index + 1}st` }
    : index + 1 == 31
    ? { id: index + 1, label: `${index + 1}st` }
    : index + 1 == 21
    ? { id: index + 1, label: `${index + 1}st` }
    : index + 1 == 2
    ? { id: index + 1, label: `${index + 1}nd` }
    : index + 1 == 22
    ? { id: index + 1, label: `${index + 1}nd` }
    : index + 1 == 3
    ? { id: index + 1, label: `${index + 1}rd` }
    : index + 1 == 23
    ? { id: index + 1, label: `${index + 1}rd` }
    : { id: index + 1, label: `${index + 1}th` };
});
