import * as yup from "yup";

export const initialValues = {
  email: "",
  password: "",
};

export const validationSchema = yup.object().shape({
  email: yup.string().label("Email").required(),
  password: yup.string().label("Password").required(),
});
