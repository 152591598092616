import { usePosition } from "hooks/usePosition";
import React from "react";
import { useDispatch } from "react-redux";
import { setLocationParameters } from "state/slices/location";

export default function GetCoordinates(props) {
  const dispatch = useDispatch();
  const { position } = usePosition();

  React.useEffect(() => {
    if (position.latitude && position.longitude) {
      dispatch(setLocationParameters(position));
      props.setGettingPosition(false);
    }
  }, [position.latitude, position.longitude]);

  return null;
}
