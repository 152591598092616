import FuelBuyerDashboardContainer from "components/pages/authenticated-new/pages/Fuel/FuelBuyer";
import FuelBuyerDashboard from "components/pages/authenticated-new/pages/Fuel/FuelBuyer/pages/Dashboard";
import FuelBuyerDriversFormPage from "components/pages/authenticated-new/pages/Fuel/FuelBuyer/pages/Drivers/Form";
import FuelBuyerDriversTablePage from "components/pages/authenticated-new/pages/Fuel/FuelBuyer/pages/Drivers/Table";
import FuelBuyerOrderPage from "components/pages/authenticated-new/pages/Fuel/FuelBuyer/pages/Orders/Order";
import FuelBuyerOrdersTablePage from "components/pages/authenticated-new/pages/Fuel/FuelBuyer/pages/Orders/Table";
import SelectFuelPurchaseTypeView from "components/pages/authenticated-new/pages/Fuel/FuelBuyer/pages/Purchase/screens";
import FuelBuyerGenerateCode from "components/pages/authenticated-new/pages/Fuel/FuelBuyer/pages/Purchase/screens/generateCode";
import FuelBuyerPurchaseOrderPage from "components/pages/authenticated-new/pages/Fuel/FuelBuyer/pages/Purchase/screens/order";
import FuelBuyerSelectFuelStationPage from "components/pages/authenticated-new/pages/Fuel/FuelBuyer/pages/Purchase/screens/selectFuelStation";
import IndividualFuelStationSetup from "components/pages/authenticated-new/pages/Fuel/FuelBuyer/pages/Setup";
import FuelBuyerVehiclesFormPage from "components/pages/authenticated-new/pages/Fuel/FuelBuyer/pages/Vehicles/Form";
import FuelBuyerVehiclesTablePage from "components/pages/authenticated-new/pages/Fuel/FuelBuyer/pages/Vehicles/Table";
import { NotFoundPage } from "components/pages/guest/_404Page";
import Loader from "components/shared/Loader";
import React, { Suspense } from "react";
import { Route, Switch, useRouteMatch } from "react-router";

export const FuelStationRoutes = () => {
  const { path } = useRouteMatch();

  const customPath = `${path}`;

  return (
    <>
      {/* <FuelBuyerDashboardContainer>
        
      </FuelBuyerDashboardContainer> */}
      <Suspense
        fallback={
          <div className="d-flex justify-content-center align-items-center h-vh">
            <Loader />
          </div>
        }
      >
        <Switch>
          {/* <Route exact path={`${customPath}`} component={ManageEventPage} /> */}

          <Route
            exact
            path={`${customPath}/dashboard`}
            component={FuelBuyerDashboard}
          />

          <Route
            exact
            path={`${customPath}/setup`}
            component={IndividualFuelStationSetup}
          />

          <Route
            exact
            path={`${customPath}/vehicles`}
            component={FuelBuyerVehiclesTablePage}
          />

          <Route
            exact
            path={`${customPath}/vehicles/create`}
            component={FuelBuyerVehiclesFormPage}
          />

          <Route
            exact
            path={`${customPath}/vehicles/edit/:id`}
            component={FuelBuyerVehiclesFormPage}
          />

          <Route
            exact
            path={`${customPath}/drivers`}
            component={FuelBuyerDriversTablePage}
          />

          <Route
            exact
            path={`${customPath}/drivers/create`}
            component={FuelBuyerDriversFormPage}
          />

          <Route
            exact
            path={`${customPath}/drivers/edit/:id`}
            component={FuelBuyerDriversFormPage}
          />

          {/* <Route
            exact
            path={`${customPath}/purchase`}
            component={FuelBuyerPurchasePage}
          /> */}
          <Route
            exact
            path={`${customPath}/purchase`}
            component={SelectFuelPurchaseTypeView}
          />

          {/* routes to handle fuel station purchase flow  */}
          <Route
            exact
            path={`${customPath}/purchase/select-fuelstation`}
            component={FuelBuyerSelectFuelStationPage}
          />

          <Route
            exact
            path={`${customPath}/purchase/order`}
            component={FuelBuyerPurchaseOrderPage}
          />

          <Route
            exact
            path={`${customPath}/purchase/generate-code`}
            component={FuelBuyerGenerateCode}
          />

          {/* End fuel station route flow  */}
          <Route
            exact
            path={`${customPath}/orders`}
            component={FuelBuyerOrdersTablePage}
          />

          <Route
            exact
            path={`${customPath}/orders/:id`}
            component={FuelBuyerOrderPage}
          />

          <Route path="*" component={NotFoundPage} />
        </Switch>
      </Suspense>
    </>
  );
};
