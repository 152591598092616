import { VehicleCarIcon } from "assets/icons";
import React from "react";

const VehiclesRegPanel = ({ bg, licenseNumber, manufacturerYear, model }) => {
  const extendStyle = `${bg ? bg : "tw-bg-gray-100 "}`;
  return (
    <div
      className={` ${extendStyle} tw-mt-2 tw-h-[124px] tw-flex tw-items-center  tw-py-8 tw-px-10 tw-rounded-md tw-border tw-cursor-pointer `}
    >
      <div className="tw-my-5 tw-flex tw-gap-5 tw-items-center">
        <div className="tw-bg-white tw-w-[60px] tw-h-[60px] tw-rounded-full tw-flex tw-justify-center tw-items-center">
          <VehicleCarIcon />
        </div>

        <div className="leading-1 tw-m-0">
          <p className=" tw-m-0">{model}</p>
          <h3 className="tw-font-semibold tw-m-0">{licenseNumber}</h3>
          {/* <p className="tw-m-0">Color: White</p> */}
        </div>
      </div>
    </div>
  );
};

export default VehiclesRegPanel;
