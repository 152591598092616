import {
  CalendarIcon,
  DashboardIcon,
  TicketsIcon,
  UserIcon,
} from "assets/icons";
import React from "react";

export const ACCOUNT_NAV = [
  {
    name: "Dashboard",
    link: "/dashboard",
    icon: <DashboardIcon />,
  },
  {
    name: "My Tickets",
    link: "/dashboard/events/tickets",
    icon: <TicketsIcon className="tw-w-6 tw-h-6" />,
  },
  {
    name: "My Events",
    link: "/dashboard/events",
    vendorLink: "/dashboard/organiser/events",
    icon: <CalendarIcon />,
  },
  {
    name: "My Account",
    link: "/dashboard/settings",
    icon: <UserIcon className="tw-w-6 tw-h-6" />,
  },
];
