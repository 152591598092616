import { useEventFormContext } from "../../../../context";
import { SELECT_CUSTOM_CONTROL_STYLES } from "../../../EventDetails/constants";
import {
  SELECT_FORM_KEYS,
  TICKET_TYPES,
  TICKET_TYPE_VALUES,
  TIME_SLOTS,
  initialValues,
  validationSchema,
} from "./constants";
import { unwrapResult } from "@reduxjs/toolkit";
import { CloseIcon } from "assets/icons";
import { Button } from "components/shared/Button";
import TextInput from "components/shared/forms/TextInput";
import ThemedSelect from "components/shared/forms/ThemedSelect";
import Modal from "components/shared/modal";
import { DELIVERY_TYPES } from "contexts/constants";
import { Field, Form, Formik } from "formik";
import { DateTime } from "luxon";
import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  createEventTicketRequest,
  updateEventTicketRequest,
} from "state/slices/events";
import {
  convertArrayToSelectOptions,
  convertValueToSelectOption,
  getOneLinerErrorMessage,
  transformSelectObjects,
} from "utilities";
import { cn } from "utilities/cn";

const FormTicketModal = ({
  isOpen,
  closeModal,
  ticket,
  tickets,
  asEdit,
  updateTickets,
}) => {
  const { event, asResellTicket } = useEventFormContext();

  const [isLoading, setIsLoading] = useState();

  const dispatch = useDispatch();

  const addTicket = (ticket) => {
    updateTickets((oldTickets) => [...oldTickets, ticket]);
  };

  const editTicket = (ticket) => {
    updateTickets((oldTickets) => {
      const findTicketIndex = oldTickets.findIndex(
        (oldTicket) => oldTicket.id === ticket.id
      );
      if (findTicketIndex < 0) return oldTickets;

      oldTickets[findTicketIndex] = ticket;
      return [...oldTickets];
    });
  };

  const createTicket = async (formValues) => {
    if (tickets.find((ticketItem) => ticketItem.name === formValues.name)) {
      toast.error(`A ticket with the name '${formValues.name}' already exist`);
      return;
    }

    setIsLoading(true);
    const payload = {
      eventId: event.id,
      ...formValues,
      ...transformSelectObjects(formValues, SELECT_FORM_KEYS),
    };
    const resultAction = await dispatch(createEventTicketRequest(payload));
    if (createEventTicketRequest.fulfilled.match(resultAction)) {
      const response = unwrapResult(resultAction);
      addTicket(response.data);
      setIsLoading(false);
      toast.success("Successfully created ticket");
      closeModal();
    } else {
      setIsLoading(false);
      if (resultAction.payload.message) {
        toast.error(resultAction.payload.message);
      } else {
        toast.error(
          getOneLinerErrorMessage(resultAction.payload) ||
            resultAction.error?.message ||
            "An error occurred, please try again."
        );
      }
    }
  };

  const updateTicket = async (formValues) => {
    if (
      tickets.find(
        (ticketItem) =>
          ticketItem.name === formValues.name && ticketItem.id !== ticket.id
      )
    ) {
      toast.error(`A ticket with the name '${formValues.name}' already exist`);
      return;
    }

    setIsLoading(true);
    const payload = {
      eventId: event.id,
      ...formValues,
      ...transformSelectObjects(formValues, SELECT_FORM_KEYS),
    };
    const resultAction = await dispatch(updateEventTicketRequest(payload));
    if (updateEventTicketRequest.fulfilled.match(resultAction)) {
      const response = unwrapResult(resultAction);
      editTicket(response.data);
      setIsLoading(false);
      toast.success("Successfully updateticket");
      closeModal();
    } else {
      setIsLoading(false);
      if (resultAction.payload.message) {
        toast.error(resultAction.payload.message);
      } else {
        toast.error(
          getOneLinerErrorMessage(resultAction.payload) ||
            resultAction.error?.message ||
            "An error occurred, please try again."
        );
      }
    }
  };

  const eventTimeSlots = useMemo(() => {
    return (
      event.dates?.map(({ startDate }) =>
        DateTime.fromISO(startDate).toFormat("hh:mm a")
      ) || []
    );
  }, [event]);

  const transformInitialValues = (ticket) => {
    const newTicket = { ...ticket };
    SELECT_FORM_KEYS.forEach((key) => {
      newTicket[key] = convertValueToSelectOption(newTicket[key]);
    });

    return newTicket;
  };

  return (
    <Modal
      isActive={isOpen}
      onClose={closeModal}
      classNames={{
        root: "tw-max-w-[600px] tw-m-auto",
        modal: "tw-w-full",
      }}
      closeIcon={
        <CloseIcon className="tw-mt-2 tw-mr-3 tw-z-10" onClick={closeModal} />
      }
    >
      <div className="tw-text-lg">
        <div className="px-4 py-4 d-flex justify-content-center align-items-center tw-border-b">
          <h5 className="font-weight-bold text-xl mb-0 tw-text-center">
            {asEdit ? "Edit" : "Create"} Ticket
          </h5>
        </div>

        <div className="tw-relative modal-body tw-min-h-screen/2.5 tw-items-center tw-justify-center tw-text-base tw-overflow-x-hidden">
          <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-3 tw-py-5 tw-px-12">
            <div className="tw-w-full">
              <Formik
                initialValues={
                  asEdit ? transformInitialValues(ticket) : initialValues
                }
                validationSchema={validationSchema}
                onSubmit={asEdit ? updateTicket : createTicket}
                enableReinitialize
              >
                {({ handleSubmit, values, setFieldValue }) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <div className="tw-max-w-2xl tw-flex tw-flex-col tw-gap-6">
                        <div className="tw-grid tw-grid-cols-2 tw-gap-3">
                          <div>
                            <Field
                              component={TextInput}
                              className="tw-input"
                              label="Ticket Name"
                              labelClassName="tw-ml-4"
                              name="name"
                              id="name"
                              placeholder="Enter Ticket Name"
                              required
                            />
                          </div>

                          <div>
                            <Field
                              component={TextInput}
                              className="tw-input"
                              label="Ticket Quantity"
                              labelClassName="tw-ml-4"
                              name="quantity"
                              id="quantity"
                              placeholder="Enter Ticket Quantity"
                              required
                            />
                          </div>
                        </div>

                        <div className="tw-grid tw-grid-cols-2 tw-gap-3">
                          <div>
                            <Field
                              component={ThemedSelect}
                              size="base"
                              name="type"
                              id="type"
                              placeholder="Ticket Type"
                              label="Ticket Type"
                              labelClassName="tw-ml-4"
                              onChange={(value) => {
                                setFieldValue("type", value);
                                if (value?.value === TICKET_TYPE_VALUES.Free) {
                                  setFieldValue("price", 0);
                                }
                              }}
                              options={convertArrayToSelectOptions(
                                TICKET_TYPES
                              )}
                              customControlStyle={SELECT_CUSTOM_CONTROL_STYLES}
                              required
                            />
                          </div>

                          {values.type?.value !== TICKET_TYPE_VALUES.Free && (
                            <div>
                              <Field
                                component={TextInput}
                                className="tw-input"
                                label="Price"
                                labelClassName="tw-ml-4"
                                name="price"
                                id="price"
                                placeholder="Enter Amount"
                                required
                              />
                            </div>
                          )}
                        </div>

                        <div className="tw-w-full">
                          <Field
                            component={ThemedSelect}
                            size="base"
                            name="timeSlot"
                            id="timeSlot"
                            placeholder="Time Slot"
                            label="Time Slot"
                            labelClassName="tw-ml-4"
                            options={convertArrayToSelectOptions([
                              ...TIME_SLOTS,
                              ...eventTimeSlots,
                            ])}
                            customControlStyle={SELECT_CUSTOM_CONTROL_STYLES}
                            required
                          />
                        </div>

                        <div
                          className={cn(
                            "tw-grid tw-grid-cols-1 tw-gap-3",
                            asResellTicket && "tw-grid-cols-2"
                          )}
                        >
                          <div className="tw-w-full">
                            <Field
                              component={ThemedSelect}
                              size="base"
                              name="deliveryType"
                              id="deliveryType"
                              placeholder="Delivery Type"
                              label="Delivery Type"
                              labelClassName="tw-ml-4"
                              options={convertArrayToSelectOptions(
                                DELIVERY_TYPES
                              )}
                              customControlStyle={SELECT_CUSTOM_CONTROL_STYLES}
                              required
                            />
                          </div>

                          {asResellTicket && (
                            <div>
                              <Field
                                component={TextInput}
                                className="tw-input"
                                label="Face Value"
                                labelClassName="tw-ml-4"
                                name="faceValue"
                                id="faceValue"
                                placeholder="Enter Amount"
                                // helpDescription="The law requires you to state
                                //   the original purchase price of
                                //   the ticket."
                                required
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="tw-flex tw-justify-center tw-gap-6 tw-mt-8">
                        <Button
                          type="submit"
                          className="tw-border-none xs:tw-px-12 xs:tw-h-14 tw-py-5 tw-rounded-md tw-bg-primary-800 tw-text-white"
                          variant="rounded"
                          loading={isLoading}
                          disabled={isLoading}
                        >
                          {asEdit ? "Update" : "Create"}
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FormTicketModal;
