import { saveStorageData, StorageKeys } from "../../lib/storageManager";
import { createSlice } from "@reduxjs/toolkit";
import { DateTime } from "luxon";

const initialState = {
  latitude: 51.5073509,
  longitude: -0.1277583,
  region: "London, UK",
  countryISO3: "GBR",
  countryLanguage: {
    code: "en",
    name: "English",
  },
  countryCurrency: {
    code: "GBP",
    name: "British pound",
    symbol: "£",
  },
  country: "United Kingdom of Great Britain and Northern Ireland",
};

const locationSlice = createSlice({
  name: "location",
  initialState: initialState,
  reducers: {
    setLocationParameters: (state, action) => {
      const locationPayload = {
        data: action.payload,
        expiringTime: DateTime.now().plus({ hours: 1 }).toMillis(),
      };
      saveStorageData(StorageKeys.Location, locationPayload);
      return {
        ...state,
        latitude: action.payload.latitude,
        longitude: action.payload.longitude,
        region: action.payload.region,
        country: action.payload.country,
        countryISO3: action.payload.countryISO3,
        countryCurrency: action.payload.countryCurrency,
        countryLanguage: action.payload.countryLanguage,
      };
    },
  },
});

export const { setLocationParameters } = locationSlice.actions;
export default locationSlice.reducer;
