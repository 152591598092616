import axios from "../api-v2";


export const fetchFuelPurchasedDetailsWithTrxId = (id) => {
  return axios.get(`/orders/fuel-station/${id}/details`);
};

export const addbankDetail = () => {
  return axios.get(`/bank-details`);
};

export const fetchBankList = () => {
  return axios.get(`/payment/banks?country=NG`);
};

export const fetchSignleStaff = (staffId, payload) => {
  return axios.get(`/vendor/staff/${staffId}`, { params: payload });
};

export const fetchVendorFuelStationList = (payload) => {
  return axios("/vendor/fuel-station/list", payload);
};

export const fetchVendorsProducts = (payload) => {
  return axios("/products", payload);
};

export const createVendorsProduct = (payload) => {
  return axios.post("/products", payload);
};

export const createFuelStation = (payload) => {
  return axios.post("/fuel-station", payload);
};

export const registerVendor = (payload) => {
  return axios.post("/fuel-station/registration", payload);
};

//validate vendor
export const validateVendor = (payload) => {
  return axios.post("/vendor/validate", payload);
};

export const fetchFuelCustomersOrders = (payload) => {
  return axios.get("/orders/fuel-station-income", payload);
};

export const fetchFuelStationTransactions = ({
  payload,
  perPage,
  page,
  customerParams,
}) => {
  return axios.get(
    `/fuel-station/transactions/list?page=${page}&limit=${perPage}${
      customerParams || ""
    }`
  );
};

export const createFuelVendorStaff = (payload) => {
  return axios.post("/vendor/staff/add", payload);
};

export const editFuelVendorStaff = (payload) => {
  return axios.post(`/vendor/staff/update/${payload.id}`, payload);
};

export const fetchFuelVendorStaffs = (payload) => {
  return axios.get(`/vendor/stafflist/02a49e9a-79a4-4164-9f6a-b2a0293839fe`);
};

export const fetchFuelBuyerCustomerDashboard = (payload) => {
  return axios.get("/buyer", payload);
};

export const createFuelBuyerCustomer = (payload) => {
  return axios.post("/buyer/create", payload);
};
export const createFuelVendor = (paylaod) => {
  return axios.post("/vendor/create", paylaod);
};

export const fetchAllFuelStations = ({ page, perPage, customParameters }) => {
  return axios.get(
    `/fuel-station/?page=${page}&limit=${perPage}${customParameters || ""}`
  );
};

export const createFuelBuyer = (payload) => {
  return axios.post("/fuel-station/buy-fuel/", payload);
};

export const createDriver = (payload) => {
  return axios.post(`/driver`, payload);
};

export const updateDriver = (id, payload) => {
  return axios.patch(`/driver/${id}`, payload);
};

export const createVehicle = (payload) => {
  return axios.post(`/vehicle`, payload);
};

export const updateVehicle = (id, payload) => {
  return axios.patch(`/vehicle/${id}`, payload);
};

export const fetchPaginatedDrivers = ({ page, perPage, customParameters }) => {
  return axios.get(
    `/driver/?page=${page}&limit=${perPage}${customParameters || ""}`
  );
};

export const fetchPaginatedVehicles = ({ page, perPage, customParameters }) => {
  return axios.get(
    `/vehicle/?page=${page}&limit=${perPage}${customParameters || ""}`
  );
};

export const deleteDriver = (id = "") => {
  return axios.delete(`/driver/${id}`);
};

export const fetchDriver = (driverId) => {
  return axios.get(`/driver/${driverId}`);
};

export const deleteVehicle = (id = "") => {
  return axios.delete(`/vehicle/${id}`);
};

export const fetchVehicle = (driverId) => {
  return axios.get(`/vehicle/${driverId}`);
};
