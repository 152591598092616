import TextInput from "./TextInput";
import React, { Fragment } from "react";
import ReactDatePicker from "react-datepicker";
import { cn } from "utilities/cn";

const DatePicker = ({
  field,
  form: { touched, errors, setFieldValue },
  variant,
  label,
  labelClassName,
  required,
  className,
  ...props
}) => {
  const hasError = touched[field.name] && errors[field.name];

  return (
    <Fragment>
      <div className="tw-w-full">
        {label ? (
          <label
            htmlFor={field.name}
            className={cn("tw-flex tw-gap-1", labelClassName)}
          >
            {label} {required ? <span className="tw-text-red-500">*</span> : ""}
          </label>
        ) : (
          ""
        )}
        <ReactDatePicker
          autoComplete="off"
          showPopperArrow={false}
          className={
            className ||
            `appearance-none w-full w-100 mt-2 py-2.5 px-3 bg-${
              variant || "gray-100"
            } rounded-md border border-gray-200 text-sm text-gray-900 focus:border-orange-300 focus:outline-none transition ease-in-out duration-300`
          }
          wrapperClassName="!tw-w-full"
          name={field.name}
          placeholderText={props.placeholder}
          selected={field.value}
          onChangeRaw={(e) => e.preventDefault()}
          onChange={(value) => setFieldValue(field.name, value)}
          onBlur={field.onBlur}
          calendarClassName="border-0 shadow font-nunito-sans text-sm"
          dateFormat="dd/MM/yyyy"
          dropdownMode="select"
          showMonthDropdown={true}
          showYearDropdown={true}
          required={required}
          {...props}
        />

        {hasError && (
          <span className="text-red-600 text-xs mt-1 animate-fade-in-down">
            {errors[field.name]}
          </span>
        )}
      </div>
    </Fragment>
  );
};

export default DatePicker;
