export const SIDEBAR_EXCLUDED_PATHS = ["/dashboard/settings"];

export const PAGE_TITLES = [
  { path: "/dashboard/organiser/events", title: "Events" },
  { path: "/dashboard/events", title: "Events" },
  { path: "/dashboard/events/tickets", title: "My Tickets" },
  {
    path: "/dashboard/organiser/events/transactions",
    title: "Ticket Transactions",
  },
  { path: "/dashboard/settings", title: "Account Settings" },
  { path: "/dashboard/business-wallet", title: "Business Wallet" },
];

export const DEFAULT_PAGE_TITLE = "DASHBOARD";

export const WALLET_ACCOUNT_TYPES = {
  General: "GENERAL",
  Events: "EVENTS",
  Fuel_Stations: "FUEL_STATION",
};

export const WALLET_TYPES = {
  Individual: "INDIVIDUAL",
  Business: "BUSINESS",
};
