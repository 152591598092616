import "./styles.scss";
import {
  faFacebookF,
  faLinkedinIn,
  faPinterestP,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TwitterShareButton,
} from "react-share";
import { toast } from "react-toastify";
import { cn } from "utilities/cn";

const EventShareBox = ({ shareDiv, url, className }) => {
  function onCopy(link) {
    try {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(link);
        toast.info("Event link copied to clipboard!");
      }
    } catch (e) {}
  }

  return (
    shareDiv && (
      <div className={cn("share-div show tw--mt-4", className)}>
        <div className="d-flex">
          <div className="social-icons">
            <FacebookShareButton url={url} title={"Triple7events"}>
              <FontAwesomeIcon icon={faFacebookF} />
            </FacebookShareButton>
          </div>
          <div className="social-icons">
            <LinkedinShareButton url={url} title={"Triple7events"}>
              <FontAwesomeIcon icon={faLinkedinIn} />
            </LinkedinShareButton>
          </div>
          <div className="social-icons">
            <TwitterShareButton url={url} title={"Triple7events"}>
              <FontAwesomeIcon icon={faTwitter} />
            </TwitterShareButton>
          </div>
          <div className="social-icons">
            <PinterestShareButton
              media={
                "https://triple7events-cloud.s3.amazonaws.com/static/img/triple7events-logo.svg"
              }
              url={url}
              title={"Triple7events"}
            >
              <FontAwesomeIcon icon={faPinterestP} />
            </PinterestShareButton>
          </div>
          <div
            className="social-icons"
            onClick={() => {
              onCopy(url);
            }}
          >
            <FontAwesomeIcon icon={faCopy} />
          </div>
        </div>
      </div>
    )
  );
};

export default EventShareBox;
