import { HELP_NAV } from "../../constants";
import "./styles.scss";
import { CloseIcon } from "assets/icons";
import React from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const SideBar = ({ setShowSideNav, showSideNav }) => {
  const eventCategories = useSelector((state) =>
    state.events.newCategoriesData.slice(0, 5)
  );

  return (
    <div>
      <div className="overlay-content">
        <p
          className="mobile-sidemenuheading"
          onClick={() => {
            setShowSideNav(!showSideNav);
          }}
        >
          Popular Categories
        </p>
        {eventCategories.map((eventCategory, index) => (
          <Link
            key={eventCategory.value}
            className="text-gray-300 tw-w-full"
            to={`/events/${eventCategory.name.toLowerCase()}`}
            onClick={() => {
              setShowSideNav(false);
            }}
          >
            {eventCategory.name}
          </Link>
        ))}
        <Link
          className="text-gray-300"
          to="/categories"
          onClick={() => {
            setShowSideNav(false);
          }}
        >
          All Categories
        </Link>
        <p className="mobile-sidemenuheading">More links</p>
        {HELP_NAV.map((nav) => (
          <Link to={nav.link} key={nav.link}>
            {nav.name}
          </Link>
        ))}
        <p className="mobile-sidemenuheading">Manage Events</p>

        <Link to="/dashboard/create">Create Event</Link>

        <Link to="/dashboard/create">Sell Tickets</Link>
      </div>
    </div>
  );
};

export default SideBar;
