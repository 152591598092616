export const defaultAddress = [
  {
    address_components: [
      {
        long_name: "49",
        short_name: "49",
        types: ["street_number"],
      },
      {
        long_name: "Frampton Close",
        short_name: "Frampton Cl",
        types: ["route"],
      },
      {
        long_name: "Bransholme",
        short_name: "Bransholme",
        types: ["political", "sublocality", "sublocality_level_1"],
      },
      {
        long_name: "London, UK",
        short_name: "London, Uk",
        types: ["postal_town"],
      },
      {
        long_name: "Kingston upon Hull",
        short_name: "Kingston upon Hull",
        types: ["administrative_area_level_2", "political"],
      },
      {
        long_name: "England",
        short_name: "England",
        types: ["administrative_area_level_1", "political"],
      },
      {
        long_name: "United Kingdom",
        short_name: "GB",
        types: ["country", "political"],
      },
      {
        long_name: "HU7 6BB",
        short_name: "HU7 6BB",
        types: ["postal_code"],
      },
    ],
    formatted_address: "49 Frampton Cl, Bransholme, Hull HU7 6BB, UK",
    geometry: {
      bounds: {
        northeast: {
          lat: 53.7792869,
          lng: -0.3218254,
        },
        southwest: {
          lat: 53.7791958,
          lng: -0.3219751,
        },
      },
      location: {
        lat: 53.7792371,
        lng: -0.321912,
      },
      location_type: "ROOFTOP",
      viewport: {
        northeast: {
          lat: 53.7805903302915,
          lng: -0.320551269708498,
        },
        southwest: {
          lat: 53.7778923697085,
          lng: -0.323249230291502,
        },
      },
    },
    place_id: "ChIJZZ6EhWK-eEgRg4FUZMWcrcY",
    types: ["premise"],
  },
  {
    address_components: [
      {
        long_name: "14",
        short_name: "14",
        types: ["street_number"],
      },
      {
        long_name: "Frampton Close",
        short_name: "Frampton Cl",
        types: ["route"],
      },
      {
        long_name: "Bransholme",
        short_name: "Bransholme",
        types: ["political", "sublocality", "sublocality_level_1"],
      },
      {
        long_name: "Hull",
        short_name: "Hull",
        types: ["postal_town"],
      },
      {
        long_name: "Kingston upon Hull",
        short_name: "Kingston upon Hull",
        types: ["administrative_area_level_2", "political"],
      },
      {
        long_name: "England",
        short_name: "England",
        types: ["administrative_area_level_1", "political"],
      },
      {
        long_name: "United Kingdom",
        short_name: "GB",
        types: ["country", "political"],
      },
      {
        long_name: "HU7 6AJ",
        short_name: "HU7 6AJ",
        types: ["postal_code"],
      },
    ],
    formatted_address: "14 Frampton Cl, Bransholme, Hull HU7 6AJ, UK",
    geometry: {
      location: {
        lat: 53.7790702,
        lng: -0.3216477,
      },
      location_type: "ROOFTOP",
      viewport: {
        northeast: {
          lat: 53.7804191802915,
          lng: -0.3202987197084979,
        },
        southwest: {
          lat: 53.7777212197085,
          lng: -0.322996680291502,
        },
      },
    },
    place_id: "ChIJIdMokGK-eEgRFZoenViQRBM",
    plus_code: {
      compound_code: "QMHH+J8 Hull, UK",
      global_code: "9C5XQMHH+J8",
    },
    types: ["street_address"],
  },
  {
    address_components: [
      {
        long_name: "Frampton Close",
        short_name: "Frampton Cl",
        types: ["route"],
      },
      {
        long_name: "Hull",
        short_name: "Hull",
        types: ["postal_town"],
      },
      {
        long_name: "Kingston upon Hull",
        short_name: "Kingston upon Hull",
        types: ["administrative_area_level_2", "political"],
      },
      {
        long_name: "England",
        short_name: "England",
        types: ["administrative_area_level_1", "political"],
      },
      {
        long_name: "United Kingdom",
        short_name: "GB",
        types: ["country", "political"],
      },
      {
        long_name: "HU7 6BB",
        short_name: "HU7 6BB",
        types: ["postal_code"],
      },
    ],
    formatted_address: "Frampton Cl, Hull HU7 6BB, UK",
    geometry: {
      bounds: {
        northeast: {
          lat: 53.7794263,
          lng: -0.3218813,
        },
        southwest: {
          lat: 53.7792329,
          lng: -0.3221564,
        },
      },
      location: {
        lat: 53.7793296,
        lng: -0.3220189000000001,
      },
      location_type: "GEOMETRIC_CENTER",
      viewport: {
        northeast: {
          lat: 53.78067858029149,
          lng: -0.320669869708498,
        },
        southwest: {
          lat: 53.77798061970849,
          lng: -0.3233678302915021,
        },
      },
    },
    place_id: "ChIJ12fFhWK-eEgRiuI_wGRPzzI",
    types: ["route"],
  },
  {
    address_components: [
      {
        long_name: "QMHH+P6",
        short_name: "QMHH+P6",
        types: ["plus_code"],
      },
      {
        long_name: "Hull",
        short_name: "Hull",
        types: ["postal_town"],
      },
      {
        long_name: "England",
        short_name: "England",
        types: ["administrative_area_level_1", "political"],
      },
      {
        long_name: "United Kingdom",
        short_name: "GB",
        types: ["country", "political"],
      },
    ],
    formatted_address: "QMHH+P6 Hull, UK",
    geometry: {
      bounds: {
        northeast: {
          lat: 53.77937499999999,
          lng: -0.321875,
        },
        southwest: {
          lat: 53.77925,
          lng: -0.322,
        },
      },
      location: {
        lat: 53.7792971,
        lng: -0.3219111,
      },
      location_type: "GEOMETRIC_CENTER",
      viewport: {
        northeast: {
          lat: 53.7806614802915,
          lng: -0.3205885197084979,
        },
        southwest: {
          lat: 53.7779635197085,
          lng: -0.323286480291502,
        },
      },
    },
    place_id: "GhIJDy_jAcDjSkARagquAzGa1L8",
    plus_code: {
      compound_code: "QMHH+P6 Hull, UK",
      global_code: "9C5XQMHH+P6",
    },
    types: ["plus_code"],
  },
  {
    address_components: [
      {
        long_name: "HU7 6AJ",
        short_name: "HU7 6AJ",
        types: ["postal_code"],
      },
      {
        long_name: "Frampton Close",
        short_name: "Frampton Cl",
        types: ["route"],
      },
      {
        long_name: "Bransholme",
        short_name: "Bransholme",
        types: ["political", "sublocality", "sublocality_level_1"],
      },
      {
        long_name: "Hull",
        short_name: "Hull",
        types: ["postal_town"],
      },
      {
        long_name: "Kingston upon Hull",
        short_name: "Kingston upon Hull",
        types: ["administrative_area_level_2", "political"],
      },
      {
        long_name: "England",
        short_name: "England",
        types: ["administrative_area_level_1", "political"],
      },
      {
        long_name: "United Kingdom",
        short_name: "GB",
        types: ["country", "political"],
      },
    ],
    formatted_address: "Frampton Cl, Bransholme, Hull HU7 6AJ, UK",
    geometry: {
      bounds: {
        northeast: {
          lat: 53.7800267,
          lng: -0.3208319,
        },
        southwest: {
          lat: 53.7772588,
          lng: -0.3228616,
        },
      },
      location: {
        lat: 53.7790907,
        lng: -0.3216543,
      },
      location_type: "APPROXIMATE",
      viewport: {
        northeast: {
          lat: 53.7800267,
          lng: -0.3204977697084979,
        },
        southwest: {
          lat: 53.7772588,
          lng: -0.323195730291502,
        },
      },
    },
    place_id: "ChIJEcExkGK-eEgRB1wYvFokG3s",
    types: ["postal_code"],
  },
  {
    address_components: [
      {
        long_name: "HU7",
        short_name: "HU7",
        types: ["postal_code", "postal_code_prefix"],
      },
      {
        long_name: "Hull",
        short_name: "Hull",
        types: ["postal_town"],
      },
      {
        long_name: "England",
        short_name: "England",
        types: ["administrative_area_level_1", "political"],
      },
      {
        long_name: "United Kingdom",
        short_name: "GB",
        types: ["country", "political"],
      },
    ],
    formatted_address: "Hull HU7, UK",
    geometry: {
      bounds: {
        northeast: {
          lat: 53.8353823,
          lng: -0.2902191,
        },
        southwest: {
          lat: 53.7661112,
          lng: -0.3802436,
        },
      },
      location: {
        lat: 53.790744,
        lng: -0.319407,
      },
      location_type: "APPROXIMATE",
      viewport: {
        northeast: {
          lat: 53.8353823,
          lng: -0.2902191,
        },
        southwest: {
          lat: 53.7661112,
          lng: -0.3802436,
        },
      },
    },
    place_id: "ChIJDYgT-Sq5eEgRpD7oDAgutYY",
    types: ["postal_code", "postal_code_prefix"],
  },
  {
    address_components: [
      {
        long_name: "Kingston upon Hull",
        short_name: "Kingston upon Hull",
        types: ["administrative_area_level_2", "political"],
      },
      {
        long_name: "Hull",
        short_name: "Hull",
        types: ["postal_town"],
      },
      {
        long_name: "England",
        short_name: "England",
        types: ["administrative_area_level_1", "political"],
      },
      {
        long_name: "United Kingdom",
        short_name: "GB",
        types: ["country", "political"],
      },
    ],
    formatted_address: "Kingston upon Hull, Hull, UK",
    geometry: {
      bounds: {
        northeast: {
          lat: 53.8132502,
          lng: -0.2413964,
        },
        southwest: {
          lat: 53.71949129999999,
          lng: -0.4225751,
        },
      },
      location: {
        lat: 53.7452888,
        lng: -0.33657,
      },
      location_type: "APPROXIMATE",
      viewport: {
        northeast: {
          lat: 53.8132502,
          lng: -0.2413964,
        },
        southwest: {
          lat: 53.71949129999999,
          lng: -0.4225751,
        },
      },
    },
    place_id: "ChIJR48330G-eEgR8KBv7Z75DAM",
    types: ["administrative_area_level_2", "political"],
  },
  {
    address_components: [
      {
        long_name: "Kingston upon Hull",
        short_name: "Hull",
        types: ["locality", "political"],
      },
      {
        long_name: "Hull",
        short_name: "Hull",
        types: ["postal_town"],
      },
      {
        long_name: "England",
        short_name: "England",
        types: ["administrative_area_level_1", "political"],
      },
      {
        long_name: "United Kingdom",
        short_name: "GB",
        types: ["country", "political"],
      },
    ],
    formatted_address: "Hull, UK",
    geometry: {
      bounds: {
        northeast: {
          lat: 53.8132502,
          lng: -0.2413964,
        },
        southwest: {
          lat: 53.71949129999999,
          lng: -0.4225751,
        },
      },
      location: {
        lat: 53.76762360000001,
        lng: -0.3274198,
      },
      location_type: "APPROXIMATE",
      viewport: {
        northeast: {
          lat: 53.8132502,
          lng: -0.2413964,
        },
        southwest: {
          lat: 53.71949129999999,
          lng: -0.4225751,
        },
      },
    },
    place_id: "ChIJrWABfI8g1kcR_zDxgp1-RG0",
    types: ["locality", "political"],
  },
  {
    address_components: [
      {
        long_name: "Hull",
        short_name: "Hull",
        types: ["postal_town"],
      },
      {
        long_name: "England",
        short_name: "England",
        types: ["administrative_area_level_1", "political"],
      },
      {
        long_name: "United Kingdom",
        short_name: "GB",
        types: ["country", "political"],
      },
    ],
    formatted_address: "Hull, UK",
    geometry: {
      bounds: {
        northeast: {
          lat: 53.9315408,
          lng: 0.1498398,
        },
        southwest: {
          lat: 53.5714054,
          lng: -0.4977419,
        },
      },
      location: {
        lat: 53.7802173,
        lng: -0.1530132,
      },
      location_type: "APPROXIMATE",
      viewport: {
        northeast: {
          lat: 53.9315408,
          lng: 0.1498398,
        },
        southwest: {
          lat: 53.5714054,
          lng: -0.4977419,
        },
      },
    },
    place_id: "ChIJQ5yO65aieEgRIJSZTgejgpE",
    types: ["postal_town"],
  },
  {
    address_components: [
      {
        long_name: "England",
        short_name: "England",
        types: ["administrative_area_level_1", "political"],
      },
      {
        long_name: "United Kingdom",
        short_name: "GB",
        types: ["country", "political"],
      },
    ],
    formatted_address: "England, UK",
    geometry: {
      bounds: {
        northeast: {
          lat: 55.81165979999999,
          lng: 1.7629159,
        },
        southwest: {
          lat: 49.8647411,
          lng: -6.4185458,
        },
      },
      location: {
        lat: 52.3555177,
        lng: -1.1743197,
      },
      location_type: "APPROXIMATE",
      viewport: {
        northeast: {
          lat: 55.81165979999999,
          lng: 1.7629159,
        },
        southwest: {
          lat: 49.8647411,
          lng: -6.4185458,
        },
      },
    },
    place_id: "ChIJ39UebIqp0EcRqI4tMyWV4fQ",
    types: ["administrative_area_level_1", "political"],
  },
  {
    address_components: [
      {
        long_name: "United Kingdom",
        short_name: "GB",
        types: ["country", "political"],
      },
    ],
    formatted_address: "United Kingdom",
    geometry: {
      bounds: {
        northeast: {
          lat: 60.91569999999999,
          lng: 33.9165549,
        },
        southwest: {
          lat: 34.5614,
          lng: -8.8988999,
        },
      },
      location: {
        lat: 55.378051,
        lng: -3.435973,
      },
      location_type: "APPROXIMATE",
      viewport: {
        northeast: {
          lat: 60.91569999999999,
          lng: 33.9165549,
        },
        southwest: {
          lat: 34.5614,
          lng: -8.8988999,
        },
      },
    },
    place_id: "ChIJqZHHQhE7WgIReiWIMkOg-MQ",
    types: ["country", "political"],
  },
];
