const { ADDRESS_FIELDS } = require("./constant");

export const capitalizeAddressValues = (values) => {
  const capitalizedValues = {};

  Object.keys(values).forEach((key) => {
    if (ADDRESS_FIELDS.includes(key)) {
      capitalizedValues[key] = values[key].replace(/\w\S*/g, (w) =>
        w.replace(/^\w/, (c) => c.toUpperCase())
      );
    }
  });

  return capitalizedValues;
};
