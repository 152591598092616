import "./styles.scss";
import { Icon } from "@iconify/react";
import RestCard from "assets/images/RestaurantCardcrop.png";
import React from "react";

const RestaurantCard = ({}) => {
  const goToSingleRest = () => {
    const url = "/single-restaurants";
    history.push(url);
  };
  return (
    <div className="restCard">
      <div
        className="restCardInd"
        style={{
          backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${RestCard})`,
          backgroundSize: "cover",
        }}
      >
        <div className="star">
          <Icon icon="entypo:star-outlined" color="#EA9532" /> 4.5
        </div>
        <h3 className="name mb-4">Alacarte Special</h3>
        <div className="d-flex align-items-center mb-5">
          <Icon icon="akar-icons:location" size={20} />
          <span className="address ml-2">Ikeja, Lagos</span>
        </div>
        <button onClick={goToSingleRest} className="mb-3 btn-discoverRest">
          Discover
        </button>
      </div>
    </div>
  );
};

export default RestaurantCard;
