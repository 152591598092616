import PropTypes from "prop-types";
import React from "react";

const sizeMap = {
  sm: "tw-w-12 tw-h-12",
  md: "tw-w-16 tw-h-16",
  lg: "tw-w-20 tw-h-20",
  xl: "tw-w-24 tw-h-24",
  "2xl": "tw-w-32 tw-h-32",
};

const Avatar = ({ src, className, alt, size = "sm" }) => {
  const sizeClass = sizeMap[size] || sizeMap["sm"];

  return (
    <div
      className={`tw-bg-white tw-rounded-full tw-flex tw-justify-center tw-items-center ${className} ${sizeClass}`}
      style={{
        backgroundImage: `url(${src})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        borderRadius: "50%",
      }}
    ></div>
  );
};

Avatar.propTypes = {
  size: PropTypes.oneOf(["sm", "md", "lg", "xl", "2xl"]),
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default React.memo(Avatar);
