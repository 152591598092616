import "./styles.scss";
import React from "react";

export default function Loader({ title }) {
  return (
    <div className="loader-component">
      <div className="loading">
        <h2 className="loader-title">{title}</h2>
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
      </div>
    </div>
  );
}

Loader.defaultProps = {
  title: "",
};
