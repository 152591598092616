import { Component } from "react";
import React from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="tw-h-screen tw-w-screen tw-flex tw-justify-center tw-items-center">
          <div>It seems there is an issue, Please reload your page.</div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
