import FormContainer from "../../../FormContainer";
import React from "react";

const StepOutForm = ({ values, setFieldValue }) => {
  return (
    <FormContainer>
      <p className="tw-font-medium">Step Out</p>

      <div className="tw-mt-6 tw-flex tw-gap-6">
        <input
          type="checkbox"
          id="enableStepOut"
          name="enableStepOut"
          value={values.enableStepOut}
          checked={values.enableStepOut}
          onChange={() => {
            setFieldValue("enableStepOut", !values.enableStepOut);
          }}
          className="tw-w-7"
        />
        <label htmlFor="enableStepOut" className="tw-mt-2">
          <span>Enable Step Out</span>
          <br />
          <span className="tw-text-base tw-text-gray-700">
            Step Out is an option where organiser can give option for attendees
            to step out of the event/venue for a break.
          </span>
        </label>
      </div>
    </FormContainer>
  );
};

export default StepOutForm;
