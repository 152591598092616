import { findCountryByISO3, findIso2ByISO3 } from "../../../../../lib/country";
import "./styles.scss";
import { Icon } from "@iconify/react";
import { unwrapResult } from "@reduxjs/toolkit";
import Logo from "assets/images/logo.svg";
import CustomNavDropdown from "components/shared/etc/CustomNavDropdown";
import { accountNav, moreNav } from "components/shared/navbar/navItems";
import PlaceSuggestionWrapper from "components/shared/placeSuggestionWrapper";
import produce from "immer";
import React, { Fragment, useEffect, useRef, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import NavDropdown from "react-bootstrap/NavDropdown";
import ReactCountryFlag from "react-country-flag";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { logoutUser } from "state/slices/account";
import { fetchRelatedEventsByCountryRequest } from "state/slices/events";
import { fetchEventNameSuggestionsRequest } from "state/slices/events";
import { setLocationParameters } from "state/slices/location";
import { logoutUserData } from "state/slices/users";
import { getOneLinerErrorMessage } from "utilities";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div ref={ref} onClick={(e) => onClick(e)}>
    {children}
  </div>
));

CustomToggle.displayName = "CustomToggle";

const SearchAndNavbar = ({ defaultNameSearchKeyword }) => {
  const dispatch = useDispatch();
  const location = useSelector((state) => state.location);
  const events = useSelector((state) => state.events);
  // const cities = events.relatedEventsData.slice(0, 7);
  const [searchQuery, setSearchQuery] = useState(location.region || "");
  const history = useHistory();
  const user = useSelector((state) => state.users.data.detail);
  const token = localStorage.getItem("token");
  const eventCategories = useSelector((state) =>
    state.events.categoriesData.slice(0, 5)
  );

  useEffect(() => {
    // window.scrollTo(0, 0);
    setSearchQuery(location.region);
  }, [location.region]);

  useEffect(() => {
    dispatch(fetchRelatedEventsByCountryRequest(location.countryISO3));
  }, [location.countryISO3]);

  function onCityClick(city) {
    return () => {
      const country = findCountryByISO3(city.countryIso_3);
      const payload = {
        latitude: city.lat,
        longitude: city.long,
        region: city.region,
        countryISO3: country.iso3,
        countryLanguage: country.language,
        countryCurrency: country.currency,
        country: country.name,
      };

      dispatch(setLocationParameters(payload));
    };
  }

  const updateLocation = (payload) => {
    dispatch(setLocationParameters(payload));
  };
  useEffect(() => {
    eventCategories.sort((a, b) => {
      if (a.type.toLowerCase() < b.type.toLowerCase()) {
        return -1;
      }
      if (a.type.toLowerCase() > b.type.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  }, [eventCategories]);
  const [showSideNav, setShowSideNav] = useState(false);
  const logout = () => {
    dispatch(logoutUser());
    dispatch(logoutUserData());
  };
  const nameSearchInputRef = useRef(null);

  const [state, setState] = useState({
    nameSearchKeyword: defaultNameSearchKeyword || "",
    nameSearchResults: [],
    nameSearchKeywordChanged: false,
    isFetchingNameSearchSuggestions: false,
    isNameSearchable: false,
    isNameSearchDropdownOpen: false,
  });

  const setNameSearchKeyword = (event) => {
    setState(
      produce((draft) => {
        // draft.placeSearchKeyword = "";
        draft.nameSearchKeyword = event.target.value;
        draft.nameSearchKeywordChanged = true;
      })
    );
  };

  const setShowNameSearchDropdownOpen = (visibility) => {
    setState(
      produce((draft) => {
        draft.isNameSearchDropdownOpen = visibility;
      })
    );
  };

  const handleNameSearchInputKeyPress = (event) => {
    if (state.nameSearchKeyword && event.code === "Enter") {
      setShowNameSearchDropdownOpen(false);
      history.push(`/search-by-name?keyword=${event.target.value}`);
    }
  };

  const handleNameSearchButtonClick = () => {
    history.push(`/search-by-name?keyword=${state.nameSearchKeyword}`);
  };

  const fetchEventNameSuggestions = async (eventName) => {
    setState(produce((draft) => void (draft.isFetchingNameSuggestions = true)));

    const resultAction = await dispatch(
      fetchEventNameSuggestionsRequest(eventName)
    );

    if (fetchEventNameSuggestionsRequest.fulfilled.match(resultAction)) {
      const response = unwrapResult(resultAction);

      setState(
        produce((draft) => {
          draft.isFetchingNameSuggestions = false;
          draft.nameSearchResults = response;
        })
      );
    } else {
      setState(
        produce((draft) => void (draft.isFetchingNameSuggestions = false))
      );

      toast.error(
        getOneLinerErrorMessage(resultAction.payload) ||
          resultAction.error?.message ||
          "An error occurred, please try again."
      );
    }
  };

  useEffect(() => {
    if (state.nameSearchKeyword.length > 2 && state.nameSearchKeywordChanged) {
      fetchEventNameSuggestions(state.nameSearchKeyword).then(undefined);
      setState(
        produce((draft) => {
          draft.isNameSearchable = true;
          draft.isNameSearchDropdownOpen = true;
        })
      );
    } else {
      setState(
        produce((draft) => {
          draft.isNameSearchable = false;
          draft.isNameSearchDropdownOpen = false;
        })
      );
    }
  }, [state.nameSearchKeyword]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!nameSearchInputRef?.current?.contains(event.target)) {
        if (!state.isNameSearchDropdownOpen) return;
        setShowNameSearchDropdownOpen(false);
      }
    };

    window.addEventListener("click", handleOutsideClick);
    return () => window.removeEventListener("click", handleOutsideClick);
  }, [state.isNameSearchDropdownOpen, nameSearchInputRef]);

  useEffect(() => {
    const handleEscape = (event) => {
      if (state.isNameSearchDropdownOpen && event.key !== "Escape") {
        return false;
      }

      setShowNameSearchDropdownOpen(false);
    };

    document.addEventListener("keyup", handleEscape);
    return () => document.removeEventListener("keyup", handleEscape);
  }, [state.isNameSearchDropdownOpen]);

  return (
    <div className="flex-row py-3 mx-0 row g-0 d-flex justify-content-start align-items-center search-navbar-wrapper">
      <div className="col-auto pr-0 d-block d-md-none">
        <a
          className="px-0 border-0 navbar-toggler text-dark"
          onClick={() => setShowSideNav(!showSideNav)}
        >
          <Icon icon="charm:menu-hamburger" fontSize={"20px"} />{" "}
        </a>
      </div>
      <Link
        className="flex-row order-1 col-auto ml-0 d-flex align-items-center"
        to="/"
      >
        <img src={Logo} alt="Triple7events" width="120" />
      </Link>
      <div className="flex-row order-3 mt-3 ml-0 col-12 col-md-5 col-lg-6 position-relative order-md-2 mt-md-0 ml-md-3 d-flex align-items-center search-input">
        <Dropdown className="shadow-sm tw-mx-2 tw-rounded-md tw-py-3 tw-px-3 tw-w-64">
          <Dropdown.Toggle
            as={CustomToggle}
            variant="success"
            id="dropdown-basic"
          >
            <div className="label country tw-rounded-none tw-w-full tw-flex tw-gap-4">
              <ReactCountryFlag
                countryCode={findIso2ByISO3(location.countryISO3) || ""}
                svg
                style={{
                  fontSize: "29px",
                  borderRadius: "100%",
                }}
              />
              <span className="tw-truncate tw-text-sm">{searchQuery}</span>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item as={"div"} className="tw-w-[400px]">
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <PlaceSuggestionWrapper
                  value={searchQuery}
                  onChange={setSearchQuery}
                  onSelect={updateLocation}
                  inputClassName="form-control form-control-sm ml-3 px-0 text-base font-weight-normal location-input"
                  showIcon
                />
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <div className="input-group input-group-lg tw-flex tw-h-14 tw-p-0 search-input-container">
          <input
            ref={nameSearchInputRef}
            className="border-0 shadow-sm form-control"
            style={{ height: "100%" }}
            name="nameSearch"
            type="search"
            placeholder="Enter event name, category, city"
            value={state.nameSearchKeyword}
            onChange={setNameSearchKeyword}
            onKeyPress={handleNameSearchInputKeyPress}
            autoComplete="off"
          />
          <div
            className="p-0 border-0 input-group-text"
            style={{ background: "none" }}
          >
            <button
              type="button"
              disabled={!state.isNameSearchable}
              className="shadow-sm pointer btn btn-green search-btn"
              onClick={handleNameSearchButtonClick}
            >
              SEARCH
            </button>
          </div>
        </div>
        {state.isNameSearchDropdownOpen && (
          <ul className="bg-white rounded-lg search-dropdown position-absolute list-unstyled">
            {state.nameSearchResults.map((event) => (
              <li key={event.id}>
                <Link
                  to={`/event/${event.id}`}
                  className="d-block text-dark w-100"
                >
                  {event.name}
                </Link>
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="flex-row order-2 col-auto ml-auto mr-0 order-md-3 d-flex justify-content-end align-items-center">
        <div className="flex-row ml-auto navbar-nav font-weight-normal">
          <CustomNavDropdown
            id="more-dropdown"
            title={
              <div className=" tw-flex tw-item-center">
                <span className="ml-2 font-weight-bold">Help</span>
                <Icon icon="fa:angle-down" className="ml-2 tw-mt-1" />
              </div>
            }
            className="tw-text-[#010101] font-weight-bold text-black-link"
          >
            {moreNav.map((nav) => (
              <NavDropdown.Item
                className="tw-text-[#010101]"
                as={Link}
                to={nav.link}
                key={nav.link}
              >
                {nav.name}
              </NavDropdown.Item>
            ))}
          </CustomNavDropdown>

          <CustomNavDropdown
            id="sell-tickets-dropdown"
            title={
              <>
                <span className="ml-2 text-accent font-weight-bold">
                  Create Event
                </span>
                <Icon icon="fa:angle-down" className="ml-2" />
              </>
            }
            className="tw-text-[#010101] font-weight-bold text-black-link"
          >
            <NavDropdown.Item
              as={Link}
              className="tw-text-[#010101]"
              to="/dashboard/create"
            >
              Sell Tickets
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/dashboard/create">
              Create Event
            </NavDropdown.Item>
          </CustomNavDropdown>

          {token ? (
            <CustomNavDropdown
              id="user-account-dropdown"
              title={
                <Fragment>
                  <Icon
                    icon="carbon:user-avatar-filled"
                    className="text-gray-300 font-weight-bold text-black-link"
                    fontSize={"25px"}
                  />
                  <span className="ml-2 d-none d-md-block">{user?.name}</span>
                </Fragment>
              }
              className="text-gray-300 text-black-link"
            >
              {accountNav.map((nav) => (
                <NavDropdown.Item as={Link} to={nav.link} key={nav.link}>
                  {nav.name}
                </NavDropdown.Item>
              ))}
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
            </CustomNavDropdown>
          ) : (
            <CustomNavDropdown
              id="sign-in-dropdown"
              title={
                <>
                  <span className="ml-2 font-weight-bold">Sign In</span>
                  <Icon icon="fa:angle-down" className="ml-2" />
                </>
              }
              className="text-gray-300 text-black-link"
            >
              <NavDropdown.Item as={Link} to="/auth/sign-in">
                Sign In
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/auth/sign-in">
                Sign Up
              </NavDropdown.Item>
            </CustomNavDropdown>
          )}
        </div>
      </div>

      {/* Side Navigation */}
      <div
        className={`overlay ${showSideNav ? "w-100 start-0" : ""}`}
        id="navbar-responsive"
      >
        <a
          href="#"
          className="closebtn"
          onClick={() => {
            setShowSideNav(!showSideNav);
          }}
        >
          ×
        </a>
        <div className="overlay-content">
          <p className="mobile-sidemenuheading">Popular Categories</p>
          {eventCategories.map((eventCategory, index) => (
            <Link
              key={eventCategory.value}
              className="text-gray-300"
              to={`/events/${eventCategory.type.toLowerCase()}`}
              onClick={() => {
                setShowSideNav(false);
              }}
            >
              {eventCategory.type}
            </Link>
          ))}
          <Link
            className="text-gray-300"
            to="/categories"
            onClick={() => {
              setShowSideNav(false);
            }}
          >
            All Categories
          </Link>
          <p className="mobile-sidemenuheading">More links</p>
          {moreNav.map((nav) => (
            <Link to={nav.link} key={nav.link}>
              {nav.name}
            </Link>
          ))}
          {/* <a href="/how-it-works/#ourfee"> Our Fee</a>
          <a href="/how-it-works/#ourfee"> Ticket Reseller Fee</a>
          <a href="/how-it-works/#refund"> Refund and Cancellation</a> */}
        </div>
      </div>
    </div>
  );
};

export default SearchAndNavbar;
