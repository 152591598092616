import React from "react";
import ReactCountryFlag from "react-country-flag";

export const countries = [
  {
    iso2: "AF",
    iso3: "AFG",
    latitude: 33,
    longitude: 65,
    name: "Afghanistan",
    currency: {
      code: "AFN",
      name: "Afghan afghani",
      symbol: "؋",
    },
    language: {
      code: "ps",
      name: "Pashto",
    },
    dialCode: "+93",
  },
  {
    iso2: "AX",
    iso3: "ALA",
    latitude: 60.116667,
    longitude: 19.9,
    name: "Åland Islands",
    currency: {
      code: "EUR",
      name: "Euro",
      symbol: "€",
    },
    language: {
      code: "sv",
      name: "Swedish",
    },
    dialCode: "+358",
  },
  {
    iso2: "AL",
    iso3: "ALB",
    latitude: 41,
    longitude: 20,
    name: "Albania",
    currency: {
      code: "ALL",
      name: "Albanian lek",
      symbol: "L",
    },
    language: {
      code: "sq",
      name: "Albanian",
    },
    dialCode: "+355",
  },
  {
    iso2: "DZ",
    iso3: "DZA",
    latitude: 28,
    longitude: 3,
    name: "Algeria",
    currency: {
      code: "DZD",
      name: "Algerian dinar",
      symbol: "د.ج",
    },
    language: {
      code: "ar",
      name: "Arabic",
    },
    dialCode: "+213",
  },
  {
    iso2: "AS",
    iso3: "ASM",
    latitude: -14.33333333,
    longitude: -170,
    name: "American Samoa",
    currency: {
      code: "USD",
      name: "United State Dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+1684",
  },
  {
    iso2: "AD",
    iso3: "AND",
    latitude: 42.5,
    longitude: 1.5,
    name: "Andorra",
    currency: {
      code: "EUR",
      name: "Euro",
      symbol: "€",
    },
    language: {
      code: "ca",
      name: "Catalan",
    },
    dialCode: "+376",
  },
  {
    iso2: "AO",
    iso3: "AGO",
    latitude: -12.5,
    longitude: 18.5,
    name: "Angola",
    currency: {
      code: "AOA",
      name: "Angolan kwanza",
      symbol: "Kz",
    },
    language: {
      code: "pt",
      name: "Portuguese",
    },
    dialCode: "+244",
  },
  {
    iso2: "AI",
    iso3: "AIA",
    latitude: 18.25,
    longitude: -63.16666666,
    name: "Anguilla",
    currency: {
      code: "XCD",
      name: "East Caribbean dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+1264",
  },
  {
    iso2: "AG",
    iso3: "ATG",
    latitude: 17.05,
    longitude: -61.8,
    name: "Antigua and Barbuda",
    currency: {
      code: "XCD",
      name: "East Caribbean dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+1268",
  },
  {
    iso2: "AR",
    iso3: "ARG",
    latitude: -34,
    longitude: -64,
    name: "Argentina",
    currency: {
      code: "ARS",
      name: "Argentine peso",
      symbol: "$",
    },
    language: {
      code: "es",
      name: "Spanish",
    },
    dialCode: "+54",
  },
  {
    iso2: "AM",
    iso3: "ARM",
    latitude: 40,
    longitude: 45,
    name: "Armenia",
    currency: {
      code: "AMD",
      name: "Armenian dram",
      symbol: null,
    },
    language: {
      code: "hy",
      name: "Armenian",
    },
    dialCode: "+374",
  },
  {
    iso2: "AW",
    iso3: "ABW",
    latitude: 12.5,
    longitude: -69.96666666,
    name: "Aruba",
    currency: {
      code: "AWG",
      name: "Aruban florin",
      symbol: "ƒ",
    },
    language: {
      code: "nl",
      name: "Dutch",
    },
    dialCode: "+297",
  },
  {
    iso2: "AU",
    iso3: "AUS",
    latitude: -27,
    longitude: 133,
    name: "Australia",
    currency: {
      code: "AUD",
      name: "Australian dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+61",
  },
  {
    iso2: "AT",
    iso3: "AUT",
    latitude: 47.33333333,
    longitude: 13.33333333,
    name: "Austria",
    currency: {
      code: "EUR",
      name: "Euro",
      symbol: "€",
    },
    language: {
      code: "de",
      name: "German",
    },
    dialCode: "+43",
  },
  {
    iso2: "AZ",
    iso3: "AZE",
    latitude: 40.5,
    longitude: 47.5,
    name: "Azerbaijan",
    currency: {
      code: "AZN",
      name: "Azerbaijani manat",
      symbol: null,
    },
    language: {
      code: "az",
      name: "Azerbaijani",
    },
    dialCode: "+994",
  },
  {
    iso2: "BS",
    iso3: "BHS",
    latitude: 24.25,
    longitude: -76,
    name: "Bahamas",
    currency: {
      code: "BSD",
      name: "Bahamian dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+1242",
  },
  {
    iso2: "BH",
    iso3: "BHR",
    latitude: 26,
    longitude: 50.55,
    name: "Bahrain",
    currency: {
      code: "BHD",
      name: "Bahraini dinar",
      symbol: ".د.ب",
    },
    language: {
      code: "ar",
      name: "Arabic",
    },
    dialCode: "+973",
  },
  {
    iso2: "BD",
    iso3: "BGD",
    latitude: 24,
    longitude: 90,
    name: "Bangladesh",
    currency: {
      code: "BDT",
      name: "Bangladeshi taka",
      symbol: "৳",
    },
    language: {
      code: "bn",
      name: "Bengali",
    },
    dialCode: "+880",
  },
  {
    iso2: "BB",
    iso3: "BRB",
    latitude: 13.16666666,
    longitude: -59.53333333,
    name: "Barbados",
    currency: {
      code: "BBD",
      name: "Barbadian dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+1246",
  },
  {
    iso2: "BY",
    iso3: "BLR",
    latitude: 53,
    longitude: 28,
    name: "Belarus",
    currency: {
      code: "BYN",
      name: "New Belarusian ruble",
      symbol: "Br",
    },
    language: {
      code: "be",
      name: "Belarusian",
    },
    dialCode: "+375",
  },
  {
    iso2: "BE",
    iso3: "BEL",
    latitude: 50.83333333,
    longitude: 4,
    name: "Belgium",
    currency: {
      code: "EUR",
      name: "Euro",
      symbol: "€",
    },
    language: {
      code: "nl",
      name: "Dutch",
    },
    dialCode: "+32",
  },
  {
    iso2: "BZ",
    iso3: "BLZ",
    latitude: 17.25,
    longitude: -88.75,
    name: "Belize",
    currency: {
      code: "BZD",
      name: "Belize dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+501",
  },
  {
    iso2: "BJ",
    iso3: "BEN",
    latitude: 9.5,
    longitude: 2.25,
    name: "Benin",
    currency: {
      code: "XOF",
      name: "West African CFA franc",
      symbol: "Fr",
    },
    language: {
      code: "fr",
      name: "French",
    },
    dialCode: "+229",
  },
  {
    iso2: "BM",
    iso3: "BMU",
    latitude: 32.33333333,
    longitude: -64.75,
    name: "Bermuda",
    currency: {
      code: "BMD",
      name: "Bermudian dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+1441",
  },
  {
    iso2: "BT",
    iso3: "BTN",
    latitude: 27.5,
    longitude: 90.5,
    name: "Bhutan",
    currency: {
      code: "BTN",
      name: "Bhutanese ngultrum",
      symbol: "Nu.",
    },
    language: {
      code: "dz",
      name: "Dzongkha",
    },
    dialCode: "+975",
  },
  {
    iso2: "BO",
    iso3: "BOL",
    latitude: -17,
    longitude: -65,
    name: "Bolivia (Plurinational State of)",
    currency: {
      code: "BOB",
      name: "Bolivian boliviano",
      symbol: "Bs.",
    },
    language: {
      code: "es",
      name: "Spanish",
    },
    dialCode: "+591",
  },
  {
    iso2: "BA",
    iso3: "BIH",
    latitude: 44,
    longitude: 18,
    name: "Bosnia and Herzegovina",
    currency: {
      code: "BAM",
      name: "Bosnia and Herzegovina convertible mark",
      symbol: null,
    },
    language: {
      code: "bs",
      name: "Bosnian",
    },
    dialCode: "+387",
  },
  {
    iso2: "BW",
    iso3: "BWA",
    latitude: -22,
    longitude: 24,
    name: "Botswana",
    currency: {
      code: "BWP",
      name: "Botswana pula",
      symbol: "P",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+267",
  },
  {
    iso2: "BR",
    iso3: "BRA",
    latitude: -10,
    longitude: -55,
    name: "Brazil",
    currency: {
      code: "BRL",
      name: "Brazilian real",
      symbol: "R$",
    },
    language: {
      code: "pt",
      name: "Portuguese",
    },
    dialCode: "+55",
  },
  {
    iso2: "IO",
    iso3: "IOT",
    latitude: -6,
    longitude: 71.5,
    name: "British Indian Ocean Territory",
    currency: {
      code: "USD",
      name: "United States dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+246",
  },
  {
    iso2: "BN",
    iso3: "BRN",
    latitude: 4.5,
    longitude: 114.66666666,
    name: "Brunei Darussalam",
    currency: {
      code: "BND",
      name: "Brunei dollar",
      symbol: "$",
    },
    language: {
      code: "ms",
      name: "Malay",
    },
    dialCode: "+673",
  },
  {
    iso2: "BG",
    iso3: "BGR",
    latitude: 43,
    longitude: 25,
    name: "Bulgaria",
    currency: {
      code: "BGN",
      name: "Bulgarian lev",
      symbol: "лв",
    },
    language: {
      code: "bg",
      name: "Bulgarian",
    },
    dialCode: "+359",
  },
  {
    iso2: "BF",
    iso3: "BFA",
    latitude: 13,
    longitude: -2,
    name: "Burkina Faso",
    currency: {
      code: "XOF",
      name: "West African CFA franc",
      symbol: "Fr",
    },
    language: {
      code: "fr",
      name: "French",
    },
    dialCode: "+226",
  },
  {
    iso2: "BI",
    iso3: "BDI",
    latitude: -3.5,
    longitude: 30,
    name: "Burundi",
    currency: {
      code: "BIF",
      name: "Burundian franc",
      symbol: "Fr",
    },
    language: {
      code: "fr",
      name: "French",
    },
    dialCode: "+257",
  },
  {
    iso2: "KH",
    iso3: "KHM",
    latitude: 13,
    longitude: 105,
    name: "Cambodia",
    currency: {
      code: "KHR",
      name: "Cambodian riel",
      symbol: "៛",
    },
    language: {
      code: "km",
      name: "Khmer",
    },
    dialCode: "+855",
  },
  {
    iso2: "CM",
    iso3: "CMR",
    latitude: 6,
    longitude: 12,
    name: "Cameroon",
    currency: {
      code: "XAF",
      name: "Central African CFA franc",
      symbol: "Fr",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+237",
  },
  {
    iso2: "CA",
    iso3: "CAN",
    latitude: 60,
    longitude: -95,
    name: "Canada",
    currency: {
      code: "CAD",
      name: "Canadian dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+1",
  },
  {
    iso2: "CV",
    iso3: "CPV",
    latitude: 16,
    longitude: -24,
    name: "Cabo Verde",
    currency: {
      code: "CVE",
      name: "Cape Verdean escudo",
      symbol: "Esc",
    },
    language: {
      code: "pt",
      iso639_2: "por",
      name: "Portuguese",
      nativeName: "Português",
    },
    dialCode: "+238",
  },
  {
    iso2: "KY",
    iso3: "CYM",
    latitude: 19.5,
    longitude: -80.5,
    name: "Cayman Islands",
    currency: {
      code: "KYD",
      name: "Cayman Islands dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+1345",
  },
  {
    iso2: "CF",
    iso3: "CAF",
    latitude: 7,
    longitude: 21,
    name: "Central African Republic",
    currency: {
      code: "XAF",
      name: "Central African CFA franc",
      symbol: "Fr",
    },
    language: {
      code: "fr",
      name: "French",
    },
    dialCode: "+236",
  },
  {
    iso2: "TD",
    iso3: "TCD",
    latitude: 15,
    longitude: 19,
    name: "Chad",
    currency: {
      code: "XAF",
      name: "Central African CFA franc",
      symbol: "Fr",
    },
    language: {
      code: "fr",
      name: "French",
    },
    dialCode: "+235",
  },
  {
    iso2: "CL",
    iso3: "CHL",
    latitude: -30,
    longitude: -71,
    name: "Chile",
    currency: {
      code: "CLP",
      name: "Chilean peso",
      symbol: "$",
    },
    language: {
      code: "es",
      iso639_2: "spa",
      name: "Spanish",
      nativeName: "Español",
    },
    dialCode: "+56",
  },
  {
    iso2: "CN",
    iso3: "CHN",
    latitude: 35,
    longitude: 105,
    name: "China",
    currency: {
      code: "CNY",
      name: "Chinese yuan",
      symbol: "¥",
    },
    language: {
      code: "zh",
      name: "Chinese",
    },
    dialCode: "+86",
  },
  {
    iso2: "CX",
    iso3: "CXR",
    latitude: -10.5,
    longitude: 105.66666666,
    name: "Christmas Island",
    currency: {
      code: "AUD",
      name: "Australian dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+61",
  },
  {
    iso2: "CC",
    iso3: "CCK",
    latitude: -12.5,
    longitude: 96.83333333,
    name: "Cocos (Keeling) Islands",
    currency: {
      code: "AUD",
      name: "Australian dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+61",
  },
  {
    iso2: "CO",
    iso3: "COL",
    latitude: 4,
    longitude: -72,
    name: "Colombia",
    currency: {
      code: "COP",
      name: "Colombian peso",
      symbol: "$",
    },
    language: {
      code: "es",
      name: "Spanish",
    },
    dialCode: "+57",
  },
  {
    iso2: "KM",
    iso3: "COM",
    latitude: -12.16666666,
    longitude: 44.25,
    name: "Comoros",
    currency: {
      code: "KMF",
      name: "Comorian franc",
      symbol: "Fr",
    },
    language: {
      code: "fr",
      name: "French",
    },
    dialCode: "+269",
  },
  {
    iso2: "CG",
    iso3: "COG",
    latitude: -1,
    longitude: 15,
    name: "Congo",
    currency: {
      code: "XAF",
      name: "Central African CFA franc",
      symbol: "Fr",
    },
    language: {
      code: "fr",
      name: "French",
    },
    dialCode: "+242",
  },
  {
    iso2: "CK",
    iso3: "COK",
    latitude: -21.23333333,
    longitude: -159.76666666,
    name: "Cook Islands",
    currency: {
      code: "NZD",
      name: "New Zealand dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+682",
  },
  {
    iso2: "CR",
    iso3: "CRI",
    latitude: 10,
    longitude: -84,
    name: "Costa Rica",
    currency: {
      code: "CRC",
      name: "Costa Rican colón",
      symbol: "₡",
    },
    language: {
      code: "es",
      name: "Spanish",
    },
    dialCode: "+506",
  },
  {
    iso2: "HR",
    iso3: "HRV",
    latitude: 45.16666666,
    longitude: 15.5,
    name: "Croatia",
    currency: {
      code: "HRK",
      name: "Croatian kuna",
      symbol: "kn",
    },
    language: {
      code: "hr",
      name: "Croatian",
    },
    dialCode: "+385",
  },
  {
    iso2: "CU",
    iso3: "CUB",
    latitude: 21.5,
    longitude: -80,
    name: "Cuba",
    currency: {
      code: "CUC",
      name: "Cuban convertible peso",
      symbol: "$",
    },
    language: {
      code: "es",
      name: "Spanish",
    },
    dialCode: "+53",
  },
  {
    iso2: "CY",
    iso3: "CYP",
    latitude: 35,
    longitude: 33,
    name: "Cyprus",
    currency: {
      code: "EUR",
      name: "Euro",
      symbol: "€",
    },
    language: {
      code: "tr",
      name: "Turkish",
    },
    dialCode: "+357",
  },
  {
    iso2: "CZ",
    iso3: "CZE",
    latitude: 49.75,
    longitude: 15.5,
    name: "Czech Republic",
    currency: {
      code: "CZK",
      name: "Czech koruna",
      symbol: "Kč",
    },
    language: {
      code: "cs",
      name: "Czech",
    },
    dialCode: "+420",
  },
  {
    iso2: "CD",
    iso3: "COD",
    latitude: 0,
    longitude: 25,
    name: "Congo (Democratic Republic of the)",
    currency: {
      code: "CDF",
      name: "Congolese franc",
      symbol: "Fr",
    },
    language: {
      code: "fr",
      name: "French",
    },
    dialCode: "+243",
  },
  {
    iso2: "DK",
    iso3: "DNK",
    latitude: 56,
    longitude: 10,
    name: "Denmark",
    currency: {
      code: "DKK",
      name: "Danish krone",
      symbol: "kr",
    },
    language: {
      code: "da",
      name: "Danish",
    },
    dialCode: "+45",
  },
  {
    iso2: "DJ",
    iso3: "DJI",
    latitude: 11.5,
    longitude: 43,
    name: "Djibouti",
    currency: {
      code: "DJF",
      name: "Djiboutian franc",
      symbol: "Fr",
    },
    language: {
      code: "fr",
      name: "French",
    },
    dialCode: "+253",
  },
  {
    iso2: "DM",
    iso3: "DMA",
    latitude: 15.41666666,
    longitude: -61.33333333,
    name: "Dominica",
    currency: {
      code: "XCD",
      name: "East Caribbean dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+1767",
  },
  {
    iso2: "DO",
    iso3: "DOM",
    latitude: 19,
    longitude: -70.66666666,
    name: "Dominican Republic",
    currency: {
      code: "DOP",
      name: "Dominican peso",
      symbol: "$",
    },
    language: {
      code: "es",
      name: "Spanish",
    },
    dialCode: "+1849",
  },
  {
    iso2: "EC",
    iso3: "ECU",
    latitude: -2,
    longitude: -77.5,
    name: "Ecuador",
    currency: {
      code: "USD",
      name: "United States dollar",
      symbol: "$",
    },
    language: {
      code: "es",
      name: "Spanish",
    },
    dialCode: "+593",
  },
  {
    iso2: "EG",
    iso3: "EGY",
    latitude: 27,
    longitude: 30,
    name: "Egypt",
    currency: {
      code: "EGP",
      name: "Egyptian pound",
      symbol: "£",
    },
    language: {
      code: "ar",
      name: "Arabic",
    },
    dialCode: "+20",
  },
  {
    iso2: "SV",
    iso3: "SLV",
    latitude: 13.83333333,
    longitude: -88.91666666,
    name: "El Salvador",
    currency: {
      code: "USD",
      name: "United States dollar",
      symbol: "$",
    },
    language: {
      code: "es",
      name: "Spanish",
    },
    dialCode: "+503",
  },
  {
    iso2: "GQ",
    iso3: "GNQ",
    latitude: 2,
    longitude: 10,
    name: "Equatorial Guinea",
    currency: {
      code: "XAF",
      name: "Central African CFA franc",
      symbol: "Fr",
    },
    language: {
      code: "es",
      iso639_2: "spa",
      name: "Spanish",
      nativeName: "Español",
    },
    dialCode: "+240",
  },
  {
    iso2: "ER",
    iso3: "ERI",
    latitude: 15,
    longitude: 39,
    name: "Eritrea",
    currency: {
      code: "ERN",
      name: "Eritrean nakfa",
      symbol: "Nfk",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+291",
  },
  {
    iso2: "EE",
    iso3: "EST",
    latitude: 59,
    longitude: 26,
    name: "Estonia",
    currency: {
      code: "EUR",
      name: "Euro",
      symbol: "€",
    },
    language: {
      code: "et",
      name: "Estonian",
    },
    dialCode: "+372",
  },
  {
    iso2: "SZ",
    iso3: "SWZ",
    latitude: -26.5,
    longitude: 31.5,
    name: "Swaziland",
    currency: {
      code: "SZL",
      name: "Swazi lilangeni",
      symbol: "L",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+268",
  },
  {
    iso2: "ET",
    iso3: "ETH",
    latitude: 8,
    longitude: 38,
    name: "Ethiopia",
    currency: {
      code: "ETB",
      name: "Ethiopian birr",
      symbol: "Br",
    },
    language: {
      code: "am",
      name: "Amharic",
    },
    dialCode: "+251",
  },
  {
    iso2: "FK",
    iso3: "FLK",
    latitude: -51.75,
    longitude: -59,
    name: "Falkland Islands (Malvinas)",
    currency: {
      code: "FKP",
      name: "Falkland Islands pound",
      symbol: "£",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+500",
  },
  {
    iso2: "FO",
    iso3: "FRO",
    latitude: 62,
    longitude: -7,
    name: "Faroe Islands",
    currency: {
      code: "DKK",
      name: "Danish krone",
      symbol: "kr",
    },
    language: {
      code: "fo",
      name: "Faroese",
    },
    dialCode: "+298",
  },
  {
    iso2: "FJ",
    iso3: "FJI",
    latitude: -18,
    longitude: 175,
    name: "Fiji",
    currency: {
      code: "FJD",
      name: "Fijian dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+679",
  },
  {
    iso2: "FI",
    iso3: "FIN",
    latitude: 64,
    longitude: 26,
    name: "Finland",
    currency: {
      code: "EUR",
      name: "Euro",
      symbol: "€",
    },
    language: {
      code: "fi",
      iso639_2: "fin",
      name: "Finnish",
      nativeName: "suomi",
    },
    dialCode: "+358",
  },
  {
    iso2: "FR",
    iso3: "FRA",
    latitude: 46,
    longitude: 2,
    name: "France",
    currency: {
      code: "EUR",
      name: "Euro",
      symbol: "€",
    },
    language: {
      code: "fr",
      name: "French",
    },
    dialCode: "+33",
  },
  {
    iso2: "GF",
    iso3: "GUF",
    latitude: 4,
    longitude: -53,
    name: "French Guiana",
    currency: {
      code: "EUR",
      name: "Euro",
      symbol: "€",
    },
    language: {
      code: "fr",
      name: "French",
    },
    dialCode: "+594",
  },
  {
    iso2: "PF",
    iso3: "PYF",
    latitude: -15,
    longitude: -140,
    name: "French Polynesia",
    currency: {
      code: "XPF",
      name: "CFP franc",
      symbol: "Fr",
    },
    language: {
      code: "fr",
      name: "French",
    },
    dialCode: "+689",
  },
  {
    iso2: "GA",
    iso3: "GAB",
    latitude: -1,
    longitude: 11.75,
    name: "Gabon",
    currency: {
      code: "XAF",
      name: "Central African CFA franc",
      symbol: "Fr",
    },
    language: {
      code: "fr",
      name: "French",
    },
    dialCode: "+241",
  },
  {
    iso2: "GM",
    iso3: "GMB",
    latitude: 13.46666666,
    longitude: -16.56666666,
    name: "Gambia",
    currency: {
      code: "GMD",
      name: "Gambian dalasi",
      symbol: "D",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+220",
  },
  {
    iso2: "GE",
    iso3: "GEO",
    latitude: 42,
    longitude: 43.5,
    name: "Georgia",
    currency: {
      code: "GEL",
      name: "Georgian Lari",
      symbol: "ლ",
    },
    language: {
      code: "ka",
      name: "Georgian",
    },
    dialCode: "+995",
  },
  {
    iso2: "DE",
    iso3: "DEU",
    latitude: 51,
    longitude: 9,
    name: "Germany",
    currency: {
      code: "EUR",
      name: "Euro",
      symbol: "€",
    },
    language: {
      code: "de",
      name: "German",
    },
    dialCode: "+49",
  },
  {
    iso2: "GH",
    iso3: "GHA",
    latitude: 8,
    longitude: -2,
    name: "Ghana",
    currency: {
      code: "GHS",
      name: "Ghanaian cedi",
      symbol: "₵",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+233",
  },
  {
    iso2: "GI",
    iso3: "GIB",
    latitude: 36.13333333,
    longitude: -5.35,
    name: "Gibraltar",
    currency: {
      code: "GIP",
      name: "Gibraltar pound",
      symbol: "£",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+350",
  },
  {
    iso2: "GR",
    iso3: "GRC",
    latitude: 39,
    longitude: 22,
    name: "Greece",
    currency: {
      code: "EUR",
      name: "Euro",
      symbol: "€",
    },
    language: {
      code: "el",
      name: "Greek (modern)",
    },
    dialCode: "+30",
  },
  {
    iso2: "GL",
    iso3: "GRL",
    latitude: 72,
    longitude: -40,
    name: "Greenland",
    currency: {
      code: "DKK",
      name: "Danish krone",
      symbol: "kr",
    },
    language: {
      code: "kl",
      name: "Kalaallisut",
    },
    dialCode: "+299",
  },
  {
    iso2: "GD",
    iso3: "GRD",
    latitude: 12.11666666,
    longitude: -61.66666666,
    name: "Grenada",
    currency: {
      code: "XCD",
      name: "East Caribbean dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+1473",
  },
  {
    iso2: "GP",
    iso3: "GLP",
    latitude: 16.25,
    longitude: -61.583333,
    name: "Guadeloupe",
    currency: {
      code: "EUR",
      name: "Euro",
      symbol: "€",
    },
    language: {
      code: "fr",
      name: "French",
    },
    dialCode: "+590",
  },
  {
    iso2: "GU",
    iso3: "GUM",
    latitude: 13.46666666,
    longitude: 144.78333333,
    name: "Guam",
    currency: {
      code: "USD",
      name: "United States dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+1671",
  },
  {
    iso2: "GT",
    iso3: "GTM",
    latitude: 15.5,
    longitude: -90.25,
    name: "Guatemala",
    currency: {
      code: "GTQ",
      name: "Guatemalan quetzal",
      symbol: "Q",
    },
    language: {
      code: "es",
      name: "Spanish",
    },
    dialCode: "+502",
  },
  {
    iso2: "GG",
    iso3: "GGY",
    latitude: 49.46666666,
    longitude: -2.58333333,
    name: "Guernsey",
    currency: {
      code: "GBP",
      name: "British pound",
      symbol: "£",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+44",
  },
  {
    iso2: "GN",
    iso3: "GIN",
    latitude: 11,
    longitude: -10,
    name: "Guinea",
    currency: {
      code: "GNF",
      name: "Guinean franc",
      symbol: "Fr",
    },
    language: {
      code: "fr",
      name: "French",
    },
    dialCode: "+224",
  },
  {
    iso2: "GW",
    iso3: "GNB",
    latitude: 12,
    longitude: -15,
    name: "Guinea-Bissau",
    currency: {
      code: "XOF",
      name: "West African CFA franc",
      symbol: "Fr",
    },
    language: {
      code: "pt",
      name: "Portuguese",
    },
    dialCode: "+245",
  },
  {
    iso2: "GY",
    iso3: "GUY",
    latitude: 5,
    longitude: -59,
    name: "Guyana",
    currency: {
      code: "GYD",
      name: "Guyanese dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+592",
  },
  {
    iso2: "HT",
    iso3: "HTI",
    latitude: 19,
    longitude: -72.41666666,
    name: "Haiti",
    currency: {
      code: "HTG",
      name: "Haitian gourde",
      symbol: "G",
    },
    language: {
      code: "fr",
      name: "French",
    },
    dialCode: "+509",
  },
  {
    iso2: "VA",
    iso3: "VAT",
    latitude: 41.9,
    longitude: 12.45,
    name: "Holy See",
    currency: {
      code: "EUR",
      name: "Euro",
      symbol: "€",
    },
    language: {
      code: "fr",
      name: "French",
    },
    dialCode: "+379",
  },
  {
    iso2: "HN",
    iso3: "HND",
    latitude: 15,
    longitude: -86.5,
    name: "Honduras",
    currency: {
      code: "HNL",
      name: "Honduran lempira",
      symbol: "L",
    },
    language: {
      code: "es",
      name: "Spanish",
    },
    dialCode: "+504",
  },
  {
    iso2: "HK",
    iso3: "HKG",
    latitude: 22.25,
    longitude: 114.16666666,
    name: "Hong Kong",
    currency: {
      code: "HKD",
      name: "Hong Kong dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+852",
  },
  {
    iso2: "HU",
    iso3: "HUN",
    latitude: 47,
    longitude: 20,
    name: "Hungary",
    currency: {
      code: "HUF",
      name: "Hungarian forint",
      symbol: "Ft",
    },
    language: {
      code: "hu",
      name: "Hungarian",
    },
    dialCode: "+36",
  },
  {
    iso2: "IS",
    iso3: "ISL",
    latitude: 65,
    longitude: -18,
    name: "Iceland",
    currency: {
      code: "ISK",
      name: "Icelandic króna",
      symbol: "kr",
    },
    language: {
      code: "is",
      name: "Icelandic",
    },
    dialCode: "+354",
  },
  {
    iso2: "IN",
    iso3: "IND",
    latitude: 20,
    longitude: 77,
    name: "India",
    currency: {
      code: "INR",
      name: "Indian rupee",
      symbol: "₹",
    },
    language: {
      code: "hi",
      name: "Hindi",
    },
    dialCode: "+91",
  },
  {
    iso2: "ID",
    iso3: "IDN",
    latitude: -5,
    longitude: 120,
    name: "Indonesia",
    currency: {
      code: "IDR",
      name: "Indonesian rupiah",
      symbol: "Rp",
    },
    language: {
      code: "id",
      name: "Indonesian",
    },
    dialCode: "+62",
  },
  {
    iso2: "IR",
    iso3: "IRN",
    latitude: 32,
    longitude: 53,
    name: "Iran (Islamic Republic of)",
    currency: {
      code: "IRR",
      name: "Iranian rial",
      symbol: "﷼",
    },
    language: {
      code: "fa",
      name: "Persian (Farsi)",
    },
    dialCode: "+98",
  },
  {
    iso2: "IQ",
    iso3: "IRQ",
    latitude: 33,
    longitude: 44,
    name: "Iraq",
    currency: {
      code: "IQD",
      name: "Iraqi dinar",
      symbol: "ع.د",
    },
    language: {
      code: "ar",
      name: "Arabic",
    },
    dialCode: "+964",
  },
  {
    iso2: "IE",
    iso3: "IRL",
    latitude: 53,
    longitude: -8,
    name: "Ireland",
    currency: {
      code: "EUR",
      name: "Euro",
      symbol: "€",
    },
    language: {
      code: "ga",
      name: "Irish",
    },
    dialCode: "+353",
  },
  {
    iso2: "IM",
    iso3: "IMN",
    latitude: 54.25,
    longitude: -4.5,
    name: "Isle of Man",
    currency: {
      code: "GBP",
      name: "British pound",
      symbol: "£",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+44",
  },
  {
    iso2: "IT",
    iso3: "ITA",
    latitude: 42.83333333,
    longitude: 12.83333333,
    name: "Italy",
    currency: {
      code: "EUR",
      name: "Euro",
      symbol: "€",
    },
    language: {
      code: "it",
      name: "Italian",
    },
    dialCode: "+39",
  },
  {
    iso2: "CI",
    iso3: "CIV",
    latitude: 8,
    longitude: -5,
    name: "Côte d'Ivoire",
    currency: {
      code: "XOF",
      name: "West African CFA franc",
      symbol: "Fr",
    },
    language: {
      code: "fr",
      name: "French",
    },
    dialCode: "+225",
  },
  {
    iso2: "JM",
    iso3: "JAM",
    latitude: 18.25,
    longitude: -77.5,
    name: "Jamaica",
    currency: {
      code: "JMD",
      name: "Jamaican dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+1876",
  },
  {
    iso2: "JP",
    iso3: "JPN",
    latitude: 36,
    longitude: 138,
    name: "Japan",
    currency: {
      code: "JPY",
      name: "Japanese yen",
      symbol: "¥",
    },
    language: {
      code: "ja",
      name: "Japanese",
    },
    dialCode: "+81",
  },
  {
    iso2: "JE",
    iso3: "JEY",
    latitude: 49.25,
    longitude: -2.16666666,
    name: "Jersey",
    currency: {
      code: "GBP",
      name: "British pound",
      symbol: "£",
    },
    language: {
      code: "en",
      iso639_2: "eng",
      name: "English",
      nativeName: "English",
    },
    dialCode: "+44",
  },
  {
    iso2: "JO",
    iso3: "JOR",
    latitude: 31,
    longitude: 36,
    name: "Jordan",
    currency: {
      code: "JOD",
      name: "Jordanian dinar",
      symbol: "د.ا",
    },
    language: {
      code: "ar",
      name: "Arabic",
    },
    dialCode: "+962",
  },
  {
    iso2: "KZ",
    iso3: "KAZ",
    latitude: 48,
    longitude: 68,
    name: "Kazakhstan",
    currency: {
      code: "KZT",
      name: "Kazakhstani tenge",
      symbol: null,
    },
    language: {
      code: "kk",
      name: "Kazakh",
    },
    dialCode: "+77",
  },
  {
    iso2: "KE",
    iso3: "KEN",
    latitude: 1,
    longitude: 38,
    name: "Kenya",
    currency: {
      code: "KES",
      name: "Kenyan shilling",
      symbol: "Sh",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+254",
  },
  {
    iso2: "KI",
    iso3: "KIR",
    latitude: 1.41666666,
    longitude: 173,
    name: "Kiribati",
    currency: {
      code: "AUD",
      name: "Australian dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+686",
  },
  {
    iso2: "KP",
    iso3: "PRK",
    latitude: 40,
    longitude: 127,
    name: "Korea (Democratic People's Republic of)",
    currency: {
      code: "KPW",
      name: "North Korean won",
      symbol: "₩",
    },
    language: {
      code: "ko",
      name: "Korean",
    },
    dialCode: "+850",
  },
  {
    iso2: "KR",
    iso3: "KOR",
    latitude: 37,
    longitude: 127.5,
    name: "Korea (Republic of)",
    currency: {
      code: "KRW",
      name: "South Korean won",
      symbol: "₩",
    },
    language: {
      code: "ko",
      name: "Korean",
    },
    dialCode: "+82",
  },
  {
    iso2: "XK",
    iso3: "KOS",
    latitude: 42.666667,
    longitude: 21.166667,
    name: "Republic of Kosovo",
    currency: {
      code: "EUR",
      name: "Euro",
      symbol: "€",
    },
    language: {
      code: "sq",
      name: "Albanian",
    },
    dialCode: "+383",
  },
  {
    iso2: "KW",
    iso3: "KWT",
    latitude: 29.5,
    longitude: 45.75,
    name: "Kuwait",
    currency: {
      code: "KWD",
      name: "Kuwaiti dinar",
      symbol: "د.ك",
    },
    language: {
      code: "ar",
      name: "Arabic",
    },
    dialCode: "+965",
  },
  {
    iso2: "KG",
    iso3: "KGZ",
    latitude: 41,
    longitude: 75,
    name: "Kyrgyzstan",
    currency: {
      code: "KGS",
      name: "Kyrgyzstani som",
      symbol: "с",
    },
    language: {
      code: "ky",
      name: "Kyrgyz",
    },
    dialCode: "+996",
  },
  {
    iso2: "LA",
    iso3: "LAO",
    latitude: 18,
    longitude: 105,
    name: "Lao People's Democratic Republic",
    currency: {
      code: "LAK",
      name: "Lao kip",
      symbol: "₭",
    },
    language: {
      code: "lo",
      name: "Lao",
    },
    dialCode: "+856",
  },
  {
    iso2: "LV",
    iso3: "LVA",
    latitude: 57,
    longitude: 25,
    name: "Latvia",
    currency: {
      code: "EUR",
      name: "Euro",
      symbol: "€",
    },
    language: {
      code: "lv",
      name: "Latvian",
    },
    dialCode: "+371",
  },
  {
    iso2: "LB",
    iso3: "LBN",
    latitude: 33.83333333,
    longitude: 35.83333333,
    name: "Lebanon",
    currency: {
      code: "LBP",
      name: "Lebanese pound",
      symbol: "ل.ل",
    },
    language: {
      code: "ar",
      name: "Arabic",
    },
    dialCode: "+961",
  },
  {
    iso2: "LS",
    iso3: "LSO",
    latitude: -29.5,
    longitude: 28.5,
    name: "Lesotho",
    currency: {
      code: "LSL",
      name: "Lesotho loti",
      symbol: "L",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+266",
  },
  {
    iso2: "LR",
    iso3: "LBR",
    latitude: 6.5,
    longitude: -9.5,
    name: "Liberia",
    currency: {
      code: "LRD",
      name: "Liberian dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+231",
  },
  {
    iso2: "LY",
    iso3: "LBY",
    latitude: 25,
    longitude: 17,
    name: "Libya",
    currency: {
      code: "LYD",
      name: "Libyan dinar",
      symbol: "ل.د",
    },
    language: {
      code: "ar",
      name: "Arabic",
    },
    dialCode: "+218",
  },
  {
    iso2: "LI",
    iso3: "LIE",
    latitude: 47.26666666,
    longitude: 9.53333333,
    name: "Liechtenstein",
    currency: {
      code: "CHF",
      name: "Swiss franc",
      symbol: "Fr",
    },
    language: {
      code: "de",
      name: "German",
    },
    dialCode: "+423",
  },
  {
    iso2: "LT",
    iso3: "LTU",
    latitude: 56,
    longitude: 24,
    name: "Lithuania",
    currency: {
      code: "EUR",
      name: "Euro",
      symbol: "€",
    },
    language: {
      code: "lt",
      name: "Lithuanian",
    },
    dialCode: "+370",
  },
  {
    iso2: "LU",
    iso3: "LUX",
    latitude: 49.75,
    longitude: 6.16666666,
    name: "Luxembourg",
    currency: {
      code: "EUR",
      name: "Euro",
      symbol: "€",
    },
    language: {
      code: "fr",
      name: "French",
    },
    dialCode: "+352",
  },
  {
    iso2: "MO",
    iso3: "MAC",
    latitude: 22.16666666,
    longitude: 113.55,
    name: "Macao",
    currency: {
      code: "MOP",
      name: "Macanese pataca",
      symbol: "P",
    },
    language: {
      code: "zh",
      name: "Chinese",
    },
    dialCode: "+853",
  },
  {
    iso2: "MG",
    iso3: "MDG",
    latitude: -20,
    longitude: 47,
    name: "Madagascar",
    currency: {
      code: "MGA",
      name: "Malagasy ariary",
      symbol: "Ar",
    },
    language: {
      code: "fr",
      name: "French",
    },
    dialCode: "+261",
  },
  {
    iso2: "MW",
    iso3: "MWI",
    latitude: -13.5,
    longitude: 34,
    name: "Malawi",
    currency: {
      code: "MWK",
      name: "Malawian kwacha",
      symbol: "MK",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+265",
  },
  {
    iso2: "MY",
    iso3: "MYS",
    latitude: 2.5,
    longitude: 112.5,
    name: "Malaysia",
    currency: {
      code: "MYR",
      name: "Malaysian ringgit",
      symbol: "RM",
    },
    language: {
      code: null,
      name: "Malaysian",
    },
    dialCode: "+60",
  },
  {
    iso2: "MV",
    iso3: "MDV",
    latitude: 3.25,
    longitude: 73,
    name: "Maldives",
    currency: {
      code: "MVR",
      name: "Maldivian rufiyaa",
      symbol: ".ރ",
    },
    language: {
      code: "dv",
      name: "Divehi",
    },
    dialCode: "+960",
  },
  {
    iso2: "ML",
    iso3: "MLI",
    latitude: 17,
    longitude: -4,
    name: "Mali",
    currency: {
      code: "XOF",
      name: "West African CFA franc",
      symbol: "Fr",
    },
    language: {
      code: "fr",
      name: "French",
    },
    dialCode: "+223",
  },
  {
    iso2: "MT",
    iso3: "MLT",
    latitude: 35.83333333,
    longitude: 14.58333333,
    name: "Malta",
    currency: {
      code: "EUR",
      name: "Euro",
      symbol: "€",
    },
    language: {
      code: "mt",
      name: "Maltese",
    },
    dialCode: "+356",
  },
  {
    iso2: "MH",
    iso3: "MHL",
    latitude: 9,
    longitude: 168,
    name: "Marshall Islands",
    currency: {
      code: "USD",
      name: "United States dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+692",
  },
  {
    iso2: "MQ",
    iso3: "MTQ",
    latitude: 14.666667,
    longitude: -61,
    name: "Martinique",
    currency: {
      code: "EUR",
      name: "Euro",
      symbol: "€",
    },
    language: {
      code: "fr",
      name: "French",
    },
    dialCode: "+596",
  },
  {
    iso2: "MR",
    iso3: "MRT",
    latitude: 20,
    longitude: -12,
    name: "Mauritania",
    currency: {
      code: "MRO",
      name: "Mauritanian ouguiya",
      symbol: "UM",
    },
    language: {
      code: "ar",
      name: "Arabic",
    },
    dialCode: "+222",
  },
  {
    iso2: "MU",
    iso3: "MUS",
    latitude: -20.28333333,
    longitude: 57.55,
    name: "Mauritius",
    currency: {
      code: "MUR",
      name: "Mauritian rupee",
      symbol: "₨",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+230",
  },
  {
    iso2: "YT",
    iso3: "MYT",
    latitude: -12.83333333,
    longitude: 45.16666666,
    name: "Mayotte",
    currency: {
      code: "EUR",
      name: "Euro",
      symbol: "€",
    },
    language: {
      code: "fr",
      name: "French",
    },
    dialCode: "+262",
  },
  {
    iso2: "MX",
    iso3: "MEX",
    latitude: 23,
    longitude: -102,
    name: "Mexico",
    currency: {
      code: "MXN",
      name: "Mexican peso",
      symbol: "$",
    },
    language: {
      code: "es",
      name: "Spanish",
    },
    dialCode: "+52",
  },
  {
    iso2: "FM",
    iso3: "FSM",
    latitude: 6.91666666,
    longitude: 158.25,
    name: "Micronesia (Federated States of)",
    currency: {
      code: "USD",
      name: "United States dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+691",
  },
  {
    iso2: "MD",
    iso3: "MDA",
    latitude: 47,
    longitude: 29,
    name: "Moldova (Republic of)",
    currency: {
      code: "MDL",
      name: "Moldovan leu",
      symbol: "L",
    },
    language: {
      code: "ro",
      name: "Romanian",
    },
    dialCode: "+373",
  },
  {
    iso2: "MC",
    iso3: "MCO",
    latitude: 43.73333333,
    longitude: 7.4,
    name: "Monaco",
    currency: {
      code: "EUR",
      name: "Euro",
      symbol: "€",
    },
    language: {
      code: "fr",
      name: "French",
    },
    dialCode: "+377",
  },
  {
    iso2: "MN",
    iso3: "MNG",
    latitude: 46,
    longitude: 105,
    name: "Mongolia",
    currency: {
      code: "MNT",
      name: "Mongolian tögrög",
      symbol: "₮",
    },
    language: {
      code: "mn",
      name: "Mongolian",
    },
    dialCode: "+976",
  },
  {
    iso2: "ME",
    iso3: "MNE",
    latitude: 42.5,
    longitude: 19.3,
    name: "Montenegro",
    currency: {
      code: "EUR",
      name: "Euro",
      symbol: "€",
    },
    language: {
      code: "sr",
      name: "Serbian",
    },
    dialCode: "+382",
  },
  {
    iso2: "MS",
    iso3: "MSR",
    latitude: 16.75,
    longitude: -62.2,
    name: "Montserrat",
    currency: {
      code: "XCD",
      name: "East Caribbean dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+1664",
  },
  {
    iso2: "MA",
    iso3: "MAR",
    latitude: 32,
    longitude: -5,
    name: "Morocco",
    currency: {
      code: "MAD",
      name: "Moroccan dirham",
      symbol: "د.م.",
    },
    language: {
      code: "ar",
      name: "Arabic",
    },
    dialCode: "+212",
  },
  {
    iso2: "MZ",
    iso3: "MOZ",
    latitude: -18.25,
    longitude: 35,
    name: "Mozambique",
    currency: {
      code: "MZN",
      name: "Mozambican metical",
      symbol: "MT",
    },
    language: {
      code: "pt",
      name: "Portuguese",
    },
    dialCode: "+258",
  },
  {
    iso2: "MM",
    iso3: "MMR",
    latitude: 22,
    longitude: 98,
    name: "Myanmar",
    currency: {
      code: "MMK",
      name: "Burmese kyat",
      symbol: "Ks",
    },
    language: {
      code: "my",
      name: "Burmese",
    },
    dialCode: "+95",
  },
  {
    iso2: "NA",
    iso3: "NAM",
    latitude: -22,
    longitude: 17,
    name: "Namibia",
    currency: {
      code: "NAD",
      name: "Namibian dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+264",
  },
  {
    iso2: "NR",
    iso3: "NRU",
    latitude: -0.53333333,
    longitude: 166.91666666,
    name: "Nauru",
    currency: {
      code: "AUD",
      name: "Australian dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+674",
  },
  {
    iso2: "NP",
    iso3: "NPL",
    latitude: 28,
    longitude: 84,
    name: "Nepal",
    currency: {
      code: "NPR",
      name: "Nepalese rupee",
      symbol: "₨",
    },
    language: {
      code: "ne",
      name: "Nepali",
    },
    dialCode: "+977",
  },
  {
    iso2: "NL",
    iso3: "NLD",
    latitude: 52.5,
    longitude: 5.75,
    name: "Netherlands",
    currency: {
      code: "EUR",
      name: "Euro",
      symbol: "€",
    },
    language: {
      code: "nl",
      name: "Dutch",
    },
    dialCode: "+31",
  },
  {
    iso2: "NC",
    iso3: "NCL",
    latitude: -21.5,
    longitude: 165.5,
    name: "New Caledonia",
    currency: {
      code: "XPF",
      name: "CFP franc",
      symbol: "Fr",
    },
    language: {
      code: "fr",
      name: "French",
    },
    dialCode: "+687",
  },
  {
    iso2: "NZ",
    iso3: "NZL",
    latitude: -41,
    longitude: 174,
    name: "New Zealand",
    currency: {
      code: "NZD",
      name: "New Zealand dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+64",
  },
  {
    iso2: "NI",
    iso3: "NIC",
    latitude: 13,
    longitude: -85,
    name: "Nicaragua",
    currency: {
      code: "NIO",
      name: "Nicaraguan córdoba",
      symbol: "C$",
    },
    language: {
      code: "es",
      name: "Spanish",
    },
    dialCode: "+505",
  },
  {
    iso2: "NE",
    iso3: "NER",
    latitude: 16,
    longitude: 8,
    name: "Niger",
    currency: {
      code: "XOF",
      name: "West African CFA franc",
      symbol: "Fr",
    },
    language: {
      code: "fr",
      name: "French",
    },
    dialCode: "+227",
  },
  {
    iso2: "NG",
    iso3: "NGA",
    latitude: 10,
    longitude: 8,
    name: "Nigeria",
    currency: {
      code: "NGN",
      name: "Nigerian naira",
      symbol: "₦",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+234",
  },
  {
    iso2: "NU",
    iso3: "NIU",
    latitude: -19.03333333,
    longitude: -169.86666666,
    name: "Niue",
    currency: {
      code: "NZD",
      name: "New Zealand dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+683",
  },
  {
    iso2: "NF",
    iso3: "NFK",
    latitude: -29.03333333,
    longitude: 167.95,
    name: "Norfolk Island",
    currency: {
      code: "AUD",
      name: "Australian dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+672",
  },
  {
    iso2: "MK",
    iso3: "MKD",
    latitude: 41.83333333,
    longitude: 22,
    name: "Macedonia (the former Yugoslav Republic of)",
    currency: {
      code: "MKD",
      name: "Macedonian denar",
      symbol: "ден",
    },
    language: {
      code: "mk",
      name: "Macedonian",
    },
    dialCode: "+389",
  },
  {
    iso2: "MP",
    iso3: "MNP",
    latitude: 15.2,
    longitude: 145.75,
    name: "Northern Mariana Islands",
    currency: {
      code: "USD",
      name: "United States dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+1670",
  },
  {
    iso2: "NO",
    iso3: "NOR",
    latitude: 62,
    longitude: 10,
    name: "Norway",
    currency: {
      code: "NOK",
      name: "Norwegian krone",
      symbol: "kr",
    },
    language: {
      code: "no",
      name: "Norwegian",
    },
    dialCode: "+47",
  },
  {
    iso2: "OM",
    iso3: "OMN",
    latitude: 21,
    longitude: 57,
    name: "Oman",
    currency: {
      code: "OMR",
      name: "Omani rial",
      symbol: "ر.ع.",
    },
    language: {
      code: "ar",
      name: "Arabic",
    },
    dialCode: "+968",
  },
  {
    iso2: "PK",
    iso3: "PAK",
    latitude: 30,
    longitude: 70,
    name: "Pakistan",
    currency: {
      code: "PKR",
      name: "Pakistani rupee",
      symbol: "₨",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+92",
  },
  {
    iso2: "PW",
    iso3: "PLW",
    latitude: 7.5,
    longitude: 134.5,
    name: "Palau",
    currency: {
      code: "USD",
      name: "United States dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+680",
  },
  {
    iso2: "PS",
    iso3: "PSE",
    latitude: 31.9,
    longitude: 35.2,
    name: "Palestine, State of",
    currency: {
      code: "ILS",
      name: "Israeli new sheqel",
      symbol: "₪",
    },
    language: {
      code: "ar",
      name: "Arabic",
    },
    dialCode: "+970",
  },
  {
    iso2: "PA",
    iso3: "PAN",
    latitude: 9,
    longitude: -80,
    name: "Panama",
    currency: {
      code: "USD",
      name: "United States dollar",
      symbol: "$",
    },
    language: {
      code: "es",
      name: "Spanish",
    },
    dialCode: "+507",
  },
  {
    iso2: "PG",
    iso3: "PNG",
    latitude: -6,
    longitude: 147,
    name: "Papua New Guinea",
    currency: {
      code: "PGK",
      name: "Papua New Guinean kina",
      symbol: "K",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+675",
  },
  {
    iso2: "PY",
    iso3: "PRY",
    latitude: -23,
    longitude: -58,
    name: "Paraguay",
    currency: {
      code: "PYG",
      name: "Paraguayan guaraní",
      symbol: "₲",
    },
    language: {
      code: "es",
      name: "Spanish",
    },
    dialCode: "+595",
  },
  {
    iso2: "PE",
    iso3: "PER",
    latitude: -10,
    longitude: -76,
    name: "Peru",
    currency: {
      code: "PEN",
      name: "Peruvian sol",
      symbol: "S/.",
    },
    language: {
      code: "es",
      name: "Spanish",
    },
    dialCode: "+51",
  },
  {
    iso2: "PH",
    iso3: "PHL",
    latitude: 13,
    longitude: 122,
    name: "Philippines",
    currency: {
      code: "PHP",
      name: "Philippine peso",
      symbol: "₱",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+63",
  },
  {
    iso2: "PN",
    iso3: "PCN",
    latitude: -25.06666666,
    longitude: -130.1,
    name: "Pitcairn",
    currency: {
      code: "NZD",
      name: "New Zealand dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+872",
  },
  {
    iso2: "PL",
    iso3: "POL",
    latitude: 52,
    longitude: 20,
    name: "Poland",
    currency: {
      code: "PLN",
      name: "Polish złoty",
      symbol: "zł",
    },
    language: {
      code: "pl",
      name: "Polish",
    },
    dialCode: "+48",
  },
  {
    iso2: "PT",
    iso3: "PRT",
    latitude: 39.5,
    longitude: -8,
    name: "Portugal",
    currency: {
      code: "EUR",
      name: "Euro",
      symbol: "€",
    },
    language: {
      code: "pt",
      name: "Portuguese",
    },
    dialCode: "+351",
  },
  {
    iso2: "PR",
    iso3: "PRI",
    latitude: 18.25,
    longitude: -66.5,
    name: "Puerto Rico",
    currency: {
      code: "USD",
      name: "United States dollar",
      symbol: "$",
    },
    language: {
      code: "es",
      name: "Spanish",
    },
    dialCode: "+1939",
  },
  {
    iso2: "QA",
    iso3: "QAT",
    latitude: 25.5,
    longitude: 51.25,
    name: "Qatar",
    currency: {
      code: "QAR",
      name: "Qatari riyal",
      symbol: "ر.ق",
    },
    language: {
      code: "ar",
      name: "Arabic",
    },
    dialCode: "+974",
  },
  {
    iso2: "RE",
    iso3: "REU",
    latitude: -21.15,
    longitude: 55.5,
    name: "Réunion",
    currency: {
      code: "EUR",
      name: "Euro",
      symbol: "€",
    },
    language: {
      code: "fr",
      name: "French",
    },
    dialCode: "+262",
  },
  {
    iso2: "RO",
    iso3: "ROU",
    latitude: 46,
    longitude: 25,
    name: "Romania",
    currency: {
      code: "RON",
      name: "Romanian leu",
      symbol: "lei",
    },
    language: {
      code: "ro",
      name: "Romanian",
    },
    dialCode: "+40",
  },
  {
    iso2: "RU",
    iso3: "RUS",
    latitude: 60,
    longitude: 100,
    name: "Russian Federation",
    currency: {
      code: "RUB",
      name: "Russian ruble",
      symbol: "₽",
    },
    language: {
      code: "ru",
      name: "Russian",
    },
    dialCode: "+7",
  },
  {
    iso2: "RW",
    iso3: "RWA",
    latitude: -2,
    longitude: 30,
    name: "Rwanda",
    currency: {
      code: "RWF",
      name: "Rwandan franc",
      symbol: "Fr",
    },
    language: {
      code: "rw",
      name: "Kinyarwanda",
    },
    dialCode: "+250",
  },
  {
    iso2: "BL",
    iso3: "BLM",
    latitude: 18.5,
    longitude: -63.41666666,
    name: "Saint Barthélemy",
    currency: {
      code: "EUR",
      name: "Euro",
      symbol: "€",
    },
    language: {
      code: "fr",
      name: "French",
    },
    dialCode: "+590",
  },
  {
    iso2: "SH",
    iso3: "SHN",
    latitude: -15.95,
    longitude: -5.7,
    name: "Saint Helena, Ascension and Tristan da Cunha",
    currency: {
      code: "SHP",
      name: "Saint Helena pound",
      symbol: "£",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+290",
  },
  {
    iso2: "KN",
    iso3: "KNA",
    latitude: 17.33333333,
    longitude: -62.75,
    name: "Saint Kitts and Nevis",
    currency: {
      code: "XCD",
      name: "East Caribbean dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+1869",
  },
  {
    iso2: "LC",
    iso3: "LCA",
    latitude: 13.88333333,
    longitude: -60.96666666,
    name: "Saint Lucia",
    currency: {
      code: "XCD",
      name: "East Caribbean dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+1758",
  },
  {
    iso2: "MF",
    iso3: "MAF",
    latitude: 18.08333333,
    longitude: -63.95,
    name: "Saint Martin (French part)",
    currency: {
      code: "EUR",
      name: "Euro",
      symbol: "€",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+590",
  },
  {
    iso2: "PM",
    iso3: "SPM",
    latitude: 46.83333333,
    longitude: -56.33333333,
    name: "Saint Pierre and Miquelon",
    currency: {
      code: "EUR",
      name: "Euro",
      symbol: "€",
    },
    language: {
      code: "fr",
      name: "French",
    },
    dialCode: "+508",
  },
  {
    iso2: "VC",
    iso3: "VCT",
    latitude: 13.25,
    longitude: -61.2,
    name: "Saint Vincent and the Grenadines",
    currency: {
      code: "XCD",
      name: "East Caribbean dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+1784",
  },
  {
    iso2: "WS",
    iso3: "WSM",
    latitude: -13.58333333,
    longitude: -172.33333333,
    name: "Samoa",
    currency: {
      code: "WST",
      name: "Samoan tālā",
      symbol: "T",
    },
    language: {
      code: "sm",
      name: "Samoan",
    },
    dialCode: "+685",
  },
  {
    iso2: "SM",
    iso3: "SMR",
    latitude: 43.76666666,
    longitude: 12.41666666,
    name: "San Marino",
    currency: {
      code: "EUR",
      name: "Euro",
      symbol: "€",
    },
    language: {
      code: "it",
      name: "Italian",
    },
    dialCode: "+378",
  },
  {
    iso2: "ST",
    iso3: "STP",
    latitude: 1,
    longitude: 7,
    name: "Sao Tome and Principe",
    currency: {
      code: "STD",
      name: "São Tomé and Príncipe dobra",
      symbol: "Db",
    },
    language: {
      code: "pt",
      name: "Portuguese",
    },
    dialCode: "+239",
  },
  {
    iso2: "SA",
    iso3: "SAU",
    latitude: 25,
    longitude: 45,
    name: "Saudi Arabia",
    currency: {
      code: "SAR",
      name: "Saudi riyal",
      symbol: "ر.س",
    },
    language: {
      code: "ar",
      name: "Arabic",
    },
    dialCode: "+966",
  },
  {
    iso2: "SN",
    iso3: "SEN",
    latitude: 14,
    longitude: -14,
    name: "Senegal",
    currency: {
      code: "XOF",
      name: "West African CFA franc",
      symbol: "Fr",
    },
    language: {
      code: "fr",
      name: "French",
    },
    dialCode: "+221",
  },
  {
    iso2: "RS",
    iso3: "SRB",
    latitude: 44,
    longitude: 21,
    name: "Serbia",
    currency: {
      code: "RSD",
      name: "Serbian dinar",
      symbol: "дин.",
    },
    language: {
      code: "sr",
      name: "Serbian",
    },
    dialCode: "+381",
  },
  {
    iso2: "SC",
    iso3: "SYC",
    latitude: -4.58333333,
    longitude: 55.66666666,
    name: "Seychelles",
    currency: {
      code: "SCR",
      name: "Seychellois rupee",
      symbol: "₨",
    },
    language: {
      code: "fr",
      name: "French",
    },
    dialCode: "+248",
  },
  {
    iso2: "SL",
    iso3: "SLE",
    latitude: 8.5,
    longitude: -11.5,
    name: "Sierra Leone",
    currency: {
      code: "SLL",
      name: "Sierra Leonean leone",
      symbol: "Le",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+232",
  },
  {
    iso2: "SG",
    iso3: "SGP",
    latitude: 1.36666666,
    longitude: 103.8,
    name: "Singapore",
    currency: {
      code: "SGD",
      name: "Singapore dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+65",
  },
  {
    iso2: "SX",
    iso3: "SXM",
    latitude: 18.033333,
    longitude: -63.05,
    name: "Sint Maarten (Dutch part)",
    currency: {
      code: "ANG",
      name: "Netherlands Antillean guilder",
      symbol: "ƒ",
    },
    language: {
      code: "nl",
      name: "Dutch",
    },
    dialCode: "+1721",
  },
  {
    iso2: "SK",
    iso3: "SVK",
    latitude: 48.66666666,
    longitude: 19.5,
    name: "Slovakia",
    currency: {
      code: "EUR",
      name: "Euro",
      symbol: "€",
    },
    language: {
      code: "sk",
      name: "Slovak",
    },
    dialCode: "+421",
  },
  {
    iso2: "SI",
    iso3: "SVN",
    latitude: 46.11666666,
    longitude: 14.81666666,
    name: "Slovenia",
    currency: {
      code: "EUR",
      name: "Euro",
      symbol: "€",
    },
    language: {
      code: "sl",
      name: "Slovene",
    },
    dialCode: "+386",
  },
  {
    iso2: "SB",
    iso3: "SLB",
    latitude: -8,
    longitude: 159,
    name: "Solomon Islands",
    currency: {
      code: "SBD",
      name: "Solomon Islands dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+677",
  },
  {
    iso2: "SO",
    iso3: "SOM",
    latitude: 10,
    longitude: 49,
    name: "Somalia",
    currency: {
      code: "SOS",
      name: "Somali shilling",
      symbol: "Sh",
    },
    language: {
      code: "ar",
      name: "Arabic",
    },
    dialCode: "+252",
  },
  {
    iso2: "ZA",
    iso3: "ZAF",
    latitude: -29,
    longitude: 24,
    name: "South Africa",
    currency: {
      code: "ZAR",
      name: "South African rand",
      symbol: "R",
    },
    language: {
      code: "en",
      iso639_2: "eng",
      name: "English",
      nativeName: "English",
    },
    dialCode: "+27",
  },
  {
    iso2: "GS",
    iso3: "SGS",
    latitude: -54.5,
    longitude: -37,
    name: "South Georgia and the South Sandwich Islands",
    currency: {
      code: "GBP",
      name: "British pound",
      symbol: "£",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+500",
  },
  {
    iso2: "SS",
    iso3: "SSD",
    latitude: 7,
    longitude: 30,
    name: "South Sudan",
    currency: {
      code: "SSP",
      name: "South Sudanese pound",
      symbol: "£",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+211",
  },
  {
    iso2: "ES",
    iso3: "ESP",
    latitude: 40,
    longitude: -4,
    name: "Spain",
    currency: {
      code: "EUR",
      name: "Euro",
      symbol: "€",
    },
    language: {
      code: "es",
      name: "Spanish",
    },
    dialCode: "+34",
  },
  {
    iso2: "LK",
    iso3: "LKA",
    latitude: 7,
    longitude: 81,
    name: "Sri Lanka",
    currency: {
      code: "LKR",
      name: "Sri Lankan rupee",
      symbol: "Rs",
    },
    language: {
      code: "si",
      iso639_2: "sin",
      name: "Sinhalese",
      nativeName: "සිංහල",
    },
    dialCode: "+94",
  },
  {
    iso2: "SD",
    iso3: "SDN",
    latitude: 15,
    longitude: 30,
    name: "Sudan",
    currency: {
      code: "SDG",
      name: "Sudanese pound",
      symbol: "ج.س.",
    },
    language: {
      code: "ar",
      name: "Arabic",
    },
    dialCode: "+249",
  },
  {
    iso2: "SR",
    iso3: "SUR",
    latitude: 4,
    longitude: -56,
    name: "Suriname",
    currency: {
      code: "SRD",
      name: "Surinamese dollar",
      symbol: "$",
    },
    language: {
      code: "nl",
      name: "Dutch",
    },
    dialCode: "+597",
  },
  {
    iso2: "SJ",
    iso3: "SJM",
    latitude: 78,
    longitude: 20,
    name: "Svalbard and Jan Mayen",
    currency: {
      code: "NOK",
      name: "Norwegian krone",
      symbol: "kr",
    },
    language: {
      code: "no",
      name: "Norwegian",
    },
    dialCode: "+47",
  },
  {
    iso2: "SE",
    iso3: "SWE",
    latitude: 62,
    longitude: 15,
    name: "Sweden",
    currency: {
      code: "SEK",
      name: "Swedish krona",
      symbol: "kr",
    },
    language: {
      code: "sv",
      name: "Swedish",
    },
    dialCode: "+46",
  },
  {
    iso2: "CH",
    iso3: "CHE",
    latitude: 47,
    longitude: 8,
    name: "Switzerland",
    currency: {
      code: "CHF",
      name: "Swiss franc",
      symbol: "Fr",
    },
    language: {
      code: "de",
      name: "German",
    },
    dialCode: "+41",
  },
  {
    iso2: "SY",
    iso3: "SYR",
    latitude: 35,
    longitude: 38,
    name: "Syrian Arab Republic",
    currency: {
      code: "SYP",
      name: "Syrian pound",
      symbol: "£",
    },
    language: {
      code: "ar",
      name: "Arabic",
    },
    dialCode: "+963",
  },
  {
    iso2: "TW",
    iso3: "TWN",
    latitude: 23.5,
    longitude: 121,
    name: "Taiwan",
    currency: {
      code: "TWD",
      name: "New Taiwan dollar",
      symbol: "$",
    },
    language: {
      code: "zh",
      name: "Chinese",
    },
    dialCode: "+886",
  },
  {
    iso2: "TJ",
    iso3: "TJK",
    latitude: 39,
    longitude: 71,
    name: "Tajikistan",
    currency: {
      code: "TJS",
      name: "Tajikistani somoni",
      symbol: "ЅМ",
    },
    language: {
      code: "tg",
      name: "Tajik",
    },
    dialCode: "+992",
  },
  {
    iso2: "TZ",
    iso3: "TZA",
    latitude: -6,
    longitude: 35,
    name: "Tanzania, United Republic of",
    currency: {
      code: "TZS",
      name: "Tanzanian shilling",
      symbol: "Sh",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+255",
  },
  {
    iso2: "TH",
    iso3: "THA",
    latitude: 15,
    longitude: 100,
    name: "Thailand",
    currency: {
      code: "THB",
      name: "Thai baht",
      symbol: "฿",
    },
    language: {
      code: "th",
      name: "Thai",
    },
    dialCode: "+66",
  },
  {
    iso2: "TL",
    iso3: "TLS",
    latitude: -8.83333333,
    longitude: 125.91666666,
    name: "Timor-Leste",
    currency: {
      code: "USD",
      name: "United States dollar",
      symbol: "$",
    },
    language: {
      code: "pt",
      name: "Portuguese",
    },
    dialCode: "+670",
  },
  {
    iso2: "TG",
    iso3: "TGO",
    latitude: 8,
    longitude: 1.16666666,
    name: "Togo",
    currency: {
      code: "XOF",
      name: "West African CFA franc",
      symbol: "Fr",
    },
    language: {
      code: "fr",
      name: "French",
    },
    dialCode: "+228",
  },
  {
    iso2: "TK",
    iso3: "TKL",
    latitude: -9,
    longitude: -172,
    name: "Tokelau",
    currency: {
      code: "NZD",
      name: "New Zealand dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+690",
  },
  {
    iso2: "TO",
    iso3: "TON",
    latitude: -20,
    longitude: -175,
    name: "Tonga",
    currency: {
      code: "TOP",
      name: "Tongan paʻanga",
      symbol: "T$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+676",
  },
  {
    iso2: "TT",
    iso3: "TTO",
    latitude: 11,
    longitude: -61,
    name: "Trinidad and Tobago",
    currency: {
      code: "TTD",
      name: "Trinidad and Tobago dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+1868",
  },
  {
    iso2: "TN",
    iso3: "TUN",
    latitude: 34,
    longitude: 9,
    name: "Tunisia",
    currency: {
      code: "TND",
      name: "Tunisian dinar",
      symbol: "د.ت",
    },
    language: {
      code: "ar",
      name: "Arabic",
    },
    dialCode: "+216",
  },
  {
    iso2: "TR",
    iso3: "TUR",
    latitude: 39,
    longitude: 35,
    name: "Turkey",
    currency: {
      code: "TRY",
      name: "Turkish lira",
      symbol: null,
    },
    language: {
      code: "tr",
      name: "Turkish",
    },
    dialCode: "+90",
  },
  {
    iso2: "TM",
    iso3: "TKM",
    latitude: 40,
    longitude: 60,
    name: "Turkmenistan",
    currency: {
      code: "TMT",
      name: "Turkmenistan manat",
      symbol: "m",
    },
    language: {
      code: "tk",
      name: "Turkmen",
    },
    dialCode: "+993",
  },
  {
    iso2: "TC",
    iso3: "TCA",
    latitude: 21.75,
    longitude: -71.58333333,
    name: "Turks and Caicos Islands",
    currency: {
      code: "USD",
      name: "United States dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+1649",
  },
  {
    iso2: "TV",
    iso3: "TUV",
    latitude: -8,
    longitude: 178,
    name: "Tuvalu",
    currency: {
      code: "AUD",
      name: "Australian dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+688",
  },
  {
    iso2: "UG",
    iso3: "UGA",
    latitude: 1,
    longitude: 32,
    name: "Uganda",
    currency: {
      code: "UGX",
      name: "Ugandan shilling",
      symbol: "Sh",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+256",
  },
  {
    iso2: "UA",
    iso3: "UKR",
    latitude: 49,
    longitude: 32,
    name: "Ukraine",
    currency: {
      code: "UAH",
      name: "Ukrainian hryvnia",
      symbol: "₴",
    },
    language: {
      code: "uk",
      name: "Ukrainian",
    },
    dialCode: "+380",
  },
  {
    iso2: "AE",
    iso3: "ARE",
    latitude: 24,
    longitude: 54,
    name: "United Arab Emirates",
    currency: {
      code: "AED",
      name: "United Arab Emirates dirham",
      symbol: "د.إ",
    },
    language: {
      code: "ar",
      name: "Arabic",
    },
    dialCode: "+971",
  },
  {
    iso2: "GB",
    iso3: "GBR",
    latitude: 54,
    longitude: -2,
    name: "United Kingdom",
    // name: "United Kingdom of Great Britain and Northern Ireland",
    currency: {
      code: "GBP",
      name: "British pound",
      symbol: "£",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+44",
  },
  {
    iso2: "US",
    iso3: "USA",
    latitude: 38,
    longitude: -97,
    name: "United States of America",
    currency: {
      code: "USD",
      name: "United States dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      iso639_2: "eng",
      name: "English",
      nativeName: "English",
    },
    dialCode: "+1",
  },
  {
    iso2: "UY",
    iso3: "URY",
    latitude: -33,
    longitude: -56,
    name: "Uruguay",
    currency: {
      code: "UYU",
      name: "Uruguayan peso",
      symbol: "$",
    },
    language: {
      code: "es",
      name: "Spanish",
    },
    dialCode: "+598",
  },
  {
    iso2: "UZ",
    iso3: "UZB",
    latitude: 41,
    longitude: 64,
    name: "Uzbekistan",
    currency: {
      code: "UZS",
      name: "Uzbekistani so'm",
      symbol: null,
    },
    language: {
      code: "uz",
      name: "Uzbek",
    },
    dialCode: "+998",
  },
  {
    iso2: "VU",
    iso3: "VUT",
    latitude: -16,
    longitude: 167,
    name: "Vanuatu",
    currency: {
      code: "VUV",
      name: "Vanuatu vatu",
      symbol: "Vt",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+678",
  },
  {
    iso2: "VE",
    iso3: "VEN",
    latitude: 8,
    longitude: -66,
    name: "Venezuela (Bolivarian Republic of)",
    currency: {
      code: "VEF",
      name: "Venezuelan bolívar",
      symbol: "Bs F",
    },
    language: {
      code: "es",
      name: "Spanish",
    },
    dialCode: "+58",
  },
  {
    iso2: "VN",
    iso3: "VNM",
    latitude: 16.16666666,
    longitude: 107.83333333,
    name: "Viet Nam",
    currency: {
      code: "VND",
      name: "Vietnamese đồng",
      symbol: "₫",
    },
    language: {
      code: "vi",
      name: "Vietnamese",
    },
    dialCode: "+84",
  },
  {
    iso2: "VG",
    iso3: "VGB",
    latitude: 18.431383,
    longitude: -64.62305,
    name: "Virgin Islands (British)",
    currency: {
      code: "USD",
      name: "United States dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+1284",
  },
  {
    iso2: "VI",
    iso3: "VIR",
    latitude: 18.34,
    longitude: -64.93,
    name: "Virgin Islands (U.S.)",
    currency: {
      code: "USD",
      name: "United States dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+1340",
  },
  {
    iso2: "WF",
    iso3: "WLF",
    latitude: -13.3,
    longitude: -176.2,
    name: "Wallis and Futuna",
    currency: {
      code: "XPF",
      name: "CFP franc",
      symbol: "Fr",
    },
    language: {
      code: "fr",
      name: "French",
    },
    dialCode: "+681",
  },
  {
    iso2: "YE",
    iso3: "YEM",
    latitude: 15,
    longitude: 48,
    name: "Yemen",
    currency: {
      code: "YER",
      name: "Yemeni rial",
      symbol: "﷼",
    },
    language: {
      code: "ar",
      name: "Arabic",
    },
    dialCode: "+967",
  },
  {
    iso2: "ZM",
    iso3: "ZMB",
    latitude: -15,
    longitude: 30,
    name: "Zambia",
    currency: {
      code: "ZMW",
      name: "Zambian kwacha",
      symbol: "ZK",
    },
    language: {
      code: "en",
      name: "English",
    },
    dialCode: "+260",
  },
  {
    iso2: "ZW",
    iso3: "ZWE",
    latitude: -20,
    longitude: 30,
    name: "Zimbabwe",
    currency: {
      code: "BWP",
      name: "Botswana pula",
      symbol: "P",
    },
    language: {
      code: "en",
      iso639_2: "eng",
      name: "English",
      nativeName: "English",
    },
    dialCode: "+263",
  },
];

export function findCurrencySymbolByCode(code) {
  return countries.find(
    (country) => country.currency.code === code?.toUpperCase()
  )?.currency?.symbol;
}

export function findCountryByISO2(iso2) {
  return countries.find((country) => country.iso2 === iso2);
}

export function findCountryByISO3(iso3) {
  return countries.find((country) => country.iso3 === iso3);
}

export function findIso2ByISO3(value) {
  return countries.find(({ iso3 }) => iso3 === value).iso2;
}

export function findCountryByName(country) {
  return countries.find(({ name }) => name === country);
}

export function convertCountryToOptions() {
  return countries.map((country) => ({
    label: country.name,
    iso3: country.iso3,
    dialCode: country.dialCode,
    value: country.name,
  }));
}

export const convertCountryToSelectModelWithFlag = (country) => ({
  label: country ? (
    <>
      <span className="tw-text-truncate">
        <ReactCountryFlag
          countryCode={findIso2ByISO3(country.iso3) || ""}
          svg
          style={{
            fontSize: "29px",
          }}
        />
        &nbsp;&nbsp;{country?.name}
      </span>
    </>
  ) : (
    ""
  ),
  value: country?.name,
});
