import FormContainer from "../../FormContainer";
import { useEventFormContext } from "../../context";
import CommissionAndRemainingTickets from "./CommissionAndRemainingTickets";
import FormTickets from "./Tickets";
import { validationSchema } from "./constants";
import { unwrapResult } from "@reduxjs/toolkit";
import { Button } from "components/shared/Button";
import Switch from "components/shared/Switch";
import TextInput from "components/shared/forms/TextInput";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updateEventTicketSettingsRequest } from "state/slices/events";
import { getAPIErrorsForFormik, getOneLinerErrorMessage } from "utilities";

const FormTicketInformation = ({ goToPreviousStep, successCallback }) => {
  const { event, updateEvent, exitFunction } = useEventFormContext();

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const [shouldExit, setShouldExit] = useState(false);

  const handleUpdate = async (formValues, formikHelpers) => {
    if (event.ticketTypes.length === 0) {
      toast.error("At least one event ticket is required.");
      setShouldExit(false);
      return;
    }

    const payload = {
      ...formValues,
      eventId: event.id,
    };

    setIsLoading(true);

    const resultAction = await dispatch(
      updateEventTicketSettingsRequest(payload)
    );
    if (updateEventTicketSettingsRequest.fulfilled.match(resultAction)) {
      const response = unwrapResult(resultAction);
      setIsLoading(false);
      toast.success("Successfully saved ticket information");
      updateEvent({ ticketSettings: response.data });
      shouldExit ? exitFunction() : successCallback?.();
    } else {
      setIsLoading(false);
      if (resultAction.payload.message) {
        toast.error(resultAction.payload.message);
      } else {
        formikHelpers.setErrors(getAPIErrorsForFormik(resultAction.payload));
        toast.error(
          getOneLinerErrorMessage(resultAction.payload) ||
            resultAction.error?.message ||
            "An error occurred, please try again."
        );
      }
    }
  };

  const saveFormData = (submitForm) => {
    setShouldExit(true);
    submitForm?.();
  };
  useEffect(() => {
    if (!isLoading) setShouldExit(false);
  }, [isLoading]);

  return (
    <FormContainer>
      <FormTickets />

      <div className="tw-mt-6">
        <Formik
          enableReinitialize={true}
          initialValues={event.ticketSettings}
          validationSchema={validationSchema}
          onSubmit={handleUpdate}
        >
          {({ handleSubmit, isValid, values, setFieldValue }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <div>
                  <CommissionAndRemainingTickets
                    setFieldValue={setFieldValue}
                    values={values}
                  />

                  <div className="tw-px-10">
                    <div className="tw-grid tw-grid-cols-3 tw-mt-8">
                      <div>
                        <Field
                          component={TextInput}
                          className="tw-input !tw-border-gray-300"
                          name="organizerMobile"
                          id="organizerMobile"
                          placeholder="Enter Organiser Number"
                          type="tel"
                          label="Enter Organiser Number"
                          required
                        />
                      </div>
                    </div>

                    <div className="tw-flex tw-justify-between tw-gap-8 tw-mt-6">
                      <div>
                        <span>Display Mobile Number</span>
                        <br />
                        <span className="tw-gray-600 tw-text-base">
                          Display this mobile number in event details page and
                          share with customers who bought the ticket
                        </span>
                      </div>
                      <div className="tw-pt-3 tw-px-8">
                        <Switch
                          onChange={(value) =>
                            setFieldValue("displayOrganizerMobile", value)
                          }
                          checked={values.displayOrganizerMobile}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tw-flex tw-gap-3 tw-justify-end tw-my-12">
                  <Button
                    type="button"
                    className="tw-border-none tw-px-8 tw-py-5 tw-rounded-md tw-bg-white tw-shadow-md"
                    title="Save"
                    loadingTitle="Saving..."
                    variant="rounded"
                    onClick={goToPreviousStep}
                    disabled={isLoading || !isValid}
                  >
                    Previous
                  </Button>
                  <Button
                    type="button"
                    className="tw-border-none tw-px-8 tw-py-5 tw-rounded-md tw-bg-secondary-700 tw-text-white"
                    title="Save"
                    loadingTitle="Saving..."
                    variant="rounded"
                    loading={shouldExit && isLoading}
                    onClick={() => saveFormData(handleSubmit)}
                    disabled={isLoading || !isValid}
                  >
                    Save/Exit
                  </Button>
                  <Button
                    type="submit"
                    className="tw-border-none tw-px-8 tw-py-5 tw-rounded-md tw-bg-primary-800 tw-text-white"
                    title="Save"
                    loadingTitle="Saving..."
                    variant="rounded"
                    loading={!shouldExit && isLoading}
                    disabled={isLoading || !isValid}
                  >
                    Next
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </FormContainer>
  );
};

export default FormTicketInformation;
