import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";

const Auth0 = ({ children }) => {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: `${window.location.origin}${process.env.REACT_APP_AUTH0_REDIRECT_PATH}`,
      }}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0;
