import React, { Fragment } from "react";
import ReactSelect from "react-select";
import { cn } from "utilities/cn";

const ThemedSelect = ({
  field,
  form: { touched, errors, setFieldValue } = {},
  label,
  size,
  labelClassName,
  required,
  customControlStyle,
  ...props
}) => {
  const valueContainerPadding = {
    base: "0.25rem 0.25rem",
    lg: "0.475rem 0.25rem",
  };

  const styles = {
    singleValue: (provided) => ({
      ...provided,
      color: "#495057",
      fontWeight: 400,
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "0.875rem",
      fontWeight: 400,
      color: state.isSelected ? "#4b4b4b" : "#4b4b4b",
      backgroundColor: state.isSelected ? "#f4f4f4" : "#ffffff",
      borderRadius: 5,
      boxSizing: "border-box",
      margin: "2px 6px",
      padding: "10px",
      width: "calc(100% - 12px)",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#f4f4f4",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#6c757d",
      fontWeight: 400,
    }),
    menu: (provided) => ({
      fontSize: "0.875rem",
      border: "1px solid rgba(33,33,33,.14)",
      borderBottomRightRadius: 2,
      borderBottomLeftRadius: 2,
      paddingTop: 5,
      boxShadow: "0 0.5px 0.2px rgba(0, 0, 0, .15)",
      boxSizing: "border-box",
      zIndex: 9999,
      ...provided,
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: 0,
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: valueContainerPadding[size] || valueContainerPadding.base,
      margin: 0,
    }),
    control: (provided, state) => ({
      ...provided,
      border: `solid 1px ${state.isFocused ? "#245177" : "#ced4da"};`,
      padding: ".045rem 0.45rem",
      fontSize: "0.875rem",
      fontWeight: 500,
      color: "#245177",
      boxShadow: "none",
      minHeight: "auto",
      cursor: "pointer",
      "&:hover": {
        border: "solid 1px #e4e4e7",
      },
      ...customControlStyle,
    }),
  };

  const hasError = touched?.[field.name] && errors?.[field.name];

  const handleBlur = (e) => {
    e.target.name = field.name;
    field.onBlur?.(e);
  };
  return (
    <Fragment>
      {label ? (
        <label
          htmlFor={field.name}
          className={cn("tw-flex tw-gap-1", labelClassName)}
        >
          {label} {required ? <span className="tw-text-red-500">*</span> : ""}
        </label>
      ) : (
        ""
      )}
      {props.allowSelectAll ? (
        <>
          <ReactSelect
            {...props}
            name={field.name}
            value={field.value}
            onBlur={handleBlur}
            styles={styles}
            options={[props.allOption, ...props.options]}
            onChange={(value) => {
              if (
                value !== null &&
                value.length > 0 &&
                value[value.length - 1].value === props.allOption.value
              ) {
                return setFieldValue(field.name, props.options);
              }
              return setFieldValue(field.name, value);
            }}
          />
        </>
      ) : (
        <>
          <ReactSelect
            name={field.name}
            onChange={(value) => setFieldValue(field.name, value)}
            value={field.value}
            onBlur={handleBlur}
            styles={styles}
            {...props}
          />
        </>
      )}
      {hasError && (
        <div className="text-danger text-sm font-weight-normal pt-1">
          {errors?.[field.name]}
        </div>
      )}
    </Fragment>
  );
};

export default ThemedSelect;
