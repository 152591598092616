import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Fuel } from "services/network";
import { Fuel as FuelV2 } from "services/network-v2";

const initialState = {
  storeLoading: false,
  storeError: null,
  data: {},
};

export const fetchFuelPurchaseByDetailsWithTxnIdRequest = createAsyncThunk(
  "/order/fuel-station",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await FuelV2.fetchFuelPurchasedDetailsWithTrxId(payload.id);
      return response;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const addBankAccountRequest = createAsyncThunk(
  "/bank/details",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await FuelV2.addbankDetail(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchAllBankListRequest = createAsyncThunk(
  "vendor/staff",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await FuelV2.fetchBankList(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchSingleStaffRequest = createAsyncThunk(
  "vendor/staff",
  async ({ staffId, otherParams }, { rejectWithValue }) => {
    try {
      const response = await FuelV2.fetchSignleStaff(staffId, otherParams);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchVendorFuelStationListREquest = createAsyncThunk(
  "fuel/FuelVendorProduct",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await FuelV2.fetchVendorFuelStationList(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchPaginatedVendorsProductRequest = createAsyncThunk(
  "fuel/FuelVendorProduct",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await FuelV2.fetchVendorsProducts(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const registerFuelVendorRequest = createAsyncThunk(
  "/fuel-station/registration",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await FuelV2.registerVendor(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const createFuelStationRequest = createAsyncThunk(
  "/vendor/fuel-station/create",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await FuelV2.createFuelStation(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

//validate fuel vendor
export const validateFuelVendorRequest = createAsyncThunk(
  "/vendor/validate",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await FuelV2.validateVendor(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchCustomersOrderRequest = createAsyncThunk(
  "orders/fuel-station-income/",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await FuelV2.fetchFuelCustomersOrders(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchFuelStationTransactionsRequest = createAsyncThunk(
  "/fuel-station/transactions/list",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await FuelV2.fetchFuelStationTransactions(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getNewFuelVendorStaffsRequest = createAsyncThunk(
  "vendor/stafflist",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await FuelV2.fetchFuelVendorStaffs(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const createNewFuelVendorStaffRequest = createAsyncThunk(
  "vendor/staff/add",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await FuelV2.createFuelVendorStaff(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const createFuelBuyerCustomerRequest = createAsyncThunk(
  "/buyer/create",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await FuelV2.createFuelBuyerCustomer(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchBuyerDashboardRequest = createAsyncThunk(
  "/buyer",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await FuelV2.fetchFuelBuyerCustomerDashboard(payload);

      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

//formaly using this to create vendors
export const createNewFuelVendorRequest = createAsyncThunk(
  "/vendor/create",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await FuelV2.createFuelVendor(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const createFuelBuyerRequest = createAsyncThunk(
  "fuel-station/buy-fuel/",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await FuelV2.createFuelBuyer(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchFuelStationRequest = createAsyncThunk(
  "fuel/fuel-station",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await FuelV2.fetchAllFuelStations(payload);
      return response?.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const createDriverRequest = createAsyncThunk(
  "fuel/createDriver",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await FuelV2.createDriver(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateDriverRequest = createAsyncThunk(
  "fuel/updateDriver",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await FuelV2.updateDriver(payload.id, payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const createVehicleRequest = createAsyncThunk(
  "fuel/createVehicle",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await FuelV2.createVehicle(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateVehicleRequest = createAsyncThunk(
  "fuel/updateVehcile",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await FuelV2.updateVehicle(payload.id, payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchPaginatedDriversRequest = createAsyncThunk(
  "fuel/fetchPaginatedDrivers",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await FuelV2.fetchPaginatedDrivers(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchPaginatedVehiclesRequest = createAsyncThunk(
  "fuel/fetchPaginatedVehicles",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await FuelV2.fetchPaginatedVehicles(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteDriverRequest = createAsyncThunk(
  "fuel/deleteDriver",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await FuelV2.deleteDriver(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchDriverRequest = createAsyncThunk(
  "fuel/fetchDriver",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await FuelV2.fetchDriver(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteVehicleRequest = createAsyncThunk(
  "fuel/deleteVehicle",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await FuelV2.deleteVehicle(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchVehicleRequest = createAsyncThunk(
  "fuel/fetchVehicle",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await FuelV2.fetchVehicle(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAllFuelBuyersRequest = createAsyncThunk(
  "fuel/allFuelBuyers",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.getAllFuelBuyers();
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAllFuelVendorsRequest = createAsyncThunk(
  "fuel/allFuelVendors",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.getAllFuelVendors();
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const createFuelVendorRequest = createAsyncThunk(
  "fuel/createFuelVendor",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.createFuelVendor(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFuelVendorDashboardRequest = createAsyncThunk(
  "fuel/FuelVendorDash",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.FuelVendorDash(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFuelVendorProductsRequest = createAsyncThunk(
  "fuel/FuelVendorProduct",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.FuelVendorProducts(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const createFuelVendorProductRequest = createAsyncThunk(
  "fuel/createFuelVendorProduct",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await FuelV2.createVendorsProduct(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const editFuelVendorProductRequest = createAsyncThunk(
  "fuel/editFuelVendorProduct",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.EditFuelVendorProduct(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteFuelVendorProductRequest = createAsyncThunk(
  "fuel/deleteFuelVendorProduct",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.DeleteFuelVendorProduct(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFuelVendorStaffsRequest = createAsyncThunk(
  "fuel/FuelVendorStaff",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.FuelVendorStaffs(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const createFuelVendorStaffRequest = createAsyncThunk(
  "fuel/createFuelVendorStaff",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.CreateFuelVendorStaff(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const editNewFuelVendorStaffRequest = createAsyncThunk(
  "vendor/staff/edit",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await FuelV2.editFuelVendorStaff(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const editFuelVendorStaffRequest = createAsyncThunk(
  "fuel/editFuelVendorStaff",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.EditFuelVendorStaff(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteFuelVendorStaffRequest = createAsyncThunk(
  "fuel/deleteFuelVendorStaff",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.DeleteFuelVendorStaff(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const resetFuelVendorStaffPinRequest = createAsyncThunk(
  "fuel/resetFuelVendorStaffPin",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.ResetFuelVendorStaffPin(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFuelVendorOrdersRequest = createAsyncThunk(
  "fuel/FuelVendorOrder",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.FuelVendorOrders(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFuelBuyerOrdersRequest = createAsyncThunk(
  "fuel/FuelBuyerOrder",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.FuelBuyerOrders(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFuelBuyerOrderDetailsRequest = createAsyncThunk(
  "fuel/FuelBuyerOrderDetail",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.FuelBuyerOrderDetails(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFuelBuyerDashboardRequest = createAsyncThunk(
  "fuel/FuelBuyerDash",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.FuelBuyerDash(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getBuyerVehiclesRequest = createAsyncThunk(
  "fuel/BuyerVehicle",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.BuyerVehicles(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const createBuyerVehicleRequest = createAsyncThunk(
  "fuel/createBuyerVehicle",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.CreateBuyerVehicle(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const editBuyerVehicleRequest = createAsyncThunk(
  "fuel/editBuyerVehicle",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.EditBuyerVehicle(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteBuyerVehicleRequest = createAsyncThunk(
  "fuel/deleteBuyerVehicle",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.DeleteBuyerVehicle(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getBuyerDriversRequest = createAsyncThunk(
  "fuel/BuyerDriver",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.BuyerDrivers(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const createBuyerDriverRequest = createAsyncThunk(
  "fuel/createBuyerDriver",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.CreateBuyerDriver(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const editBuyerDriverRequest = createAsyncThunk(
  "fuel/editBuyerDriver",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.EditBuyerDriver(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteBuyerDriverRequest = createAsyncThunk(
  "fuel/deleteBuyerDriver",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.DeleteBuyerDriver(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const generateCodeRequest = createAsyncThunk(
  "fuel/generateCode",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.GenerateCode(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAllStationsRequest = createAsyncThunk(
  "fuel/getAllStations",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.GetAllStations(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const sendCodeRequest = createAsyncThunk(
  "fuel/SendCode",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.SendCode(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const renewCodeRequest = createAsyncThunk(
  "fuel/RenewCode",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.RenewCode(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const refundRequest = createAsyncThunk(
  "fuel/Refund",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.Refund(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFuelPayoutTransactionsRequest = createAsyncThunk(
  "fuel/GetFuelPayoutTransactions",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.GetFuelPayoutTransactions(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const LinkBankToFLutterWaveRequest = createAsyncThunk(
  "fuel/LinkBankToFLutterWave",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.LinkBankToFLutterWave(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFuelVendorBankRequest = createAsyncThunk(
  "fuel/getFuelVendorBank",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.getFuelVendorBank(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const requestFuelPayoutRequest = createAsyncThunk(
  "fuel/requestFuelPayout",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.requestFuelPayout(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const unlinkFuelAccountRequest = createAsyncThunk(
  "fuel/unlinkFuelAccount",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.unlinkFuelAccount(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const verifyCardPaymentRequest = createAsyncThunk(
  "users/verifyCardPayment",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.verifyCardPayment(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const initiateCardPaymentRequest = createAsyncThunk(
  "users/initiateCardPayment",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.initiateCardPayment(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

const fuelSlice = createSlice({
  name: "fuel",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getFuelVendorDashboardRequest.fulfilled]: (state, action) => {
      state.data = action.payload.data;
    },
    [getFuelBuyerDashboardRequest.fulfilled]: (state, action) => {
      state.data = action.payload.data;
    },
  },
});

export const { logoutStoreData } = fuelSlice.actions;
export const { deleteCart } = fuelSlice.actions;
export const { updateReduxCartRequest } = fuelSlice.actions;
export default fuelSlice.reducer;
