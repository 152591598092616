import SearchResultEvent from "./Event";
import Loader from "components/shared/Loader";
import { useCombinedEventsPagination } from "hooks/useCombinedEventsPagination";
import React, { useEffect, useRef } from "react";

const SearchResults = ({ search, setShowSearchSuggestions }) => {
  const scrollObserverReference = useRef();

  const {
    data,
    displayMoreData,
    initialLoad,
    loading,
    showLoadMoreButton,
    resetData,
  } = useCombinedEventsPagination({ search });

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      for (const entry of entries) {
        if (entry.isIntersecting && showLoadMoreButton) {
          displayMoreData();
        }
      }
    });

    if (scrollObserverReference.current) {
      observer.observe(scrollObserverReference.current);
    }

    return () => {
      if (scrollObserverReference.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(scrollObserverReference.current);
      }
    };
  }, [displayMoreData, showLoadMoreButton]);

  if (initialLoad) {
    return (
      <div className="tw-w-full tw-h-full tw-p-6 tw-flex tw-justify-center">
        <Loader />
      </div>
    );
  }

  if (data.length === 0) {
    return <div className="tw-text-center tw-p-6">No Event Found.</div>;
  }

  return (
    <>
      {data.map((event, Idx) => (
        <SearchResultEvent
          event={event}
          key={Idx}
          setShowSearchSuggestions={setShowSearchSuggestions}
        />
      ))}
      <div ref={scrollObserverReference}>
        {loading && (
          <div className="tw-w-full tw-h-full tw-p-6 tw-flex tw-justify-center">
            <Loader />
          </div>
        )}
      </div>
    </>
  );
};

export default SearchResults;
