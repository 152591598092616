import {
  EmptyTransactionHistoryIcon,
  EmptyVehicleDataIcon,
} from "assets/icons";
import React from "react";

export const NoTransactionHistory = () => {
  return (
    <div className="tw-mx-auto tw-w-[90%] tw-h-[150px] tw-flex tw-items-center tw-justify-center">
      <EmptyTransactionHistoryIcon />
    </div>
  );
};

export const EmptyVehicleData = () => {
  return (
    <div>
      <center>
        {" "}
        <EmptyVehicleDataIcon />
      </center>
    </div>
  );
};
