import { findCountryByISO2 } from "../lib/country";
import {
  getStorageData,
  removeStorageData,
  StorageKeys,
} from "../lib/storageManager";
import axios from "axios";
import { defaultAddress } from "lib/defaultGoogleLocation";
import { DateTime } from "luxon";
import React, { useState } from "react";

// const locationUrl = "https://www.iplocate.io/api/lookup/";
// const locationUrl = "https://ip-api.com/json";
const locationUrl = "https://ipapi.co/json/";
// const locationUrl = "https://maps.googleapis.com/maps/api/geocode/json";

export const usePosition = () => {
  const [position, setPosition] = useState(0);

  React.useEffect(() => {
    const location = getAndCheckLocation();
    if (location) {
      setPosition(location);
    } else {
      getGeolocationByIP();
    }
  }, []);

  function getAndCheckLocation() {
    const locationData = getStorageData(StorageKeys.Location);
    if (!locationData || !locationData.expiringTime || !locationData.data) {
      return null;
    }

    const now = DateTime.now();
    if (DateTime.fromMillis(locationData.expiringTime) < now) {
      removeStorageData(StorageKeys.Location);
      return null;
    }

    return locationData.data;
  }

  async function getGeolocationByIP() {
    try {
      const response = await axios.get(locationUrl);
      const location = response.data;
      const countryObject = findCountryByISO2(location.country);
      if (location.latitude && location.longitude) {
        setPosition({
          latitude: location.latitude,
          longitude: location.longitude,
          region: `${location.city}, ${countryObject.name}`,
          countryISO3: countryObject.iso3,
          countryLanguage: countryObject.language,
          countryCurrency: countryObject.currency,
          country: countryObject.name,
        });
      } else {
        setLocation(defaultAddress, {
          latitude: 53.7792371,
          longitude: -0.321912,
        });
      }
    } catch (error) {
      setLocation(defaultAddress, {
        latitude: 53.7792371,
        longitude: -0.321912,
      });
    }
  }
  // ******************************************************************************************** //
  // ** This commented out code below is to get the current location using google api directly ** //
  // ******************************************************************************************** //

  // async function getGeolocationByIP() {
  // if (!navigator.geolocation) {
  //   toast.error("Geolocation is not supported by your browser");
  // } else {
  //   navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  // }
  // }

  // const successCallback = async (position) => {
  //   const geolocation = position.coords;
  //   const response = await axios.get(
  //     `${locationUrl}?latlng=${geolocation.latitude},${geolocation.longitude}&key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`
  //   );
  //   const actualPlace = response.data.results;
  //   setLocation(actualPlace, geolocation);
  // };

  // const errorCallback = () => {
  //   toast.error(
  //     "Please allow app to access your current location so you can see events near you, this is likely at the top right corner of your browser",
  //     { autoClose: false }
  //   );
  //   setLocation(defaultAddress, { latitude: 53.7792371, longitude: -0.321912 });
  // };

  const setLocation = (actualPlace, geolocation) => {
    const country = actualPlace[0].address_components.find(
      (compo) => compo.types[0] === "country"
    );
    const countryObject = findCountryByISO2(country.short_name);

    actualPlace.every((element) => {
      const city = element.address_components.find(
        (compo) =>
          compo.types[0] === "locality" || compo.types[0] === "postal_town"
      );
      if (city) {
        setPosition({
          latitude: geolocation.latitude,
          longitude: geolocation.longitude,
          region: `${city.long_name}, ${countryObject.name}`,
          countryISO3: countryObject.iso3,
          countryLanguage: countryObject.language,
          countryCurrency: countryObject.currency,
          country: countryObject.name,
        });
        return false;
      }
    });
  };

  return {
    position,
  };
};
