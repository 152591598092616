import { useAuth0 } from "@auth0/auth0-react";
import { Icon } from "@iconify/react";
import produce from "immer";
import queryString from "query-string";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { loginSocialAuthRequest } from "state/slices/account";
import { getOneLinerErrorMessage } from "utilities";

const SocialAuth = ({ successCallback, className, setState }) => {
  const {
    loginWithRedirect,
    isAuthenticated,
    getAccessTokenSilently,
    getIdTokenClaims,
    logout,
  } = useAuth0();

  const dispatch = useDispatch();

  const { code, state: auth0State } = queryString.parse(useLocation().search);

  const handleSocialAuthLogin = useCallback(
    async (socialAuthToken) => {
      setState(produce((draft) => void (draft.isLoginLoading = true)));

      const resultAction = await dispatch(
        loginSocialAuthRequest({ token: socialAuthToken })
      );

      if (loginSocialAuthRequest.fulfilled.match(resultAction)) {
        setState(produce((draft) => void (draft.isLoginLoading = false)));
        localStorage.setItem(
          "token",
          resultAction.payload.data?.tokens?.accessToken
        );
        successCallback?.();
      } else {
        setState(produce((draft) => void (draft.isLoginLoading = false)));

        toast.error(
          resultAction.payload?.message ||
            getOneLinerErrorMessage(resultAction.payload) ||
            resultAction.error?.message ||
            "An error occurred, please try again."
        );
      }
    },
    [dispatch]
  );

  useEffect(() => {
    try {
      const fetchAccessToken = async () => {
        const response = await getIdTokenClaims();
        return handleSocialAuthLogin(response?.__raw);
      };
      if (isAuthenticated && auth0State && code) {
        setState(produce((draft) => void (draft.isLoginLoading = true)));
        fetchAccessToken();
      }
    } catch (e) {}
  }, [
    auth0State,
    code,
    getAccessTokenSilently,
    getIdTokenClaims,
    handleSocialAuthLogin,
    isAuthenticated,
  ]);

  return (
    <div className={className}>
      <button
        className="btnGoogle"
        type="button"
        onClick={() =>
          loginWithRedirect({
            authorizationParams: {
              connection: "google-oauth2",
            },
          })
        }
      >
        <Icon icon="flat-color-icons:google" className="btnIcon" height={25} />
      </button>
      <button
        className="btnFB"
        type="button"
        onClick={() =>
          loginWithRedirect({
            authorizationParams: {
              connection: "facebook",
            },
          })
        }
      >
        <Icon icon="logos:facebook" className="btnIcon" height={25} />
      </button>
      <button
        className="btnApple"
        type="button"
        onClick={() =>
          loginWithRedirect({
            authorizationParams: {
              connection: "apple",
            },
          })
        }
      >
        <Icon icon="devicon:apple" className="btnIcon" height={25} />
      </button>
    </div>
  );
};

export default SocialAuth;
