import FormContainer from "../../../FormContainer";
import EventFormVideo from "./Video";
import { VideoUploadIllustration } from "assets/images";
import { Button } from "components/shared/Button";
import ImageUpload from "components/shared/forms/ImageUpload";
import TextInput from "components/shared/forms/TextInput";
import { Field } from "formik";
import React from "react";

const PromotionalVideosForm = ({ video, addVideo, removeVideo }) => {
  return (
    <FormContainer>
      <div className="tw-mt-4 tw-flex md:tw-flex-row tw-flex-col tw-justify-between tw-items-start tw-gap-4">
        <div className="lg:tw-min-w-[500px] tw-w-full md:tw-max-w-[500px]">
          <p className="tw-text-base tw-font-medium">
            Add Promotional Video
            <br />
            <span className="tw-font-normal">
              Maximum size allowed is 2MB only
            </span>
          </p>

          <div className="tw-rounded-md tw-border-none">
            {!video?.url ? (
              <div className="tw-border tw-border-dashed tw-rounded-lg tw-bg-[rgba(32,80,118,0.2)] tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-6 tw-p-10">
                <img src={VideoUploadIllustration} />

                <p>Browse your photos to start uploading.</p>

                <ImageUpload
                  addNewImages={addVideo}
                  singleImageUrl={video?.url}
                  isSingle
                  maxFiles={1}
                >
                  <Button
                    className="tw-bg-secondary-800 tw-text-white"
                    type="button"
                  >
                    Browse File
                  </Button>
                </ImageUpload>
              </div>
            ) : (
              <EventFormVideo
                video={video}
                canBeRemoved
                removeVideo={removeVideo}
              />
            )}
          </div>
        </div>

        <div className="tw-w-full md:tw-w-0 md:tw-h-72 tw-border tw-relative tw-my-8 md:tw-mt-20">
          <div className="tw-absolute tw-bg-white tw--top-[20px] md:tw-top-[40%] tw-p-3 tw-left-[40%] md:tw--left-[20px]">
            OR
          </div>
        </div>

        <div className="tw-w-full md:tw-w-1/3 tw-mt-6">
          <Field
            component={TextInput}
            className="tw-input !tw-border-gray-300"
            name="youtubeUrl"
            id="youtubeUrl"
            placeholder="http://"
            type="text"
            label={
              <div>
                <span>Event Video Link</span>
                <br />
                <span className="tw-text-sm">
                  [Paste your youtube embed video link]
                </span>
              </div>
            }
            labelClassName="tw-ml-4"
          />
        </div>
      </div>
    </FormContainer>
  );
};

export default PromotionalVideosForm;
