import "./styles.scss";
import AllRestaurantsBanner from "assets/images/AllRestaurants.png";
import RestaurantCard from "components/shared/RestaurantCard";
import Footer from "components/shared/partials/Footer";
import MainHeader from "components/shared/partials/MainHeader";
import SearchAndNavbar from "components/shared/partials/MainHeader/components/SearchAndNavbar";
import React from "react";

const AllRestaurantsPage = () => {
  const restaurantData = [
    { id: 1, name: "Alacarte Special", address: "Ikeja, Lagos", rating: 4.5 },
    { id: 2, name: "Alacarte Special", address: "Ikeja, Lagos", rating: 4.2 },
    { id: 3, name: "Alacarte Special", address: "Ikeja, Lagos", rating: 4.7 },
    { id: 4, name: "Alacarte Special", address: "Ikeja, Lagos", rating: 4.1 },
    { id: 5, name: "Alacarte Special", address: "Ikeja, Lagos", rating: 4.3 },
    { id: 6, name: "Alacarte Special", address: "Ikeja, Lagos", rating: 4.8 },
    { id: 7, name: "Alacarte Special", address: "Ikeja, Lagos", rating: 4.8 },
    { id: 8, name: "Alacarte Special", address: "Ikeja, Lagos", rating: 4.7 },
    { id: 9, name: "Alacarte Special", address: "Ikeja, Lagos", rating: 4.0 },
    // Add more card data as needed
  ];
  return (
    <div className="allRestMainHeader">
      <MainHeader />
      <div
        style={{
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%), lightgray 50% / center center no-repeat`,
          backgroundImage: `url(${AllRestaurantsBanner})`,
          backgroundSize: "cover",
        }}
        className="allRest"
      >
        <div className="allRestHead">
          <h2>
            Discover All Restaurants within <br /> your city
          </h2>
          <div className="allRestForm">
            <div className="allRestFormDiv">
              <input
                type="search"
                name=""
                id=""
                placeholder="Enter Restaurant Name, city"
              />
            </div>
            <button>SEARCH</button>
          </div>
        </div>
      </div>
      <section className="restCardSec">
        {restaurantData.map((restaurant) => (
          <RestaurantCard
            key={restaurant.id}
            name={restaurant.name}
            address={restaurant.address}
            rating={restaurant.rating}
          />
        ))}
      </section>
      <Footer />
    </div>
  );
};

export default AllRestaurantsPage;
