import { HamburgerIcon } from "assets/icons";
import clsx from "clsx";
import React from "react";
import { cn } from "utilities/cn";

const Hamburger = ({ className, showSideBar, toggleSideBar }) => {
  return (
    <button
      onClick={toggleSideBar}
      className={cn("lg:tw-hidden tw-border-none", className)}
    >
      {!showSideBar ? (
        <HamburgerIcon className="tw-w-8 tw-h-8 md:tw-w-10 md:tw-h-10" />
      ) : (
        <svg
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            y="17.8828"
            width="25.2892"
            height="1.58058"
            transform="rotate(-45 0 17.8828)"
            fill="currentColor"
          />
          <rect
            x="1.11768"
            width="25.2892"
            height="1.58058"
            transform="rotate(45 1.11768 0)"
            fill="currentColor"
          />
        </svg>
      )}
    </button>
  );
};

export default Hamburger;
