import FuelBuyerDashboardPageTitle from "../../../components/PageTitle";
import { BackArrowIcon } from "assets/icons";
import { Button } from "components/shared/Button";
import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const FuelBuyerOrderPage = () => {
  const history = useHistory();

  const details = [
    { title: "driver name", value: "john doe" },
    { title: "status", value: "NOT USED" },
    { title: "vehicle details", value: "toyota camry" },
    { title: "driver name", value: "john doe" },
    { title: "date & time of purchase", value: "23 dec 2020" },
    { title: "fuel type", value: "diesel" },
    { title: "amount paid", value: "NGN 200.00" },
    { title: "no of litres/unit", value: "NGN 200.00" },
    { title: "code validity", value: "30min" },
    { title: "transaction number", value: "AHGF988323" },
  ];

  return (
    <div>
      <div className="tw-flex tw-justify-between tw-items-center">
        <FuelBuyerDashboardPageTitle title="Order" />

        <div
          className="tw-flex tw-gap-2 cursor-pointer"
          onClick={() => history.goBack()}
        >
          <BackArrowIcon />

          <span>Back</span>
        </div>
      </div>

      <div className="tw-max-w-xl tw-rounded-md tw-shadow-lg tw-my-12">
        <div className="tw-bg-green-500 tw-text-white text-center py-3">
          PURCHASE CODE: AAAAADDDDFFF
        </div>
        <div className="tw-flex tw-flex-wrap tw-px-4">
          {details.map((detail, index) => (
            <div
              className="tw-w-1/2 tw-py-4 tw-border-b text-center"
              key={index}
            >
              <span className="tw-text-sm tw-text-gray-400 tw-uppercase">
                {detail?.title}
              </span>
              <p className="tw-block tw-my-0">{detail?.value}</p>
            </div>
          ))}
        </div>
      </div>

      <p>
        If driver did not recieved the Purchase code Send again to registered
        mobile number
      </p>

      <Button className="tw-bg-secondary-800 tw-text-white">RESEND CODE</Button>
    </div>
  );
};

export default FuelBuyerOrderPage;
