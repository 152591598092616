import FormContainer from "../../FormContainer";
import { useEventFormContext } from "../../context";
import AddressForm from "./AddressForm";
import { capitalizeAddressValues } from "./AddressForm/functions";
import FormCategoryInput from "./CategoryInput";
import EventDates from "./EventDates";
import RecurringDates from "./RecurringDates";
import {
  CURRENCY_LIST,
  EVENT_TYPES,
  SELECT_CUSTOM_CONTROL_STYLES,
  SELECT_FORM_KEYS,
  TIMEZONE_LIST,
  initialValues,
  validationSchema,
} from "./constants";
import { unwrapResult } from "@reduxjs/toolkit";
import { Button } from "components/shared/Button";
import TextInput from "components/shared/forms/TextInput";
import ThemedSelect from "components/shared/forms/ThemedSelect";
import { Field, Form, Formik } from "formik";
import _ from "lodash";
import { DateTime } from "luxon";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  createDraftEventRequest,
  updateEventRequest,
} from "state/slices/events";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import {
  convertArrayToSelectOptions,
  convertModelArrayToSelectOptions,
  convertValueToSelectOption,
  getAPIErrorsForFormik,
  getOneLinerErrorMessage,
  transformSelectObjects,
} from "utilities";
import { cn } from "utilities/cn";

const FormEventDetails = ({ successCallback }) => {
  const { exitFunction, event, setEvent, asEdit, asResellTicket } =
    useEventFormContext();

  const editorRef = useRef();

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const [shouldExit, setShouldExit] = useState(false);

  const transformRequestPayload = (formValues) => {
    const transformedValues = {
      ...formValues,
      ...transformSelectObjects(formValues, SELECT_FORM_KEYS),
    };
    return {
      ...transformedValues,
      ...capitalizeAddressValues(transformedValues),
      dates: transformedValues.dates.map((date) => ({
        startDate: DateTime.fromJSDate(date.startDate)
          .set({
            hour: DateTime.fromJSDate(date.startTime).toFormat("H"),
            minute: DateTime.fromJSDate(date.startTime).toFormat("mm"),
          })
          .toISO(),
        endDate: DateTime.fromJSDate(date.endDate)
          .set({
            hour: DateTime.fromJSDate(date.endTime).toFormat("H"),
            minute: DateTime.fromJSDate(date.endTime).toFormat("mm"),
          })
          .toISO(),
      })),
    };
  };

  const handleCreate = async (formValues, formikHelpers) => {
    if (asResellTicket && !Number(formValues.canResell)) {
      setShouldExit(false);
      toast.error(
        "Please confirm that you have authorization to resell this ticket before proceeding"
      );
      return;
    }

    const payload = transformRequestPayload(formValues);

    setIsLoading(true);

    const resultAction = await dispatch(createDraftEventRequest(payload));
    if (createDraftEventRequest.fulfilled.match(resultAction)) {
      const response = unwrapResult(resultAction);
      setIsLoading(false);
      toast.success("Successfully saved event");
      setEvent(response.data);
      shouldExit ? exitFunction() : successCallback?.();
    } else {
      setIsLoading(false);
      if (resultAction.payload.message) {
        toast.error(resultAction.payload.message);
      } else {
        formikHelpers.setErrors(getAPIErrorsForFormik(resultAction.payload));
        toast.error(
          getOneLinerErrorMessage(resultAction.payload) ||
            resultAction.error?.message ||
            "An error occurred, please try again."
        );
      }
    }
  };

  const handleUpdate = async (formValues, formikHelpers) => {
    const payload = {
      eventId: event.id,
      data: { ...transformRequestPayload(formValues) },
    };

    setIsLoading(true);

    const resultAction = await dispatch(updateEventRequest(payload));
    if (updateEventRequest.fulfilled.match(resultAction)) {
      const response = unwrapResult(resultAction);
      setIsLoading(false);
      toast.success("Successfully saved event");
      setEvent(response.data);

      shouldExit ? exitFunction() : successCallback?.();
    } else {
      setIsLoading(false);
      if (resultAction.payload.message) {
        toast.error(resultAction.payload.message);
      } else {
        formikHelpers.setErrors(getAPIErrorsForFormik(resultAction.payload));
        toast.error(
          getOneLinerErrorMessage(resultAction.payload) ||
            resultAction.error?.message ||
            "An error occurred, please try again."
        );
      }
    }
  };

  const saveFormData = (submitForm) => {
    setShouldExit(true);
    submitForm?.();
  };
  useEffect(() => {
    if (!isLoading) setShouldExit(false);
  }, [isLoading]);

  const formInitialValues = useMemo(() => {
    if (!event.id) return {};

    const newEvent = {
      ...event,
      ...event.venueDetails,
      ..._.omit(
        {
          ...event.recurringDetails,
          recurringFrequency: event.recurringDetails?.frequency,
          endDateOfRecurring: new Date(event.recurringDetails?.endDate),
        },
        ["frequency", "endDate"]
      ),
      category: event.category?.name,
      dates: event.dates?.map(({ startDate, endDate }) => ({
        startDate: new Date(startDate),
        startTime: new Date(startDate),
        endDate: new Date(endDate),
        endTime: new Date(endDate),
      })),
    };

    SELECT_FORM_KEYS.forEach((key) => {
      newEvent[key] = convertValueToSelectOption(newEvent[key]);
    });

    return _.omit(newEvent, ["ticketSettings"]);
  }, [event]);

  return (
    <FormContainer>
      <Formik
        enableReinitialize={true}
        initialValues={asEdit ? formInitialValues : initialValues}
        validationSchema={validationSchema}
        onSubmit={asEdit ? handleUpdate : handleCreate}
      >
        {({ handleSubmit, isValid, values, setFieldValue }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div className="tw-flex tw-flex-col tw-gap-6">
                <div>
                  <Field
                    component={TextInput}
                    className="tw-input !tw-border-gray-300"
                    name="title"
                    id="title"
                    placeholder="Enter Event Title"
                    type="text"
                    label="Event Title"
                    labelClassName="tw-ml-4"
                    required
                  />
                </div>

                <div>
                  <label className="font-weight-normal tw-ml-4">
                    Description <span className="text-red">*</span>
                  </label>
                  <SunEditor
                    disableToolbar={true}
                    placeholder="Please type here..."
                    height="300"
                    onChange={(value) => setFieldValue("description", value)}
                    defaultValue={values.description}
                    setContents={values.description}
                    ref={editorRef}
                    setDefaultStyle="font-size: 14px; font-weight:400"
                  />
                </div>

                <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-10">
                  <div>
                    <FormCategoryInput />
                  </div>

                  <div>
                    <Field
                      component={ThemedSelect}
                      name="type"
                      id="type"
                      size="sm"
                      placeholder="Public or Private Event?"
                      options={convertArrayToSelectOptions(EVENT_TYPES)}
                      required
                      label="Public or Private Event?"
                      labelClassName="tw-ml-4"
                      customControlStyle={SELECT_CUSTOM_CONTROL_STYLES}
                    />
                  </div>
                </div>

                <div>
                  <Field
                    component={TextInput}
                    className="tw-input !tw-border-gray-300"
                    name="venueName"
                    id="venueName"
                    placeholder="Enter Venue Name"
                    type="text"
                    label="Event Venue Name"
                    labelClassName="tw-ml-4"
                    required
                  />
                </div>

                <div>
                  <AddressForm
                    setFieldValue={setFieldValue}
                    event={formInitialValues}
                  />
                </div>

                <div className="tw-grid tw-grid-cols-2 tw-gap-10">
                  {/* <div>
                    <Field
                      component={ThemedSelect}
                      name="currency"
                      id="currency"
                      size="sm"
                      placeholder="Select Currency"
                      options={convertModelArrayToSelectOptions(
                        CURRENCY_LIST,
                        "id",
                        "label",
                        true
                      )}
                      required
                      label="Currency"
                      labelClassName="tw-ml-4"
                      customControlStyle={SELECT_CUSTOM_CONTROL_STYLES}
                      disabled
                    />
                  </div> */}

                  <div>
                    <Field
                      component={ThemedSelect}
                      name="timezone"
                      id="timezone"
                      size="sm"
                      placeholder="Select Timezone"
                      options={TIMEZONE_LIST}
                      required
                      label="Timezone"
                      labelClassName="tw-ml-4"
                      customControlStyle={SELECT_CUSTOM_CONTROL_STYLES}
                    />
                  </div>
                </div>

                <div>
                  <EventDates values={values} setFieldValue={setFieldValue} />
                </div>

                <div>
                  <RecurringDates
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                </div>

                {asResellTicket && (
                  <div>
                    <p>Are you authorised to sell this ticket?</p>
                    <div className="tw-mb-6 tw-flex tw-gap-16">
                      <div className="tw-flex tw-items-center tw-gap-1">
                        <input
                          type="radio"
                          id="yes"
                          name="type"
                          value={1}
                          checked={values.type === 1}
                          onChange={(e) =>
                            setFieldValue("canResell", e.currentTarget.value)
                          }
                          className="tw-radio !tw-w-4.5 !tw-h-4.5"
                        />
                        <label htmlFor="yes" className="tw-pt-1.5 tw-ml-2">
                          Yes
                        </label>
                      </div>
                      <div className="tw-flex tw-items-center tw-gap-1">
                        <input
                          size="5"
                          type="radio"
                          id="no"
                          name="type"
                          value={0}
                          checked={values.canResell === 0}
                          onChange={(e) =>
                            setFieldValue("canResell", e.currentTarget.value)
                          }
                          className={cn(
                            "radio form-control tw-radio !tw-w-4.5 !tw-h-4.5",
                            values.canResell === 0 &&
                              "!tw-ring-2 !tw-ring-secondary-500 !tw-border-4"
                          )}
                        />
                        <label htmlFor="no" className="tw-pt-1.5 tw-ml-2">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="tw-flex tw-gap-3 tw-justify-end tw-my-6">
                <Button
                  type="button"
                  className="tw-border-none tw-px-8 tw-py-5 tw-rounded-md tw-bg-secondary-700 tw-text-white"
                  title="Save"
                  loadingTitle="Saving..."
                  variant="rounded"
                  loading={shouldExit && isLoading}
                  onClick={() => saveFormData(handleSubmit)}
                  disabled={isLoading || !isValid}
                >
                  Save/Exit
                </Button>
                <Button
                  type="submit"
                  className="tw-border-none tw-px-8 tw-py-5 tw-rounded-md tw-bg-primary-800 tw-text-white"
                  title="Save"
                  loadingTitle="Saving..."
                  variant="rounded"
                  loading={!shouldExit && isLoading}
                  disabled={isLoading || !isValid}
                >
                  Next
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </FormContainer>
  );
};

export default FormEventDetails;
