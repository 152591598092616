import "./styles.scss";
import React from "react";

const CreateEventDash = () => {
  return (
    <>
      <div className="mt-2 ml-1 createEventDash">
        <p>Create and manage your events and start earning at Triple7</p>
        <button>Create New Event</button>
      </div>
    </>
  );
};

export default CreateEventDash;
