import FormContainer from "../../../FormContainer";
import Switch from "components/shared/Switch";
import React from "react";

const RefundPolicyForm = ({ setFieldValue, values }) => {
  const setRefundPolicy = (value, checked) => {
    return setFieldValue("refundPolicy", value);
  };

  return (
    <FormContainer>
      <p className="tw-font-medium">Refund Policy</p>

      <div className="tw-border tw-rounded-md tw-py-16 tw-px-4 md:tw-px-10 sm:tw--mx-2 md:tw--mx-8">
        <div className="tw-flex tw-flex-col tw-gap-12 tw-max-w-3xl">
          <div className="tw-flex tw-justify-between tw-gap-8">
            <div>
              <span>Flexible</span>
              <br />
              <span className="tw-text-gray-600 tw-text-base">
                Refundable until 24 hours before the Event
              </span>
            </div>
            <div className="tw-pt-3 tw-px-8">
              <Switch
                onChange={(checked) => setRefundPolicy("Flexible", checked)}
                checked={values.refundPolicy === "Flexible"}
              />
            </div>
          </div>

          <div className="tw-flex tw-justify-between tw-gap-8">
            <div>
              <span>Moderate</span>
              <br />
              <span className="tw-text-gray-600 tw-text-base">
                Refundable within 1 week of ticket purchase
              </span>
            </div>
            <div className="tw-pt-3 tw-px-8">
              <Switch
                onChange={(checked) => setRefundPolicy("Moderate", checked)}
                checked={values.refundPolicy === "Moderate"}
              />
            </div>
          </div>

          <div className="tw-flex tw-justify-between tw-gap-8">
            <div>
              <span>Strict</span>
              <br />
              <span className="tw-text-gray-600 tw-text-base">
                Non Refundable
              </span>
            </div>
            <div className="tw-pt-3 tw-px-8">
              <Switch
                onChange={(checked) => setRefundPolicy("Strict", checked)}
                checked={values.refundPolicy === "Strict"}
              />
            </div>
          </div>
        </div>
      </div>
    </FormContainer>
  );
};

export default RefundPolicyForm;
