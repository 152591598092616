import "./styles.scss";
import offline from "assets/images/Offline.gif";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router";

const OfflinePage = () => {
  const history = useHistory();
  const location = useLocation();
  const { from } = location.state || {
    from: { pathname: "/" },
  };
  const online = navigator.onLine;

  const retry = () => {
    history.push(from);
  };

  useEffect(() => {
    if (online) {
      retry();
    }
  }, [online]);

  return (
    <div className="notFound_page">
      <img src={offline} alt="" />
      <p>
        Oops...!!! It seems you are not connected to the internet. Please <br />
        check your connection and try again
      </p>
      <div className="d-flex justify-content-center">
        <button className="btn btn-green btn-lg px-5" onClick={retry}>
          Retry
        </button>
      </div>
    </div>
  );
};

export default OfflinePage;
