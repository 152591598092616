import "./styles.scss";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";

export const EventDashboardSidebar = ({ isOpen, toggleSidebar }) => {
  const history = useHistory();
  const [showSubLink1, setShowSubLink1] = useState(false);
  const [showSubLink2, setShowSubLink2] = useState(false);
  const [showSubLink3, setShowSubLink3] = useState(false);
  const params = useParams();
  const { id: eventId, eventName } = params;

  if (!eventId) {
    history.push("/dashboard/booked");
  }

  const { isResell, isAfrica } = useSelector(
    (state) => state.events.eventDashboardData
  );

  return (
    <div className="event-dash-sidebar-wrapper">
      <div
        onClick={toggleSidebar}
        className={
          isOpen ? "sidebar-overlay overlay-isOpen" : "sidebar-overlay"
        }
      >
        <div className="closeMenu">
          <Icon icon="ci:close-big" />
        </div>
      </div>
      <div
        id="sidebar-wrapper"
        className={isOpen ? "sidebar-toggle active" : "sidebar-toggle"}
      >
        <div className="sidebar-nav">
          <div className="space-out">
            <div>
              <ul className="pl-0 text-left">
                <li className="bg-gray-70">
                  <NavLink
                    to="/dashboard/booked"
                    className="font-weight-bold pl-3 text-gray-300"
                  >
                    <FontAwesomeIcon icon={faAngleLeft} className="mr-2" />
                    Back To Dashboard
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="active"
                    to={`/dashboard/event/${eventId}/${eventName}/dash`}
                    className="pl-3"
                    onClick={toggleSidebar}
                  >
                    Event Dashboard
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="active"
                    to={`/dashboard/event/${eventId}/${eventName}/edit`}
                    className="pl-3"
                    onClick={toggleSidebar}
                  >
                    Edit Event
                  </NavLink>
                </li>
                {isResell && (
                  <li>
                    <NavLink
                      activeClassName="active"
                      to={`/dashboard/event/${eventId}/${eventName}/upload-ticket`}
                      className="pl-3"
                      onClick={toggleSidebar}
                    >
                      Upload Ticket
                    </NavLink>
                  </li>
                )}
                {!isAfrica && (
                  <li>
                    <NavLink
                      activeClassName="active"
                      to={`/dashboard/event/${eventId}/${eventName}/payment-gateway`}
                      className="pl-3"
                      onClick={toggleSidebar}
                    >
                      Payment Gateway
                    </NavLink>
                  </li>
                )}
                <li>
                  <NavLink
                    activeClassName="active"
                    to={`/dashboard/event/${eventId}/${eventName}/reminders`}
                    className="pl-3"
                    onClick={toggleSidebar}
                  >
                    Reminders
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    activeClassName="active"
                    to={`/dashboard/event/${eventId}/${eventName}/step-out`}
                    className="pl-3"
                    onClick={toggleSidebar}
                  >
                    Stepout
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="active"
                    to={`/dashboard/event/${eventId}/${eventName}/reachout`}
                    className="pl-3"
                    onClick={toggleSidebar}
                  >
                    Reachout
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="active"
                    to={`/dashboard/event/${eventId}/${eventName}/discount`}
                    className="pl-3"
                    onClick={toggleSidebar}
                  >
                    Discount
                  </NavLink>
                </li>
                <li>
                  <div
                    onClick={() => {
                      setShowSubLink2(false);
                      setShowSubLink3(false);
                      setShowSubLink1(!showSubLink1);
                    }}
                    className="pl-3 parent-link"
                  >
                    Reports
                  </div>
                  <div className={`sub-links ${showSubLink1 ? "show" : ""}`}>
                    <NavLink
                      activeClassName="active"
                      to={`/dashboard/event/${eventId}/${eventName}/reporting`}
                      className="pl-3"
                      onClick={toggleSidebar}
                    >
                      Payout
                    </NavLink>

                    <NavLink
                      activeClassName="active"
                      to={`/dashboard/event/${eventId}/${eventName}/analysis`}
                      className="pl-3"
                      onClick={toggleSidebar}
                    >
                      Analysis
                    </NavLink>
                  </div>
                </li>
                <li>
                  <NavLink
                    activeClassName="active"
                    to={`/dashboard/event/${eventId}/${eventName}/orders`}
                    className="pl-3"
                    onClick={toggleSidebar}
                  >
                    Orders
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink
                    activeClassName="active"
                    to={`/dashboard/event/${eventId}/${eventName}/affiliate`}
                    className="pl-3"
                      onClick={toggleSidebar}
                  >
                    Affiliate Program
                  </NavLink>
                </li> */}

                <li>
                  <div
                    onClick={() => {
                      setShowSubLink1(false);
                      setShowSubLink3(false);
                      setShowSubLink2(!showSubLink2);
                    }}
                    className="pl-3 parent-link"
                  >
                    Booking Information
                  </div>
                  <div className={`sub-links ${showSubLink2 ? "show" : ""}`}>
                    <NavLink
                      activeClassName="active"
                      to={`/dashboard/event/${eventId}/${eventName}/booking-info`}
                      className="pl-3"
                      onClick={toggleSidebar}
                    >
                      Booking Information
                    </NavLink>

                    <NavLink
                      activeClassName="active"
                      to={`/dashboard/event/${eventId}/${eventName}/booking-info-list`}
                      className="pl-3"
                      onClick={toggleSidebar}
                    >
                      Booking Information Answers
                    </NavLink>
                  </div>
                </li>

                <li>
                  <div
                    onClick={() => {
                      setShowSubLink1(false);
                      setShowSubLink2(false);
                      setShowSubLink3(!showSubLink3);
                    }}
                    className="pl-3 parent-link"
                  >
                    Manage Attendee
                  </div>
                  <div className={`sub-links ${showSubLink3 ? "show" : ""}`}>
                    <NavLink
                      activeClassName="active"
                      to={`/dashboard/event/${eventId}/${eventName}/manage-attendees`}
                      className="pl-3"
                      onClick={toggleSidebar}
                    >
                      Check-in Attendees List
                    </NavLink>

                    <NavLink
                      activeClassName="active"
                      to={`/dashboard/event/${eventId}/${eventName}/manage-vip`}
                      className="pl-3"
                      onClick={toggleSidebar}
                    >
                      VIP & Guest List
                    </NavLink>
                  </div>
                </li>
                <li>
                  <NavLink
                    activeClassName="active"
                    to={`/dashboard/event/${eventId}/${eventName}/thank-you-video`}
                    className="pl-3"
                    onClick={toggleSidebar}
                  >
                    Add Thank you Video
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
