import { Logo } from "assets/images";
import LottieSuccess from "assets/lottie/success.json";
import Loader from "components/shared/Loader";
import React, { useCallback, useEffect, useState } from "react";
import Lottie from "react-lottie";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { verifyEmailRequest } from "state/slices/account";
import { getOneLinerErrorMessage, useQuery } from "utilities";

const REDIRECT_TIMER_IN_SECONDS = 1500;

const VerifyEmail = ({ history }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const query = useQuery();

  const handleVerifyEmail = useCallback(async () => {
    const payload = {
      token: query.get("token") || "",
    };
    const resultAction = await dispatch(verifyEmailRequest(payload));

    if (verifyEmailRequest.fulfilled.match(resultAction)) {
      setIsLoading(false);
      setTimeout(() => {
        history.push("/");
      }, REDIRECT_TIMER_IN_SECONDS);
    } else {
      setIsLoading(false);
      toast.error(
        resultAction.payload.message ||
          getOneLinerErrorMessage(resultAction.payload) ||
          resultAction.error?.message ||
          "An error occurred, please try again."
      );
      history.push("/");
    }
  }, [dispatch, history, query]);

  useEffect(() => {
    handleVerifyEmail();
  }, [handleVerifyEmail]);

  return (
    <div className="tw-flex tw-w-full tw-justify-center tw-mt-12 md:tw-mt-16">
      <div className="tw-flex tw-flex-col tw-items-center">
        <Link to="/">
          <Logo className="tw-w-48" />
        </Link>

        {isLoading ? (
          <div className="tw-mt-48">
            <Loader title="Verifying email..." />
          </div>
        ) : (
          <>
            <Lottie
              options={{
                loop: false,
                autoplay: true,
                animationData: LottieSuccess,
              }}
              height={200}
              width={200}
            />
            <p className="tw-text-center">
              <span className="tw-font-semibold tw-text-xl">
                Successfully Verified Email! 🎉
              </span>{" "}
              <br /> Redirecting...
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default withRouter(VerifyEmail);
