import CustomToggle from "./CustomToggle";
import { faLessThanEqual } from "@fortawesome/free-solid-svg-icons";
import { ChevronRightIcon } from "assets/icons";
import PlaceSuggestionWrapper from "components/shared/placeSuggestionWrapper";
import { findIso2ByISO3 } from "lib/country";
import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import ReactCountryFlag from "react-country-flag";
import Geocode, { setApiKey } from "react-geocode";
import { useDispatch, useSelector } from "react-redux";
import { setLocationParameters } from "state/slices/location";
import { cn } from "utilities/cn";

const LocationSelector = ({ displayCountryName = true }) => {
  const location = useSelector((state) => state.location);

  const [searchQuery, setSearchQuery] = useState("");

  const [selectedLocation, setSelectedLocation] = useState(
    location.region || ""
  );

  const dispatch = useDispatch();

  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    setSearchQuery("");

    setSelectedLocation(location.region);
  }, [location.region]);

  const updateLocation = (payload) => {
    setShowDropdown(false);

    dispatch(setLocationParameters(payload));
  };

  const toggleDropdown = () => {
    setShowDropdown((oldValue) => !oldValue);
  };

  const innerRef = useRef();
  useEffect(() => innerRef.current && innerRef.current.focus());

  return (
    <div>
      <Dropdown
        className={cn(
          "tw-mx-2 tw-rounded-md tw-py-1.5 tw-px-3 tw-pr-6 tw-w-64 tw-max-w-[80px]",
          displayCountryName && "lg:tw-max-w-[190px] md:tw-shadow-md"
        )}
        show={showDropdown}
        onClick={toggleDropdown}
        onBlur={() => setShowDropdown(false)}
        autoFocus
      >
        <Dropdown.Toggle
          as={CustomToggle}
          variant="success"
          id="dropdown-autoclose-true"
        >
          <div className="tw-rounded-none tw-w-full tw-flex tw-justify-between tw-items-center tw-cursor-pointer tw-max-w-100">
            <div className="tw-flex tw-gap-2 tw-items-center tw-max-w-full tw-pr-1">
              <ReactCountryFlag
                countryCode={findIso2ByISO3(location.countryISO3) || ""}
                svg
                style={{
                  fontSize: "29px",
                  borderRadius: "100%",
                }}
              />
              {displayCountryName && (
                <span className="tw-truncate tw-text-sm tw-hidden lg:tw-block">
                  {/* {searchQuery} */}
                  {selectedLocation}
                </span>
              )}
            </div>

            <div>
              <ChevronRightIcon className="tw-rotate-90 tw-w-3 tw-h-3" />
            </div>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu style={{ marginTop: "10px", marginLeft: "-11px" }}>
          <Dropdown.Item as="div" style={{ width: "100%" }} autoFocus>
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
              className="sm:tw-w-[400px] tw-w-screen"
            >
              <PlaceSuggestionWrapper
                inputRef={innerRef}
                value={searchQuery}
                onChange={setSearchQuery}
                onSelect={updateLocation}
                inputClassName="text-capitalize !tw-py-1 !tw-border-gray-300"
                className="tw-w-full !tw-ml-0 !tw-px-0 tw-text-base"
                showIcon
              />
            </div>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default LocationSelector;
