import _ from "lodash";
import millify from "millify";
import { useLocation } from "react-router-dom";

export const getLabelFromOption = (products) => {
  return products.map((product) => ({
    value: product,
    label: product,
  }));
};

export const getAPIErrorsForFormik = (payload) => {
  if (
    !payload.errors ||
    !payload.errors[0] ||
    payload.errors[0].parameter !== "data/attributes/" ||
    payload.errors[0].detail.errors.length === 0
  ) {
    return {};
  }

  return Object.fromEntries(
    payload.errors[0].detail.errors.map(({ parameter, detail }) => [
      _.camelCase(parameter.replace("data/attributes/", "")),
      detail,
    ])
  );
};

export const convertArrayToSelectOptions = (values) => {
  return values.map((value) => ({ label: value, value }));
};

export const convertValueToSelectOption = (value) => {
  return { label: value, value };
};

export const convertModelArrayToSelectOptions = (
  items,
  value,
  label,
  includeOriginal
) => {
  return items.map((item) => ({
    ...(includeOriginal ? { ...item } : {}),
    value: typeof item[value] === undefined ? "" : item[value],
    label: item[label] || "",
  }));
};

export const transformSelectObjects = (formValues, selectKeys) => {
  const payload = {};

  selectKeys.map((key) => (payload[key] = formValues[key]?.value));

  return payload;
};

export const getOneLinerErrorMessage = (payload) => {
  if (
    !payload ||
    !payload.message ||
    payload.message?.length !== 1 ||
    typeof payload.message[0] !== "string"
  ) {
    return null;
  }

  return payload.message[0];
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const effectStoreUserRole = (userRole, route) => {
  if (userRole?.id) {
    if (route == "items" || route == "write-items") {
      if (
        route == "items" &&
        (userRole.items == "rw" || userRole.items == "r")
      ) {
        return true;
      } else if (route == "write-items" && userRole.items == "rw") {
        return true;
      } else {
        return false;
      }
    }

    if (route == "category" || route == "write-category") {
      if (
        route == "category" &&
        (userRole.category == "rw" || userRole.category == "r")
      ) {
        return true;
      } else if (route == "write-category" && userRole.category == "rw") {
        return true;
      } else {
        return false;
      }
    }

    if (route == "orders" || route == "write-orders") {
      if (
        route == "orders" &&
        (userRole.orders == "rw" || userRole.orders == "r")
      ) {
        return true;
      } else if (route == "write-orders" && userRole.orders == "rw") {
        return true;
      } else {
        return false;
      }
    }

    if (route == "users" || route == "write-users") {
      if (
        route == "users" &&
        (userRole.users == "rw" || userRole.users == "r")
      ) {
        return true;
      } else if (route == "write-users" && userRole.users == "rw") {
        return true;
      } else {
        return false;
      }
    }

    if (route == "sales" || route == "write-sales") {
      if (
        route == "sales" &&
        (userRole.sales == "rw" || userRole.sales == "r")
      ) {
        return true;
      } else if (route == "write-sales" && userRole.sales == "rw") {
        return true;
      } else {
        return false;
      }
    }
  } else {
    return true;
  }
};

export const dataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? window.atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
  return new Blob([ia], { type: mimeString });
};

export const YearList = (startYear, currentYear) => {
  const current = currentYear || new Date().getFullYear();
  const start = startYear || 2018;
  let list = [];
  for (let index = start; index <= current; index++) {
    list.push(index);
  }
  return list.reverse();
};

export const hyphenate = (string) => {
  if (string) return string.split(" ").join("-");
};

export const sliderResponsive = [
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 3,
    },
  },
  {
    breakpoint: 800,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2,
    },
  },
  {
    breakpoint: 480,
    settings: {
      slidesToShow: 1,
      centerMode: true,
    },
  },

  {
    breakpoint: 380,
    settings: {
      slidesToShow: 1,
      centerMode: false,
    },
  },
];

export const formatNumber = (text, shortened = true) => {
  if (!text) return "0";
  return shortened ? millify(Number(text)) : Number(text)?.toLocaleString();
};
