/* eslint-disable react/react-in-jsx-scope */
const DefaultTable = ({ headerData, children }) => {
  return (
    <div className="tw-overflow-x-auto tw-relative">
      <table className="tw-table-auto tw-w-full">
        <thead className="tw-bg-gray-50 md:tw-py-[1.7rem] tw-text-gray-600 tw-rounded-[2rem]">
          <tr className="tw-border-b">
            {headerData?.map((item, key) => (
              <th
                key={key}
                className="tw-px-3 tw-py-8  tw-font-medium tw-text-[12.5px]"
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        {children}
        {/* <tbody>{children}</tbody> */}
      </table>
    </div>
  );
};

export default DefaultTable;
