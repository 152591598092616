import React from "react";
import Skeleton from "react-skeleton-loader";

const TableSkeleton = ({ rows = 10, columns = 5 }) => {
  return (
    <tbody>
      {[...new Array(Number(rows))].map((_, parentIdx) => (
        <tr className="tw-border-b" key={`tr-${parentIdx}`}>
          {[...new Array(Number(columns))].map((_, Idx) => (
            <td className="tw-py-3 tw-px-3" key={Idx}>
              <Skeleton count={1} />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

export default TableSkeleton;
