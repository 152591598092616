import Modal from ".";
import { Button } from "../Button";
import { CloseIcon } from "assets/icons";
import { MODAL_BODY_STYLE, MODAL_ROOT, MODAL_STYLE } from "constants";
import React from "react";

const DefaultDeleteModal = ({ isActive, onClose, onAction, loading }) => {
  return (
    <Modal
      isActive={isActive}
      onClose={onClose}
      classNames={{
        root: `${MODAL_ROOT}`,
        modal: `${MODAL_STYLE}`,
      }}
      closeIcon={<CloseIcon className="tw-mt-1.5 tw-mr-1 text-white tw-z-10" />}
    >
      <div
        className={`${MODAL_BODY_STYLE} tw-text-center tw-my-[2em] tw-w-[80%] tw-mx-auto`}
      >
        <div className="tw-flex tw-justify-center tw-my-4">
          <img src={"/images/trash.gif"} width={90} height={90} />
        </div>
        <h3 className="tw-font-semibold tw-items-center tw-text-center">
          Delete these item
        </h3>

        <p className="tw-my-5 tw-text-md md:tw-text-lg lg:text-text-lg ">
          Deleting these items will permanently remove them from your device.
          This action cannot be undone.
        </p>

        <div className="tw-flex tw-justify-center tw-mt-5 tw-gap-5 tw-items-center tw-w-full">
          <Button
            onClick={onClose}
            className=" tw-border tw-text-primary-800 tw-w-full tw-border-primary-800 "
          >
            Cancel
          </Button>

          <Button
            onClick={onAction}
            loading={loading}
            disabled={loading}
            variant="primary"
            className=" tw-w-full tw-text-white"
          >
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DefaultDeleteModal;
