import FormContainer from "../../../FormContainer";
import TextInput from "components/shared/forms/TextInput";
import { Field } from "formik";
import React from "react";

const TransportAndParkingForm = () => {
  return (
    <FormContainer>
      <p className="tw-font-medium">Transport & Parking</p>

      <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-3 tw-gap-6 tw-mt-6">
        <div>
          <Field
            component={TextInput}
            className="tw-input !tw-border-gray-300"
            name="trainNumber"
            id="trainNumber"
            placeholder="Enter Train Number"
            type="text"
            label="Train"
            labelClassName="tw-ml-4"
          />
        </div>

        <div>
          <Field
            component={TextInput}
            className="tw-input !tw-border-gray-300"
            name="busNumber"
            id="busNumber"
            placeholder="Enter Bus Number"
            type="text"
            label="Bus"
            labelClassName="tw-ml-4"
          />
        </div>

        <div>
          <Field
            component={TextInput}
            className="tw-input !tw-border-gray-300"
            name="ferryNumber"
            id="ferryNumber"
            placeholder="Enter Ferry Number"
            type="text"
            label="Ferry"
            labelClassName="tw-ml-4"
          />
        </div>

        <div>
          <Field
            component={TextInput}
            className="tw-input !tw-border-gray-300"
            name="freeParkingLocation"
            id="freeParkingLocation"
            placeholder="Enter Free Parking"
            type="text"
            label="Free Parking"
            labelClassName="tw-ml-4"
          />
        </div>

        <div>
          <Field
            component={TextInput}
            className="tw-input !tw-border-gray-300"
            name="paidParkingLocation"
            id="paidParkingLocation"
            placeholder="Enter Paid Parking"
            type="text"
            label="Paid Parking"
            labelClassName="tw-ml-4"
          />
        </div>
      </div>
    </FormContainer>
  );
};

export default TransportAndParkingForm;
