import React from "react";

export function thousandFormatter(number) {
  if (typeof number === "undefined") return 0;

  const parts = parseFloat(number).toFixed(2).split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export const GetNumberString = (number) => {
  if (number == 1) {
    return `${number}st`;
  } else if (number == 2) {
    return `${number}nd`;
  } else if (number == 3) {
    return `${number}rd`;
  } else {
    return `${number}th`;
  }
};

export const checkAndReduceLength = (text, length) => {
  const textLength = text.split("").length;
  if (textLength > length) {
    const font = 100 - (textLength - length) * 10;
    return (
      <span
        style={{
          fontSize: `${font}%`,
        }}
      >
        {text}
      </span>
    );
  } else {
    return <span>{text}</span>;
  }
};
