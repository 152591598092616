import React from "react";

const SelectFuelStationPane = ({ src, name, price, onSelect, isSelected }) => {
  return (
    <div
      className="tw-flex tw-items-center tw-justify-between tw-my-5 tw-cursor-pointer"
      onClick={onSelect}
    >
      <div className="tw-m-0 tw-flex tw-items-center tw-gap-5 ">
        <input
          type="checkbox"
          className="tw-cursor-pointer"
          checked={isSelected}
        />
        <div
          className="tw-w-[50px] tw-h-[50px] tw-rounded-full  "
          style={{
            backgroundImage: `url(${src ? src : "/images/logo.svg"})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            transition: "background-image 0.3s ease-in-out",
          }}
        ></div>
      </div>
      <div className="tw-m-0 tw-flex tw-items-center tw-gap-5 ">
        <p className="tw-font-600 tw-text-sm sm:tw-text-sm md:tw-text-[14px] lg:tw-text-[14px]">
          {name}
        </p>
        <p className="tw-font-600 tw-text-sm sm:tw-text-sm md:tw-text-[14px] lg:tw-text-[14px]">
          {price}/ltr
        </p>
      </div>
    </div>
  );
};

export default SelectFuelStationPane;
