import FormContainer from "../../FormContainer";
import { useEventFormContext } from "../../context";
import MediaForm from "./Media";
import ObservationsForm from "./Observations";
import PromotionalVideosForm from "./PromotionalVideos";
import PublishSuccessModal from "./PublishSuccessModal";
import RefundPolicyForm from "./RefundPolicy";
import StepOutForm from "./StepOut";
import TransportAndParkingForm from "./TransportAndParking";
import {
  LATE_TIME_ENRTY_FORMAT,
  SELECT_FORM_KEYS,
  initialValues,
  validationSchema,
} from "./constants";
import { unwrapResult } from "@reduxjs/toolkit";
import { Button } from "components/shared/Button";
import { Form, Formik } from "formik";
import useMedia from "hooks/useMedia";
import { DateTime } from "luxon";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import {
  publishEventRequest,
  updateEventMetaRequest,
} from "state/slices/events";
import {
  convertValueToSelectOption,
  getAPIErrorsForFormik,
  getOneLinerErrorMessage,
  transformSelectObjects,
} from "utilities";

const SettingsAndMedia = ({ goToPreviousStep }) => {
  const history = useHistory();

  const { event, updateEvent } = useEventFormContext();

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const [shouldPublish, setShouldPublish] = useState(false);

  const [openPublishSuccessModal, setOpenPublishSuccessModal] = useState(false);

  const {
    singleImage: photoUrl,
    addImages: addPhotoUrl,
    uploadSingleImage,
  } = useMedia({
    isSingle: true,
    defaultImages: event.meta?.photoUrl,
  });

  const {
    images: otherPhotos,
    addImages: addOtherPhotos,
    uploadMultipleImages,
    removeImage: removeFromOtherPhotos,
  } = useMedia({
    defaultImages: event.meta?.otherPhotos,
  });

  const {
    singleImage: video,
    addImages: addVideo,
    uploadSingleImage: uploadSingleVideo,
    removeImage: removeVideo,
  } = useMedia({
    isSingle: true,
    defaultImages: event.meta?.promotionalVideos?.[0] || "",
  });

  const publishEvent = async () => {
    setIsLoading(true);
    const payload = { eventId: event.id };

    const resultAction = await dispatch(publishEventRequest(payload));
    if (publishEventRequest.fulfilled.match(resultAction)) {
      updateEvent({ isPublished: true });
      setIsLoading(false);
      setOpenPublishSuccessModal(true);
    } else {
      setIsLoading(false);
      if (resultAction.payload.message) {
        toast.error(resultAction.payload.message);
      } else {
        toast.error(
          getOneLinerErrorMessage(resultAction.payload) ||
            resultAction.error?.message ||
            "An error occurred, please try again."
        );
      }
    }
  };

  const handleUpdate = async (formValues, formikHelpers) => {
    setIsLoading(true);

    let uploadedPhotoUrl = "";
    let otherUploadedPhotoUrls = "";
    let uploadedVideoUrl = "";

    try {
      uploadedPhotoUrl = await uploadSingleImage();
      otherUploadedPhotoUrls = await uploadMultipleImages();
      uploadedVideoUrl = await uploadSingleVideo();
    } catch (error) {
      setShouldPublish(false);
      setIsLoading(false);
      return;
    }

    const payload = {
      eventId: event.id,
      data: {
        ...formValues,
        ...transformSelectObjects(formValues, SELECT_FORM_KEYS),
        photoUrl: uploadedPhotoUrl,
        otherPhotos: otherUploadedPhotoUrls,
        promotionalVideos: [uploadedVideoUrl],
        eventId: event.id,
        lateEntryTime: formValues.lateEntryTime
          ? DateTime.fromJSDate(formValues.lateEntryTime).toFormat(
              LATE_TIME_ENRTY_FORMAT
            )
          : "",
      },
    };

    const resultAction = await dispatch(updateEventMetaRequest(payload));
    if (updateEventMetaRequest.fulfilled.match(resultAction)) {
      const response = unwrapResult(resultAction);
      toast.success("Successfully saved event meta");
      updateEvent({ meta: response.data });

      console.log("here");

      if (shouldPublish) return publishEvent();

      setIsLoading(false);
      history.push("/dashboard/organiser/events");
    } else {
      setIsLoading(false);
      if (resultAction.payload.message) {
        toast.error(resultAction.payload.message);
      } else {
        formikHelpers.setErrors(getAPIErrorsForFormik(resultAction.payload));
        toast.error(
          getOneLinerErrorMessage(resultAction.payload) ||
            resultAction.error?.message ||
            "An error occurred, please try again."
        );
      }
    }
  };

  const saveAndPublishEvent = (submitForm) => {
    setShouldPublish(true);
    submitForm();
  };

  useEffect(() => {
    if (!isLoading) setShouldPublish(false);
  }, [isLoading]);

  const formEditInitialValues = useMemo(() => {
    if (!event.id) return {};

    const newMeta = {
      ...event.meta,
      ...(event.meta.lateEntryTime &&
      event.meta.lateEntryTime !== "Invalid DateTime"
        ? {
            lateEntryTime: DateTime.fromFormat(
              event.meta.lateEntryTime,
              LATE_TIME_ENRTY_FORMAT
            ).toJSDate(),
          }
        : { lateEntryTime: "" }),
    };

    SELECT_FORM_KEYS.forEach((key) => {
      newMeta[key] = convertValueToSelectOption(newMeta[key]);
    });
    return newMeta;
  }, [event]);

  return (
    <div>
      <PublishSuccessModal
        isOpen={openPublishSuccessModal}
        closeModal={() => setOpenPublishSuccessModal(false)}
        event={event}
      />

      <div className="tw-mt-6">
        <Formik
          enableReinitialize={true}
          initialValues={
            !event.meta.eventId ? initialValues : formEditInitialValues
          }
          validationSchema={validationSchema}
          onSubmit={handleUpdate}
        >
          {({ handleSubmit, isValid, values, setFieldValue }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <TransportAndParkingForm />

                <hr />

                <ObservationsForm setFieldValue={setFieldValue} />

                <hr />

                <StepOutForm setFieldValue={setFieldValue} values={values} />

                <hr />

                <RefundPolicyForm
                  setFieldValue={setFieldValue}
                  values={values}
                />

                <hr />

                <MediaForm
                  photoUrl={photoUrl}
                  addPhotoUrl={addPhotoUrl}
                  otherPhotos={otherPhotos}
                  addOtherPhotos={addOtherPhotos}
                  removeFromOtherPhotos={removeFromOtherPhotos}
                />

                <hr />

                <PromotionalVideosForm
                  video={video}
                  addVideo={addVideo}
                  removeVideo={removeVideo}
                />

                <FormContainer>
                  <div className="tw-flex tw-gap-3 tw-justify-end tw-my-12">
                    <Button
                      type="button"
                      className="tw-border-none tw-px-8 tw-py-5 tw-rounded-md tw-bg-white tw-shadow-md"
                      title="Save"
                      loadingTitle="Saving..."
                      variant="rounded"
                      onClick={goToPreviousStep}
                      disabled={isLoading || !isValid}
                    >
                      Previous
                    </Button>
                    <Button
                      type="submit"
                      className="tw-border-none tw-px-8 tw-py-5 tw-rounded-md tw-bg-secondary-700 tw-text-white"
                      title="Save"
                      loadingTitle="Saving..."
                      variant="rounded"
                      loading={!shouldPublish && isLoading}
                      disabled={isLoading || !isValid}
                    >
                      Save/Exit
                    </Button>
                    {!event.isPublished && (
                      <Button
                        type="button"
                        className="tw-border-none tw-px-8 tw-py-5 tw-rounded-md tw-bg-green-600 tw-text-white"
                        title="Publish Event"
                        loadingTitle="Saving..."
                        variant="rounded"
                        onClick={() => saveAndPublishEvent(handleSubmit)}
                        loading={shouldPublish && isLoading}
                        disabled={isLoading || !isValid}
                      >
                        Publish 🎉
                      </Button>
                    )}
                  </div>
                </FormContainer>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default SettingsAndMedia;
