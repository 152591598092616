import { initialValues, validationSchema } from "./constants";
import { Logo } from "assets/images";
import LockImg from "assets/images/LockImg.png";
import { Button } from "components/shared/Button";
import TextInput from "components/shared/forms/TextInput";
import { Field, Form, Formik } from "formik";
import produce from "immer";
import React, { Fragment, useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { forgotPasswordRequest } from "state/slices/account";

const ForgotPassword = ({ history }) => {
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    document.title = "Triple7Events - Forgot Password";
  }, []);

  const [state, setState] = useState({
    isForgotPasswordLoading: false,
  });

  const handleForgotPassword = async (formValues) => {
    setState(
      produce((draft) => {
        draft.isForgotPasswordLoading = true;
      })
    );

    const resultAction = await dispatch(forgotPasswordRequest(formValues));
    if (forgotPasswordRequest.fulfilled.match(resultAction)) {
      setState(
        produce((draft) => {
          draft.isForgotPasswordLoading = false;
        })
      );
      toast.success("Recovery code sent on your mail!");
    } else {
      setState(
        produce((draft) => {
          draft.isForgotPasswordLoading = false;
        })
      );

      toast.error(
        resultAction.payload?.message ||
          resultAction.error?.message ||
          "An error occurred, please try again."
      );
    }
  };

  return (
    <Fragment>
      <section className="tw-mx-auto tw-flex tw-flex-row tw-max-h-screen tw-h-screen tw-relative">
        <div className="tw-w-full md:tw-w-1/2 md:tw-max-w-[50vw] tw-flex tw-justify-center tw-px-8">
          <div className="tw-flex tw-flex-col tw-w-[533px] tw-mx-auto">
            <Link to="/">
              <Logo className="tw-w-56 tw-mx-auto tw-mt-12 md:tw-mt-24" />
            </Link>
            <h2 className="tw-text-2xl tw-font-semibold tw-text-center tw-mb-6 tw-mt-24">
              Forgot your Password?
            </h2>
            <span className="tw-text-base tw-text-center">
              Enter your email address to receive your <br /> password reset
              instructions
            </span>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleForgotPassword}
            >
              {({ handleSubmit, isValid }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <div className="tw-flex tw-mt-16 tw-flex-col">
                      <label
                        htmlFor="email"
                        className="tw-text-sm tw-ml-5 tw-font-semibold"
                      >
                        Email Address
                      </label>
                      <div className="tw-mb-[61px]">
                        <Field
                          component={TextInput}
                          className="tw-rounded-xl tw-text-base tw-border-2 tw-border-[#555] tw-pl-5 tw-py-3 tw-w-full focus-visible:tw-outline-secondary-600"
                          type="email"
                          name="email"
                          id="email"
                          placeholder="Enter Email Address"
                        />
                      </div>
                    </div>

                    <div className="tw-flex tw-justify-center">
                      <Button
                        type="submit"
                        className="tw-rounded-[10px] tw-bg-[#941C1F] tw-text-white tw-font-semibold tw-py-[12px] xs:tw-py-8 tw-px-[80px] tw-block xs:tw-text-lg"
                        title="Send Link"
                        loading={state.isForgotPasswordLoading}
                        disabled={state.isForgotPasswordLoading || !isValid}
                      >
                        Send Link
                      </Button>
                    </div>

                    <div className="form-group">
                      <div className="text-center tw-mt-[100px] font-weight-normal">
                        © {new Date().getFullYear()} Triple7 Events.
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
        <div className="tw-hidden md:tw-block tw-w-1/2 tw-absolute tw-right-0 tw-top-0 tw-min-h-screen tw-overflow-auto tw-overflow-y-hidden">
          <img
            src={LockImg}
            alt="lock-image"
            className="tw-h-screen tw-w-full"
          />
        </div>
      </section>
    </Fragment>
  );
};

export default ForgotPassword;
