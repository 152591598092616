import "./styles.scss";
import { DashboardSidebar } from "components/pages/authenticated/user-dashboard/sharedcomponents/Sidebar";
import EventDashTestHeader from "components/shared/EventDashTestHeader";
import EventRecentTransaction from "components/shared/EventRecentTransaction";
import UpcomingEvents from "components/shared/UpcomingEvents";
import AlternateNavBar from "components/shared/partials/AlternateNavBar";
import EventDashRestaurants from "components/shared/partials/EventDashRestaurants";
import React from "react";

const EventDashTest = () => {
  return (
    <>
      <AlternateNavBar />
      <DashboardSidebar />
      <div className="row bg-ev-ds-ts dashboard-wrapper">
        <div className="eventDashTest col-8">
          <EventDashTestHeader />
          <UpcomingEvents />
          <EventDashRestaurants />
        </div>
        <div className="col-4">
          <EventRecentTransaction />
        </div>
      </div>
    </>
  );
};

export default EventDashTest;
