import * as yup from "yup";

export const initialValues = {
  ticketSettings: {
    showRemainingTickets: false,
    absorbPolicy: true,
    displayOrganizerMobile: false,
  },
  organizerMobile: "",
};

export const validationSchema = yup.object().shape({
  organizerMobile: yup.string().label("Organiser Mobile Number").required(),
});
