import { CloseIcon } from "assets/icons";
import { Button } from "components/shared/Button";
import Modal from "components/shared/modal";
import { findCurrencySymbolByCode } from "lib/country";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { deleteEventTicketRequest } from "state/slices/events";
import { getOneLinerErrorMessage } from "utilities";

const DeleteTicketModal = ({
  isOpen,
  closeModal,
  ticket,
  event,
  updateTickets,
}) => {
  const [isLoading, setIsLoading] = useState();

  const dispatch = useDispatch();

  const removeTicket = (id) => {
    updateTickets((oldTickets) => {
      const findTicketIndex = oldTickets.findIndex(
        (oldTicket) => oldTicket.id === id
      );
      if (findTicketIndex < 0) return oldTickets;

      oldTickets.splice(findTicketIndex, 1);

      return [...oldTickets];
    });
  };

  const deleteTicket = async () => {
    setIsLoading(true);
    const payload = {
      eventId: event.id,
      id: ticket.id,
    };
    const resultAction = await dispatch(deleteEventTicketRequest(payload));
    if (deleteEventTicketRequest.fulfilled.match(resultAction)) {
      removeTicket(ticket.id);
      setIsLoading(false);
      toast.success("Successfully deleted ticket");
      closeModal();
    } else {
      setIsLoading(false);
      if (resultAction.payload.message) {
        toast.error(resultAction.payload.message);
      } else {
        toast.error(
          getOneLinerErrorMessage(resultAction.payload) ||
            resultAction.error?.message ||
            "An error occurred, please try again."
        );
      }
    }
  };

  return (
    <Modal
      isActive={isOpen}
      onClose={closeModal}
      classNames={{
        root: "tw-max-w-[600px] tw-m-auto",
        modal: "tw-w-full tw-max-h-[280px]",
      }}
      closeIcon={
        <CloseIcon
          className="tw-mt-1.5 tw-mr-1 text-white tw-z-10"
          onClick={closeModal}
        />
      }
    >
      <div className="tw-text-lg">
        <div className="px-4 py-3 d-flex justify-content-between align-items-center bg-accent text-white">
          <h5 className="font-weight-bold text-xl mb-0">Delete Ticket</h5>
        </div>

        <div className="tw-relative modal-body tw-min-h-screen/2.5 tw-items-center tw-justify-center tw-text-base tw-overflow-x-hidden">
          <div className="tw-py-6 tw-px-4 tw-flex tw-flex-col tw-gap-3">
            Are you sure you want to delete this ticket?
            <span className="tw-capitalize">
              Name: <b>{ticket.name}</b>
            </span>
            <span className="tw-capitalize">
              Type: <b>{ticket.type}</b>
            </span>
            <span className="tw-capitalize">
              Price:{" "}
              <b>
                {findCurrencySymbolByCode(event.currency)}
                {ticket.price}
              </b>
            </span>
          </div>

          <div className="tw-flex tw-justify-end tw-gap-6">
            <Button
              type="button"
              className="tw-border-none tw-px-12 tw-py-5 tw-rounded-md tw-bg-secondary-700 tw-text-white"
              variant="rounded"
              onClick={deleteTicket}
              loading={isLoading}
              disabled={isLoading}
            >
              Yes, Delete
            </Button>
            <Button
              type="button"
              className="tw-border-none tw-px-12 tw-py-5 tw-rounded-md tw-bg-primary-800 tw-text-white"
              title="Save"
              loadingTitle="Saving..."
              variant="rounded"
              onClick={closeModal}
              disabled={isLoading}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteTicketModal;
