import { BackArrowIcon, CircleCheckIcon } from "assets/icons";
import {
  ActivateWalletIllustration,
  Logo,
  UserWalletImage,
} from "assets/images";
import DashboardFooter from "components/pages/authenticated-new/components/Footer";
import { Button } from "components/shared/Button";
import React from "react";
import { useHistory } from "react-router-dom";

const ActivateWalletPage = () => {
  const history = useHistory();

  return (
    <div>
      <div className="tw-bg-gradient-to-r tw-from-[#C8D3E6] tw-to-[#205076]">
        <div className="tw-max-w-8xl tw-px-4 lg:tw-px-20 tw-mx-auto">
          <div className="tw-py-6 tw-pb-2">
            <Logo className="tw-w-36" />
          </div>

          <div className="tw-p-8 tw-pt-2">
            <Button
              onClick={() => history.goBack()}
              className="tw-flex tw-gap-4 !tw-rounded-full tw-border tw-border-white tw-text-white tw-my-6 tw-shadow-none"
            >
              <BackArrowIcon />

              <span>Go Back</span>
            </Button>

            <div className="tw-flex tw-flex-col-reverse md:tw-flex-row tw-gap-y-8">
              <div className="tw-w-full md:tw-w-1/2">
                <span className="tw-text-white tw-text-3xl md:tw-text-5xl tw-font-semibold">
                  Simpler <br />
                  Smarter, Safer
                </span>

                <div className="tw-my-6 tw-flex tw-flex-col tw-gap-2">
                  <div className="tw-flex tw-gap-3 tw-items-start">
                    <CircleCheckIcon className="tw-text-primary-800 tw-w-7 tw-h-7 tw-flex-shrink-0" />
                    Easy and convenient in-app payment method.
                  </div>
                  <div className="tw-flex tw-gap-3 tw-items-start">
                    <CircleCheckIcon className="tw-text-primary-800 tw-w-7 tw-h-7 tw-flex-shrink-0" />
                    Every transaction is authenticated with your PIN and
                    features like one-time passwords, tokenization, and
                    point-to-point encryption.
                  </div>
                  <div className="tw-flex tw-gap-3">
                    <CircleCheckIcon className="tw-text-primary-800 tw-w-7 tw-h-7 tw-flex-shrink-0" />
                    View transactions at a glance or filter by income, expense,
                    and date
                  </div>
                </div>

                <Button className="tw-bg-primary-800 tw-text-white !tw-h-20 !tw-px-16 !tw-text-xl tw-uppercase tw-mt-6">
                  Coming Soon
                </Button>

                <div className="tw-text-base tw-mt-6">
                  To use triple7events wallet feature,{" "}
                  <span className="tw-text-primary-800">
                    Terms & Conditions
                  </span>{" "}
                  apply.
                </div>
              </div>
              <div className="tw-w-full md:tw-w-1/2 tw-flex tw-justify-center md:tw-justify-end">
                <img
                  src={UserWalletImage}
                  className="tw-max-w-[250px] md:tw-max-w-[350px] lg:tw-max-w-[450px]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="tw-pt-16">
        <div className="tw-max-w-8xl tw-px-6 md:tw-px-20 tw-mx-auto tw-flex tw-flex-col md:tw-flex-row tw-gap-6 tw-items-center">
          <div className="tw-w-full md:tw-w-1/2 tw-flex tw-justify-center">
            <img
              src={ActivateWalletIllustration}
              className="tw-max-w-[200px] md:tw-max-w-[300px] lg:tw-max-w-[400px]"
            />
          </div>
          <div className="tw-w-full md:tw-w-1/2">
            <p className="tw-text-2xl tw-font-medium tw-text-secondary-700">
              How Triple7events Wallet Works
            </p>

            <p>
              Triple7events wallet makes payment and bookings extremely easy,
              fast, and secure.
            </p>

            <div className="tw-mt-10">
              <p className="tw-text-secondary-700">
                Activating your triple7events wallet is as easy as 1,2,3
              </p>

              <ol className="tw-list-decimal tw-ml-4">
                <li>Click on Activate wallet button</li>
                <li>
                  Fill your details in the pop-up (Be sure to remember them)
                </li>
                <li>Top up your wallet, and you are good to go.</li>
              </ol>
            </div>

            <div className="tw-mt-10">
              <p className="tw-text-secondary-700">
                Pay using your wallet balance.
              </p>

              <p>
                From your smartphone, iPad, Laptop, or PC, you can pay for and
                book movies, stage plays, concerts, events, hotels, theme parks,
                museums, petrol, meals, and much more. You don&apos;t have to
                stand in those never-ending lines any longer. You pay for your
                tickets directly from your wallet balance when you use the
                wallet. At any time during the day, you can cancel or check the
                status of your ticket.
              </p>
            </div>

            <div className="tw-mt-10">
              <p className="tw-text-secondary-700">
                Earn rewards and cash backs
              </p>

              <p>
                Earn rewards and cashback such as discounts, redeemable points,
                promos, combo offers, and many more.
              </p>
            </div>
          </div>
        </div>
      </div>

      <DashboardFooter />
    </div>
  );
};

export default ActivateWalletPage;
