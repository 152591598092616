import React from "react";

const DashboardFooter = () => {
  return (
    <div className="tw-flex tw-w-full tw-justify-center tw-text-center tw-my-6 tw-mt-10 tw-text-sm tw-text-gray-700">
      <p>
        Triple7events is the trade name of Hobnika Limited ©{" "}
        {new Date().getFullYear()} triple7events. All Rights Reserved.
      </p>
    </div>
  );
};

export default DashboardFooter;
