import FuelBuyerDashboardPageTitle from "../../../components/PageTitle";
import { EditIcon, TrashIcon } from "assets/icons";
import PlainFuelStationDashboardLayout from "components/layout/FuelStation/plainFuelStationDashboardContainer";
import EmptyTable from "components/pages/authenticated-new/pages/Events/shared/EmptyTable";
import { Button } from "components/shared/Button";
import TableSkeleton from "components/shared/TableSkeleton";
import DefaultDeleteModal from "components/shared/modal/deleteModal";
import DefaultTable from "components/shared/tables/defaultTable";
import TableActionIcons from "components/shared/tables/tableActionIcons";
import { FUEL_BUYER_VEHICLES_TABLEHEADER } from "constants/tableHeaderConstants";
import { TABLE_STYLE_ENUMS } from "enums/styleEnums";
import usePagination from "hooks/usePagination";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  deleteVehicleRequest,
  fetchPaginatedVehiclesRequest,
} from "state/slices/fuel";
import { getOneLinerErrorMessage } from "utilities";

const FuelBuyerVehiclesTablePage = () => {
  const [activeVehicle, setActiveVehicle] = useState({});

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const showDeleteModal = (vehicle) => {
    setActiveVehicle(vehicle);
    setOpenDeleteModal(true);
  };

  const [search, setSearch] = React.useState("");
  const { data, pager, loading, refetch } = usePagination({
    dataSelector: "",
    request: fetchPaginatedVehiclesRequest,
    customQueryParams: {
      ...(search ? { search } : {}),
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const deleteVehicle = async () => {
    setIsLoading(true);
    const resultAction = await dispatch(deleteVehicleRequest(activeVehicle));
    if (deleteVehicleRequest.fulfilled.match(resultAction)) {
      setIsLoading(false);
      toast.success("Successfully deleted vehicle");
      refetch();
      setOpenDeleteModal(false);
    } else {
      setIsLoading(false);
      if (resultAction.payload.message) {
        toast.error(resultAction.payload.message);
      } else {
        toast.error(
          getOneLinerErrorMessage(resultAction.payload) ||
            resultAction.error?.message ||
            "An error occurred, please try again."
        );
      }
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <>
      <DefaultDeleteModal
        isActive={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        onAction={deleteVehicle}
        loading={isLoading}
      />

      <div>
        <div className="tw-flex tw-justify-between tw-items-center">
          <FuelBuyerDashboardPageTitle
            title="Vehicle List"
            description={"Your registered vehicles"}
          />

          <div className="tw-flex tw-gap-2">
            <input
              className="!tw-h-full !tw-min-h-full !tw-w-72 tw-border !tw-rounded-md tw-input"
              placeholder="Search"
              type="search"
              onChange={handleSearch}
            />

            <Link to="/dashboard/fuel-station/vehicles/create">
              <Button className="tw-bg-green-500 tw-text-white">
                ADD VEHICLE
              </Button>
            </Link>
          </div>
        </div>

        <PlainFuelStationDashboardLayout>
          <div className="tw-mt-6">
            <div className="tw-py-4 tw-text-base">
              {loading || data?.length > 0 ? (
                <div>
                  <div className="tw-relative tw-overflow-x-auto">
                    <div>
                      <DefaultTable
                        headerData={FUEL_BUYER_VEHICLES_TABLEHEADER}
                      >
                        {loading ? (
                          <TableSkeleton rows={10} columns={7} />
                        ) : (
                          <tbody className={TABLE_STYLE_ENUMS.TABLE_BODY}>
                            {data.map((vehicle, Idx) => (
                              <tr className="tw-border-b" key={Idx}>
                                <td className="tw-px-3 tw-py-3 tw-w-48">
                                  {vehicle?.type}
                                </td>
                                <td className="tw-px-3 tw-py-3 tw-w-48">
                                  {vehicle?.manufacturer}
                                </td>
                                <td className="tw-px-3 tw-py-3">
                                  {vehicle?.licenseNo}
                                </td>
                                <td className="tw-px-3 tw-py-3">
                                  {vehicle?.model}
                                </td>
                                <td className="tw-px-3 tw-py-3 tw-w-48"></td>
                                <td className="tw-px-3 tw-py-3">
                                  {vehicle?.fuelType}
                                </td>
                                <td className="tw-px-3 tw-py-3">
                                  <TableActionIcons
                                    onDelete={() =>
                                      showDeleteModal(vehicle?.id)
                                    }
                                    editPath={`/dashboard/fuel-station/vehicles/edit/${vehicle?.id}`}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        )}
                      </DefaultTable>

                      {pager}
                    </div>
                  </div>
                </div>
              ) : (
                <EmptyTable
                  title="No Vehicles(s) Available"
                  description=" Once any vehicles(s) is added, they will appear here."
                  className="tw-mb-6"
                />
              )}
            </div>
          </div>
        </PlainFuelStationDashboardLayout>
      </div>
    </>
  );
};

export default FuelBuyerVehiclesTablePage;
