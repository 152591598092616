import { EventFallbackImage } from "assets/images";
import React from "react";
import { useImage } from "react-image";
import { Img } from "react-image";

export const ImageComponent = ({ src: srcList, ...props }) => {
  const { src } = useImage({ srcList });

  return <img src={`${src}`} alt="" {...props} />;
};
