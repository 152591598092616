/* eslint-disable react-hooks/exhaustive-deps */
import { INGRESSO_ACTIVE_COUNTRIES } from "./constants";
import { shuffleArray } from "./functions";
import usePagination from "hooks/usePagination";
import { useCallback, useEffect, useState } from "react";
import { flushSync } from "react-dom";
import { useSelector } from "react-redux";
import {
  fetchAllEventsRequest,
  fetchAllIngressoEventsRequest,
} from "state/slices/events";

const DEFAULT_LIMIT = 9;

export const useCombinedEventsPagination = ({
  search,
  category,
  limit = DEFAULT_LIMIT,
}) => {
  const location = useSelector((state) => state.location);

  const [initialLoad, setInitialLoad] = useState(true);

  const [displayedEvents, setDisplayedEvents] = useState([]);

  const [eventsStore, setEventsStore] = useState([]);

  const [ingressoDataIsExhausted, setIngressoDataIsExhausted] = useState(false);
  const [t7DataIsExhausted, setT7DataIsExhausted] = useState(false);

  const dataIsExhausted = ingressoDataIsExhausted && t7DataIsExhausted;

  const {
    data: t7Events,
    loading: loadingT7Events,
    error: t7EventsError,
    templateData: { currentPage: currentT7Epage, goToPage: goToT7Page },
  } = usePagination({
    dataSelector: "events",
    request: fetchAllEventsRequest,
    limit,
    customQueryParams: {
      ...(search && { search }),
      ...(category && { category }),
    },
  });

  const {
    data: ingressoEvents,
    loading: loadingIngressoEvents,
    error: ingressoEventsError,
    templateData: { currentPage: currentIGpage, goToPage: goToIGPage },
  } = usePagination({
    dataSelector: "events",
    request: fetchAllIngressoEventsRequest,
    limit: limit,
    customQueryParams: {
      ...(search && { keywords: search }),
    },
  });

  const loading = loadingT7Events || loadingIngressoEvents;

  const displayMoreData = useCallback(() => {
    const splicedData = eventsStore.slice(0, limit);
    setDisplayedEvents((oldDisplayedEvents) => {
      return [
        ...oldDisplayedEvents,
        ...(splicedData?.length > 0 ? splicedData : []),
      ];
    });

    flushSync(() => {
      setEventsStore((oldEventsStore) => {
        const newEventsStore = oldEventsStore.slice(limit);

        return [...newEventsStore];
      });
    });
  }, [eventsStore]);

  // Initial Load
  useEffect(() => {
    if (
      !loadingT7Events &&
      !loadingIngressoEvents &&
      initialLoad &&
      (eventsStore.length >= limit || dataIsExhausted)
    ) {
      displayMoreData();
      setInitialLoad(false);
    }
  }, [eventsStore.length, initialLoad, loadingIngressoEvents, loadingT7Events]);

  // When more t7events are loaded
  useEffect(() => {
    if (t7Events.length < limit) setT7DataIsExhausted(true);

    if (t7Events && t7Events.length > 0) {
      flushSync(() => {
        setEventsStore((oldEventsStore) => {
          return shuffleArray([...oldEventsStore, ...t7Events]);
        });
      });
    }
  }, [t7Events]);

  // When more ingresso events are loaded
  useEffect(() => {
    const ingressoIsEnabled = INGRESSO_ACTIVE_COUNTRIES.includes(
      location.country
    );

    if (ingressoEvents.length < limit || !ingressoIsEnabled)
      setIngressoDataIsExhausted(true);

    if (ingressoEvents && ingressoEvents.length > 0 && ingressoIsEnabled) {
      flushSync(() => {
        setEventsStore((oldEventsStore) => {
          return shuffleArray([...oldEventsStore, ...ingressoEvents]);
        });
      });
    }
  }, [ingressoEvents]);

  // Trigger refetch if the data in event Store is too small
  useEffect(() => {
    if (
      eventsStore.length < limit &&
      !loadingT7Events &&
      !loadingIngressoEvents &&
      !dataIsExhausted
    ) {
      if (!ingressoEventsError) goToIGPage(currentIGpage + 1);
      if (!t7EventsError) goToT7Page(currentT7Epage + 1);
    }
  }, [eventsStore.length, loadingIngressoEvents, loadingT7Events]);

  const resetData = useCallback(() => {
    setDisplayedEvents([]);
    flushSync(() => {
      setEventsStore([]);
    });
    setInitialLoad(true);
    setIngressoDataIsExhausted(false);
    setT7DataIsExhausted(false);
    goToIGPage(1);
    goToT7Page(1);
  }, [goToIGPage, goToT7Page]);

  useEffect(() => {
    resetData();
  }, [location, search, category]);

  const showLoadMoreButton = !(dataIsExhausted && eventsStore.length === 0);

  return {
    data: displayedEvents,
    displayMoreData,
    resetData,
    initialLoad,
    loading,
    showLoadMoreButton,
  };
};
