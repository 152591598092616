import "./styles.scss";
import { Logo } from "assets/images";
import LockImg from "assets/images/LockImg.png";
import { Button } from "components/shared/Button";
import TextInput from "components/shared/forms/TextInput";
import { Field, Form, Formik } from "formik";
import produce from "immer";
import React, { Fragment, useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { resetPasswordRequest } from "state/slices/account";
import { getOneLinerErrorMessage, useQuery } from "utilities";
import * as yup from "yup";

const ResetPassword = ({ history }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const query = useQuery();

  useLayoutEffect(() => {
    document.title = "Triple7Events - Reset Password";
  }, []);

  const [state, setState] = useState({
    isResetPasswordLoading: false,
  });

  const initialValues = {
    reset_key: "",
    new_pass1: "",
    new_pass2: "",
  };

  const validationSchema = yup.object().shape({
    // reset_key: yup.string().label("Reset Key").required(),
    password: yup
      .string()
      .label("New Password")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        "The password should consist of a minimum of one lowercase letter, one uppercase letter, and one digit, with a length of more than 8 characters."
      )
      .required(),
    confirmPassword: yup
      .string()
      .label("Confirm Password")
      .oneOf([null, yup.ref("password")], "Both password need to be the same")
      .required(),
  });

  const handleResetPassword = async (formValues, formikHelpers) => {
    setState(
      produce((draft) => {
        draft.isResetPasswordLoading = true;
      })
    );

    const resultAction = await dispatch(
      resetPasswordRequest({
        password: formValues.password,
        token: query.get("token") || "",
      })
    );

    if (resetPasswordRequest.fulfilled.match(resultAction)) {
      setState(
        produce((draft) => {
          draft.isResetPasswordLoading = false;
        })
      );
      toast.success("Password reset successful!");
      history.push("/auth/login");
    } else {
      setState(
        produce((draft) => {
          draft.isResetPasswordLoading = false;
        })
      );
      toast.error(
        getOneLinerErrorMessage(resultAction.payload) ||
          "An error occurred, please try again."
      );
    }
  };

  return (
    <Fragment>
      <section className="tw-mx-auto tw-flex tw-flex-row tw-max-h-screen tw-h-screen tw-relative">
        <div className="tw-w-full md:tw-w-1/2 md:tw-max-w-[50vw] tw-flex tw-justify-center tw-px-8">
          <div className="tw-flex tw-flex-col tw-w-[533px] tw-mx-auto">
            <Link to="/">
              <Logo className="tw-w-56 tw-mx-auto tw-mt-12 md:tw-mt-20" />
            </Link>
            <h2 className="tw-text-2xl tw-font-semibold tw-text-center tw-mb-6 tw-mt-24">
              Update your Password
            </h2>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleResetPassword}
            >
              {({ handleSubmit, isValid }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <div className="tw-flex tw-mt-10 tw-flex-col">
                      <label
                        htmlFor="newPassword"
                        className="tw-text-sm tw-ml-5 tw-font-semibold"
                      >
                        New Password
                      </label>
                      <div>
                        <Field
                          component={TextInput}
                          className="tw-rounded-xl tw-text-base tw-border-2 tw-border-[#555] tw-pl-5 tw-py-3 tw-w-full focus-visible:tw-outline-secondary-600"
                          type="password"
                          name="password"
                          id="password"
                          placeholder="Enter New Password"
                        />
                      </div>
                    </div>

                    <div className="tw-flex tw-flex-col tw-mt-8 tw-mb-12">
                      <label
                        htmlFor="confirmPassword"
                        className="tw-text-sm tw-ml-5 tw-font-semibold"
                      >
                        Confirm Password
                      </label>
                      <div>
                        <Field
                          component={TextInput}
                          className="tw-rounded-xl tw-text-base tw-border-2 tw-border-[#555] tw-pl-5 tw-py-3 tw-w-full focus-visible:tw-outline-secondary-600"
                          type="password"
                          name="confirmPassword"
                          id="confirmPassword"
                          placeholder="Enter New Password Again"
                        />
                      </div>
                    </div>

                    <div className="tw-flex tw-justify-center">
                      <Button
                        type="submit"
                        className="tw-rounded-[10px] tw-bg-[#941C1F] tw-text-white tw-font-semibold tw-py-[12px] xs:tw-py-8 tw-px-[80px] tw-block xs:tw-text-lg"
                        title="Send Link"
                        loading={state.isResetPasswordLoading}
                        disabled={state.isResetPasswordLoading || !isValid}
                      >
                        Update
                      </Button>
                    </div>

                    <div className="form-group">
                      <div className="text-center tw-mt-[80px] font-weight-normal">
                        © {new Date().getFullYear()} Triple7 Events.
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
        <div className="tw-hidden md:tw-block tw-w-1/2 tw-absolute tw-right-0 tw-top-0 tw-min-h-screen tw-overflow-auto tw-overflow-y-hidden">
          <img
            src={LockImg}
            alt="lock-image"
            className="tw-h-screen tw-w-full"
          />
        </div>
      </section>
    </Fragment>
  );
};

export default withRouter(ResetPassword);
