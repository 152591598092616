import axios from "axios";
import applyCaseMiddleware from "axios-case-converter";

const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_V2_BASE_URL,
  timeout: 30 * 1000,
});

Axios.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  (response) => response,

  async (error) => {
    if (error.message.includes("401")) {
      localStorage.removeItem("token");
      localStorage.removeItem("persist:root");
      localStorage.removeItem("storeUserRole");
      localStorage.removeItem("user");
      window.location.replace("/auth/login");
    }

    if (error.message.includes("403")) {
      localStorage.removeItem("token");
      localStorage.removeItem("storeUserRole");
      localStorage.removeItem("user");
      localStorage.removeItem("persist:root");
      window.location.replace("/auth/login");
    }

    return Promise.reject(error);
  }
);

export default Axios;
