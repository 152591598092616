import { CloseIcon } from "assets/icons";
import PropTypes from "prop-types";
import React from "react";
import { Modal as RModal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";

export default function Modal(props) {
  return (
    <RModal
      {...props}
      open={props.isActive}
      onClose={props.onClose}
      closeIcon={props.closeIcon}
      styles={{
        modal: {
          maxWidth: props.maxWidth || "unset",
          width: props.width || "",
          minWidth: props.minWidth || "80%",
          padding: "unset",
          margin: "auto",
          borderRadius: "1rem",
          background: props.bgColor,
        },
        overlay: {
          background: "rgba(0, 0, 0, 0.5)",
        },
        closeButton: {
          background: "transparent",
        },
        closeIcon: {
          display: props.showIcon === "none" ? "none" : "",
          color: "white",
        },
      }}
      center
      classNames={props.classNames}
    >
      <div className={props.childClassName}>{props.children}</div>
    </RModal>
  );
}

Modal.propTypes = {
  isActive: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.element,
  closeIcon: PropTypes.element,
  width: PropTypes.string,
  childClassName: PropTypes.string,
};
