import { CancelIcon } from "assets/icons";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

const SearchModalPopup = ({ showOverlay, setShowOverlay }) => {
  const history = useHistory();

  const [search, setSearch] = useState("");

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const searchEvents = () => {
    history.push(`/events?search=${search}`);
    setShowOverlay(false);
  };

  return (
    <div className="overlay-content !tw-m-0 !tw-p-0 !tw-top-0">
      <div
        className="tw-absolute tw-right-10 tw--top-20"
        onClick={() => setShowOverlay(false)}
      >
        <CancelIcon />
      </div>
      <div className="tw-flex tw-items-center tw-justify-center tw-text-white tw-text-center tw-px-6 tw-relative">
        <div>
          <p className="tw-text-2xl tw-font-semibold">
            Discover All Events & Businesses within your city
          </p>
          <div className="tw-flex tw-flex-col tw-items-center tw-w-full tw-gap-4">
            <input
              className="tw-rounded-md tw-bg-white tw-bg-transparent tw-outline-none focus-visible:tw-outline-none tw-border-0 tw-h-full tw-w-full tw-px-4 tw-py-4 tw-text-black"
              placeholder="Enter event name, category or city"
              name="mobile-header-search"
              onChange={handleSearchChange}
            />
            <button
              onClick={searchEvents}
              className="tw-w-48 tw-h-full tw-py-4 tw-px-8 btn-green tw-rounded-md tw-text-base"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchModalPopup;
