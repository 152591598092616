export const HELP_NAV = [
  {
    name: "How event works",
    link: "how-event-works",
  },
  // {
  //   name: "How store works",
  //   link: "how-store-works",
  // },
  // {
  //   name: "How fuel works",
  //   link: "how-fuel-works",
  // },
  // {
  //   name: "Our Fee",
  //   link: "#ourfee",
  // },
  // {
  //   name: "Ticket Reseller Fee",
  //   link: "#ourfee",
  // },
  // {
  //   name: "Refund and Cancellation",
  //   link: "#refund",
  // },
];
