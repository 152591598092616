import "./styles.scss";
import React, { useState } from "react";
import NavDropdown from "react-bootstrap/NavDropdown";

const CustomNavDropdown = (props) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  return (
    <NavDropdown
      {...props}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onToggle={() => setIsClicked(!isClicked)}
      show={isClicked || isHovered}
    />
  );
};

export default CustomNavDropdown;
