export const STEPS_VALUES = {
  EventDetails: 1,
  TicketInformation: 2,
  SettingsAndMedia: 3,
};

export const STEPS = [
  { title: "Event Details", value: STEPS_VALUES.EventDetails },
  { title: "Ticket Information", value: STEPS_VALUES.TicketInformation },
  { title: "Settings & Media", value: STEPS_VALUES.SettingsAndMedia },
];
