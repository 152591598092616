import { Icon } from "@iconify/react";
import CustomNavDropdown from "components/shared/etc/CustomNavDropdown";
import React from "react";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";

const CreateEvent = () => {
  return (
    <div className="navbar-nav flex-row font-weight-normal ml-auto">
      <CustomNavDropdown
        id="more-dropdown"
        title={
          <div className="tw-flex tw-item-center">
            <span className="ml-2 text-accent font-weight-bold">
              Create Event
            </span>
            <Icon icon="fa:angle-down" className="ml-2 tw-mt-1" />
          </div>
        }
        className="tw-text-[#010101] font-weight-bold text-black-link"
      >
        <NavDropdown.Item
          className="tw-text-[#010101] tw-text-lg"
          as={Link}
          to="/dashboard/create"
        >
          Create Event / Sell Ticket
        </NavDropdown.Item>
        <NavDropdown.Item
          className="tw-text-[#010101] tw-text-lg"
          as={Link}
          to="/dashboard/resell-ticket"
        >
          Resell Ticket
        </NavDropdown.Item>
      </CustomNavDropdown>
    </div>
  );
};

export default CreateEvent;
