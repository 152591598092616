import React, { FC, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { cn } from "utilities/cn";

const DefaultMaxSize = 2000000;

const Placeholder = () => {
  return (
    <p className="tw-p-4">
      Drag and drop some files here, or click to select file(s).{" "}
      <span>
        Max file size <span className="tw-font-medium">12MB</span>
      </span>
    </p>
  );
};

const ImageUpload = ({
  addNewImages,
  isSingle,
  singleImageUrl,
  children,
  className,
  imgClassName,
  customPlaceholder,
  maxFiles = 10,
  maxSize = DefaultMaxSize,
  accept,
}) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      addNewImages(acceptedFiles);
    },
    [addNewImages]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles,
    maxSize,
    // ...(accept
    //   ? accept
    //   : {
    //       accept: {
    //         "image/jpeg": [],
    //         "image/png": [],
    //       },
    //     }),
  });

  return (
    <div className="w-full">
      {isSingle ? (
        <div
          className={cn(
            "tw-max-w-full  tw-w-full tw-border tw-text-gray-600 tw-cursor-pointer hover:tw-text-gray-900",
            className
          )}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {children ? (
            children
          ) : (
            <>
              {!singleImageUrl ? (
                <>{customPlaceholder ? customPlaceholder : <Placeholder />}</>
              ) : (
                <img
                  src={singleImageUrl}
                  alt="Images"
                  className={imgClassName}
                />
              )}
            </>
          )}
        </div>
      ) : (
        <div
          className={cn("tw-max-w-full tw-w-full tw-cursor-pointer", className)}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {children ? (
            children
          ) : (
            <>{customPlaceholder ? customPlaceholder : <Placeholder />}</>
          )}
        </div>
      )}
    </div>
  );
};

export default ImageUpload;
