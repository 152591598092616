import axios from "../api-v2";

export const uploadSingleImage = (payload) => {
  return axios.post(`/media/upload`, payload);
};

export const uploadMultipleImages = (payload) => {
  return axios.post(`/media/bulk-upload`, payload);
};

export const fetchBanks = (country = "NG") => {
  return axios.get(`/payment/banks?country=${country}`);
};

export const validateBankAccount = (payload) => {
  return axios.post(`/payment/banks/resolve`, payload);
};

export const withdrawWalletAmount = (payload) => {
  return axios.post(`/wallet/payout`, payload);
};

export const fetchBusinessWalletTransactions = ({
  page,
  perPage,
  customParameters,
}) => {
  return axios.get(
    `/wallet/transactions/?page=${page}&limit=${perPage}${
      customParameters || ""
    }`
  );
};

export const reportWalletTransaction = (payload) => {
  return axios.post(`/transactions/report`, payload);
};
