import App from "./App";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import Auth0 from "components/shared/Auth0";
import ErrorBoundary from "components/shared/ErrorBoundary";
import React from "react";
import ReactDOM from "react-dom";
import Geocode from "react-geocode";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_GEOCODE_KEY);
Geocode.setLanguage("en");
Geocode.setRegion("uk");
Geocode.setLocationType("ROOFTOP");

ReactDOM.render(
  <React.Fragment>
    <ErrorBoundary>
      <Auth0>
        <App />
      </Auth0>
    </ErrorBoundary>
  </React.Fragment>,
  document.getElementById("root")
);

reportWebVitals();
