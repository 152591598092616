const TABLEDATA_STYLE = " tw-py-4 tw-px-6";
const MODAL_ROOT = "tw-max-w-[600px] tw-m-auto";
const MODAL_STYLE = "tw-w-full tw-max-h-fit";
const MODAL_HEADER_STYLE =
  "px-4 py-3 d-flex justify-content-between align-items-center bg-accent text-white";
const MODAL_BODY_STYLE =
  "tw-relative modal-body tw-items-center tw-justify-center tw-text-base tw-overflow-x-hidden";
export {
  TABLEDATA_STYLE,
  MODAL_ROOT,
  MODAL_STYLE,
  MODAL_HEADER_STYLE,
  MODAL_BODY_STYLE,
};
