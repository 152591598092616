import "./styles.scss";
import { DashboardSidebar } from "components/pages/authenticated/user-dashboard/sharedcomponents/Sidebar";
import CreateEventDash from "components/shared/DashCreateEvent";
import EventDashTestHeader from "components/shared/EventDashTestHeader";
import EventDashWithEventHeader from "components/shared/EventDashWithEventHeader";
import EventRecentTransaction from "components/shared/EventRecentTransaction";
import PastEvents from "components/shared/PastEvents";
import RecentEvents from "components/shared/RecentEvents";
import UpcomingEvents from "components/shared/UpcomingEvents";
import AlternateNavBar from "components/shared/partials/AlternateNavBar";
import EventDashRestaurants from "components/shared/partials/EventDashRestaurants";
import React from "react";

const EventDashWithEvent = () => {
  return (
    <>
      <AlternateNavBar />
      <DashboardSidebar />
      <div className="row bg-ev-ds-ts dashboard-wrapper">
        <div className="eventDashTest col-8">
          <EventDashWithEventHeader />
          <CreateEventDash />
          <div className="row ml-1 mt-4">
            <div className="col-6">
              <RecentEvents />
            </div>
            <div className="col-6">
              <PastEvents />
            </div>
          </div>
        </div>
        <div className="col-4">
          <EventRecentTransaction />
        </div>
      </div>
    </>
  );
};

export default EventDashWithEvent;
