import { Icon } from "@iconify/react";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const InputHelpTooltip = ({ children, helpDescription, ...props }) => {
  return helpDescription ? (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id="tooltip">{helpDescription}</Tooltip>}
      {...props}
    >
      <button type="button" className="">
        {children || <Icon icon="ep:question-filled" />}
      </button>
    </OverlayTrigger>
  ) : (
    ""
  );
};

export default InputHelpTooltip;
