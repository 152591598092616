import * as TimezoneList from "components/shared/timezones.json";
import { getAllISOCodes } from "iso-country-currency";
import {
  convertArrayToSelectOptions,
  convertModelArrayToSelectOptions,
} from "utilities";
import * as yup from "yup";

export const EVENT_TYPES = ["Public", "Private"];

export const TIMEZONE_LIST = convertModelArrayToSelectOptions(
  TimezoneList.default,
  "value",
  "label",
  true
);

export const initialValues = {
  title: "",
  description: "",
  type: convertArrayToSelectOptions([EVENT_TYPES[0]])?.[0],
  timezone: TIMEZONE_LIST?.[14],
  landmark: "",
  recurring: false,
};

export const validationSchema = yup.object().shape({
  title: yup.string().label("Title").required(),
  type: yup.object().label("Event visibility (public or private)").required(),
  description: yup.string().label("Description").required(),
  category: yup.object().label("Category").required(),
  venueName: yup.string().label("Venue Name").required(),
  city: yup.string().label("City").required(),
  state: yup.string().label("State").required(),
  country: yup.object().label("country").required(),
  currency: yup.object().label("currency").required(),
  timezone: yup.object().label("timezone").required(),
  // dates: yup
  //   .array()
  //   .of(
  //     yup.object().shape({
  //       startDate: yup.string().required("Required"),
  //       startTime: yup.string().required("Required"),
  //       endDate: yup.string().required("Required"),
  //       endTime: yup.string().required("Required"),
  //     })
  //   )
  //   .required("Must have dates")
  //   .min(3, "Minimum of 1 date"),
});

export const SELECT_CUSTOM_CONTROL_STYLES = {
  padding: "7px",
  borderRadius: "10px",
  border: "1px solid #d1d5db",
};

export const CURRENCY_LIST = getAllISOCodes().map((each) => {
  return {
    id: each.currency,
    label: `${each.currency}`,
  };
});

export const SELECT_FORM_KEYS = [
  "country",
  "category",
  "type",
  "currency",
  "timezone",
  "recurringFrequency",
  "recurringWeek",
];
