import React from "react";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => {
  return (
    <div ref={ref} onClick={(e) => onClick(e)}>
      {children}
    </div>
  );
});

CustomToggle.displayName = "LocationSearchCustomToggle";

export default CustomToggle;
