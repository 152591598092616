import { HELP_NAV } from "../../constants";
import { Icon } from "@iconify/react";
import CustomNavDropdown from "components/shared/etc/CustomNavDropdown";
import React from "react";
import { NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

const Help = () => {
  return (
    <div className="navbar-nav flex-row font-weight-normal ml-auto">
      <CustomNavDropdown
        id="more-dropdown"
        title={
          <div className="tw-flex tw-item-center">
            <span className="ml-2 font-weight-bold tw-text-gray-800">Help</span>
            <Icon icon="fa:angle-down" className="ml-2 tw-mt-1" />
          </div>
        }
        className="tw-text-[#010101] font-weight-bold text-black-link"
      >
        {HELP_NAV.map((nav) => (
          <NavDropdown.Item
            className="tw-text-[#010101] tw-text-lg"
            as={Link}
            to={nav.link}
            key={nav.link}
          >
            {nav.name}
          </NavDropdown.Item>
        ))}
      </CustomNavDropdown>
    </div>
  );
};

export default Help;
