import Avatar from "components/shared/Avatar";
import React from "react";

const DriversRegPanel = ({ name, licenseNumber, driversPhoto }) => {
  return (
    <div
      className="tw-bg-gray-100 tw-mt-2  tw-flex tw-items-center 
     tw-py-1 tw-px-8 tw-rounded-md tw-border tw-cursor-pointer  "
    >
      <div className="tw-my-5 tw-flex tw-gap-5 tw-items-center">
        <Avatar src={driversPhoto} size="lg" />

        <div className="leading-1">
          <h2 className="tw-font-semibold">{name}</h2>
          <p className="tw-text-sm">Driver’s License: {licenseNumber}</p>
        </div>
      </div>
    </div>
  );
};

export default DriversRegPanel;
