import "./styles.scss";
import { Icon } from "@iconify/react";
import UserDash from "assets/images/userDashImg.png";
import React, { useEffect, useState } from "react";

const EventDashTestHeader = () => {
  const [location, setLocation] = useState(null);
  const [countryData, setCountryData] = useState(null);

  useEffect(() => {
    // Get user's geolocation using browser's Geolocation API
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
          fetchCountryData(latitude, longitude);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  const fetchCountryData = async (latitude, longitude) => {
    try {
      const response = await fetch(
        `https://restcountries.com/v3.1/all?fields=name,flags`
      );
      const data = await response.json();
      setCountryData(data[0]);
    } catch (error) {
      console.error("Error fetching country data:", error);
    }
  };
  return (
    <>
      <div className="eventDashTestHeader ml-1 mt-4">
        <div className="headerProfile">
          <div className="headerProfiledetails">
            <h2>Welcome Back!</h2>
            <span>We are happy to have you back again</span>
          </div>
          <hr />
          <div className="headerProfileUser">
            <img src={UserDash} alt="" />
            <div className="nameAndOD">
              <h2>Godwin Tope Dilip</h2>
              <div className="eventLoc">
                {location && countryData ? (
                  <div className="headerLocation">
                    <span>{countryData.flags.emoji}</span>
                    <p>{countryData.name.common}</p>
                  </div>
                ) : (
                  <p>Loading location...</p>
                )}
              </div>
              <div className="eventTypes">
                <span>Music</span>
                <span>Theatre</span>
                <span>Bar</span>
                <span>Restaurant</span>
              </div>
              <div className="eventSocials">
                <Icon icon="logos:facebook" height={20} />
                <Icon icon="skill-icons:twitter" height={20} />
                <Icon icon="skill-icons:instagram" height={20} />
              </div>
            </div>
          </div>
        </div>
        <div className="headerOptions">
          <button className="uAbtn">Update Account</button>
          <div className="headerWalleth">
            <span>Account Wallet</span>
            <Icon icon="mdi:wallet-outline" height={20} color="#fff" />
            <small>Total Amount</small>
            <p> &#8358; 0.00</p>
            <Icon icon="ph:eye" height={20} color="#fff" />
            <button className="walletBalance">Top-up Wallet</button>
            <a href="">
              See Transactions{" "}
              <div className="">
                <Icon icon="ic:round-arrow-outward" height={16} />
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventDashTestHeader;
