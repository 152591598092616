import EmptyEventData from "components/shared/EmptyEventData";
import React from "react";

const EmptyTable = ({
  title = "No Data",
  className,
  description = "",
  value,
  onClick,
}) => {
  return (
    <EmptyEventData
      title={title}
      className={className}
      description={description}
      value={value}
      onClick={onClick}
    />
  );
};

export default EmptyTable;
