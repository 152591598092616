import FormContainer from "../../../FormContainer";
import { useEventFormContext } from "../../../context";
import EventFormImage from "./Image";
import { MAXIMUM_OTHER_PHOTOS } from "./contants";
import { ImageUploadIllustration } from "assets/images";
import { Button } from "components/shared/Button";
import ImageUpload from "components/shared/forms/ImageUpload";
import React from "react";

const MediaForm = ({
  photoUrl,
  otherPhotos,
  addPhotoUrl,
  addOtherPhotos,
  removeFromOtherPhotos,
}) => {
  const { asResellTicket } = useEventFormContext();

  return (
    <FormContainer>
      <p className="tw-font-medium">Media</p>

      <div className="tw-mt-8 tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-3 tw-gap-4">
        <div>
          <p className="tw-text-base tw-font-medium">
            Add {asResellTicket && "Ticket"} Photo{" "}
            <span className="tw-font-normal">[For home page only]</span>
          </p>
          <ImageUpload
            addNewImages={addPhotoUrl}
            singleImageUrl={photoUrl?.url}
            isSingle
            className="tw-rounded-md tw-border-none"
            maxFiles={1}
          >
            {!photoUrl?.url ? (
              <div className="tw-border tw-border-dashed tw-rounded-lg tw-bg-[rgba(32,80,118,0.2)] tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-6 tw-p-10">
                <img src={ImageUploadIllustration} />

                <p>Browse your photos to start uploading.</p>

                <Button
                  className="tw-bg-secondary-800 tw-text-white"
                  type="button"
                >
                  Browse File
                </Button>
              </div>
            ) : (
              <EventFormImage image={photoUrl} canBeRemoved={false} />
            )}
          </ImageUpload>
        </div>

        {otherPhotos.map((photo, Idx) => (
          <EventFormImage
            key={Idx}
            image={photo}
            removeImage={removeFromOtherPhotos}
            className="sm:tw-mt-10"
          />
        ))}

        {MAXIMUM_OTHER_PHOTOS - otherPhotos.length ? (
          <div>
            <p className="tw-text-base tw-font-medium">
              Additional Photos{" "}
              <span className="tw-font-normal">
                [Event details page images - maximum{" "}
                {MAXIMUM_OTHER_PHOTOS - otherPhotos.length}]
              </span>
            </p>
            <ImageUpload
              addNewImages={addOtherPhotos}
              className="tw-rounded-md tw-border-none"
              maxFiles={MAXIMUM_OTHER_PHOTOS - otherPhotos.length}
            >
              <div className="tw-border tw-border-dashed tw-rounded-lg tw-bg-[rgba(32,80,118,0.2)] tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-6 tw-p-10">
                <img src={ImageUploadIllustration} />

                <p>Browse your photos to start uploading.</p>

                <Button
                  className="tw-bg-secondary-800 tw-text-white"
                  type="button"
                >
                  Browse Files
                </Button>
              </div>
            </ImageUpload>
          </div>
        ) : (
          ""
        )}
      </div>
    </FormContainer>
  );
};

export default MediaForm;
