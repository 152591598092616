import LicenseImageForm from "./LicenseImage";
import ProfileImageForm from "./ProfileImage";
import { initialValues, validationSchema } from "./constants";
import PlainFuelStationDashboardLayout from "components/layout/FuelStation/plainFuelStationDashboardContainer";
import { Button } from "components/shared/Button";
import TextInput from "components/shared/forms/TextInput";
import { Field, Form, Formik } from "formik";
import useMedia from "hooks/useMedia";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  createDriverRequest,
  fetchDriverRequest,
  updateDriverRequest,
} from "state/slices/fuel";
import { getAPIErrorsForFormik, getOneLinerErrorMessage } from "utilities";

const FuelBuyerDriversFormPage = ({}) => {
  const { user } = useSelector((state) => state.users.data);
  const { data } = useSelector((state) => state?.fuelCustomers);

  const params = useParams();
  const { id } = params;
  const asEdit = !!id;

  const dispatch = useDispatch();
  const { push } = useHistory();
  const [driver, setDriver] = useState({});

  const {
    singleImage: profilePhotoUrl,
    addImages: addProfilePhotoUrl,
    uploadSingleImage: uploadProfileImage,
    updateImages: updateProfileImage,
  } = useMedia({
    isSingle: true,
    defaultImages: driver?.photoUrl,
  });

  const {
    singleImage: licensePhotoUrl,
    addImages: addLicensePhotoUrl,
    uploadSingleImage: uploadLicenseImage,
    updateImages: updateLicenseImage,
  } = useMedia({
    isSingle: true,
    defaultImages: driver?.licenseUrl,
  });

  const [isFetchingDriver, setIsFetchingDriver] = useState(false);
  const getDriver = useCallback(async () => {
    setIsFetchingDriver(true);

    const resultAction = await dispatch(fetchDriverRequest(id));
    if (fetchDriverRequest.fulfilled.match(resultAction)) {
      setDriver(resultAction?.payload);
      setIsFetchingDriver(false);
      updateProfileImage(resultAction?.payload?.photoUrl);
      updateLicenseImage(resultAction?.payload?.licenseUrl);
    } else {
      setIsFetchingDriver(false);
      toast.error(
        getOneLinerErrorMessage(resultAction.payload) ||
          resultAction.error?.message ||
          "An error occurred, please try again."
      );
    }
  }, [dispatch, id, updateLicenseImage, updateProfileImage]);
  useEffect(() => {
    if (id) getDriver();
  }, [getDriver, id]);

  const [isLoading, setIsLoading] = useState(false);
  const uploadImageRequest = async () => {
    let licenseUrl = "";
    let photoUrl = "";
    try {
      licenseUrl = await uploadLicenseImage();
      photoUrl = await uploadProfileImage();
      return { licenseUrl, photoUrl };
    } catch (error) {
      setIsLoading(false);
      throw new Error(error);
    }
  };

  const createDriver = async (formValues, formikHelpers) => {
    if (!profilePhotoUrl || !licensePhotoUrl) {
      toast.error("Please upload both profile and license images");
      return;
    }

    setIsLoading(true);

    const { licenseUrl, photoUrl } = await uploadImageRequest();

    const payload = {
      data: {
        ...formValues,
        licenseUrl,
        photoUrl,
        ownerId: user?.id,
        ownerType: "COMPANY",
      },
    };

    const resultAction = await dispatch(createDriverRequest(payload.data));
    if (createDriverRequest.fulfilled.match(resultAction)) {
      setIsLoading(false);
      formikHelpers.resetForm();
      toast.success("Successfully added driver");
      push("/dashboard/fuel-station/drivers");
    } else {
      setIsLoading(false);
      if (resultAction.payload.message) {
        toast.error(resultAction.payload.message);
      } else {
        formikHelpers.setErrors(getAPIErrorsForFormik(resultAction.payload));
        toast.error(
          getOneLinerErrorMessage(resultAction.payload) ||
            resultAction.error?.message ||
            "An error occurred, please try again."
        );
      }
    }
  };

  const editDriver = async (formValues, formikHelpers) => {
    if (!profilePhotoUrl || !licensePhotoUrl) {
      toast.error("Please upload both profile and license images");
      return;
    }

    setIsLoading(true);

    const { licenseUrl, photoUrl } = await uploadImageRequest();

    const payload = {
      data: {
        ...formValues,
        licenseUrl,
        photoUrl,
        ownerId: user?.id,
        ownerType: "COMPANY",
      },
    };
    const resultAction = await dispatch(updateDriverRequest(payload.data));
    if (updateDriverRequest.fulfilled.match(resultAction)) {
      setIsLoading(false);
      formikHelpers.resetForm();
      toast.success("Successfully updated driver");
      push("/dashboard/fuel-station/drivers");
    } else {
      setIsLoading(false);
      if (resultAction.payload.message) {
        toast.error(resultAction.payload.message);
      } else {
        formikHelpers.setErrors(getAPIErrorsForFormik(resultAction.payload));
        toast.error(
          getOneLinerErrorMessage(resultAction.payload) ||
            resultAction.error?.message ||
            "An error occurred, please try again."
        );
      }
    }
  };

  return (
    <PlainFuelStationDashboardLayout
      header={asEdit ? "Edit Driver" : "Add Driver"}
    >
      <div className="bg-white tw-mt-4 tw-p-8 tw-border tw-rounded-md">
        <Formik
          initialValues={asEdit ? driver : initialValues}
          validationSchema={validationSchema}
          onSubmit={asEdit ? editDriver : createDriver}
          enableReinitialize
        >
          {({ handleSubmit, isValid }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <div className="tw-flex tw-flex-col tw-gap-4">
                  <div>
                    <ProfileImageForm
                      photoUrl={profilePhotoUrl}
                      addPhotoUrl={addProfilePhotoUrl}
                    />
                  </div>

                  <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-8 tw-gap-y-8">
                    <div>
                      <Field
                        component={TextInput}
                        className="tw-input"
                        name="name"
                        id="name"
                        type="text"
                        label="Driver's Name"
                        placeholder="Driver's Name"
                      />
                    </div>

                    <div>
                      <Field
                        component={TextInput}
                        className="tw-input"
                        name="email"
                        id="email"
                        type="email"
                        label="Driver's Email Id"
                        placeholder="Driver's Email Id"
                      />
                    </div>

                    <div>
                      <Field
                        component={TextInput}
                        className="tw-input"
                        name="phoneNumber"
                        id="phoneNumber"
                        type="tel"
                        label="Driver's Mobile Number"
                        placeholder="Driver's Mobile Number"
                      />
                    </div>

                    <div>
                      <Field
                        component={TextInput}
                        className="tw-input"
                        name="licenseNo"
                        id="licenseNo"
                        type="text"
                        label="Driver's License Number"
                        placeholder="Driver's License Number"
                      />
                    </div>
                  </div>

                  <div>
                    <LicenseImageForm
                      photoUrl={licensePhotoUrl}
                      addPhotoUrl={addLicensePhotoUrl}
                    />
                  </div>
                </div>

                <div className="tw-flex tw-gap-3 tw-mt-16 tw-justify-end">
                  <Button
                    type="submit"
                    className="tw-border-none tw-px-12 tw-py-5 tw-rounded-md tw-bg-secondary-700 tw-text-white"
                    variant="rounded"
                    loading={isLoading}
                    disabled={isLoading || !isValid}
                  >
                    {isLoading ? "Saving..." : asEdit ? "Submit" : "Submit"}
                  </Button>
                  <Button
                    type="button"
                    className="tw-border-none tw-px-12 tw-py-5 tw-rounded-md tw-bg-primary-800 tw-text-white"
                    variant="rounded"
                    onClick={() => push("/dashboard/fuel-station/drivers")}
                    disabled={isLoading}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </PlainFuelStationDashboardLayout>
  );
};

export default FuelBuyerDriversFormPage;
