import EventCard from "../EventCard";
import "./styles.scss";
import { Icon } from "@iconify/react";
import React from "react";

const TESTDATA = [
  {
    id: "T7E3F6",
    platform: "T7E",
    eventName: "ArtMesh: Open Mic & MeetUp for Storytellers",
    category: "Networking",
    isOwner: false,
    eventAddress: "American Corner Ikeja, Lagos, Nigeria",
    city: " Lagos",
    startDate: "Sat, 07 Oct 2023 14:00",
    endDate: "Sat, 07 Oct 2023 17:00",
    eventImage:
      "https://triple7events-dit.s3.amazonaws.com/media/uploaded_files/meetup_for_storytellers_T7E3F6_image.png",
    ticketPrice: "0.00",
    surcharge: "0",
    currCode: "fa-ngn",
    currShort: "NGN",
    currSymbol: "₦",
    isPublic: true,
    availableTickets: true,
    url: "https://stage.triple7events.com/-lagos/events/artmesh-open-mic-meetup-for-storytellers-T7E3F6/",
    isEditable: true,
    isSaved: false,
    stripeConnected: false,
  },
  {
    id: "T7E3FC",
    platform: "T7E",
    eventName: "Suya Groove",
    category: "Food, Drink and Nightlife",
    isOwner: false,
    eventAddress: "Suya Spot, Akerele Street, Lagos, Nigeria",
    city: " Lagos",
    startDate: "Fri, 22 Sep 2023 18:00",
    endDate: "Sat, 23 Sep 2023 11:00",
    eventImage:
      "https://triple7events-dit.s3.amazonaws.com/media/uploaded_files/suya_groove_T7E3FC_image.png",
    ticketPrice: "1165.00",
    surcharge: "165.00",
    currCode: "fa-ngn",
    currShort: "NGN",
    currSymbol: "₦",
    isPublic: true,
    availableTickets: true,
    url: "https://stage.triple7events.com/-lagos/events/suya-groove-T7E3FC/",
    isEditable: false,
    isSaved: false,
    stripeConnected: false,
  },
];

const UpcomingEvents = () => {
  return (
    <>
      <div className="ml-1 mt-4 upEvents">
        <div className="topNav">
          <h1>Upcoming Events</h1>
          <div className="arrowLeft">
            <Icon icon="ep:arrow-left" height={11} />
          </div>
          <div className="list">
            <span>Music</span>
            <span>Theatre</span>
            <span>Concert</span>
            <span>Cinemas</span>
            <span>Tours</span>
          </div>
          <div className="arrowRight">
            <Icon icon="ep:arrow-right" height={11} />
          </div>
        </div>
        <div className="container-fluid main-page-container--padding pt-4">
          <div className="row">
            {/* {TESTDATA.map((event, index) => (
              <div className="col-12 col-md-6 col-lg-4 mb-4" key={index}>
                <EventCard event={event} smallStyle={true} />
              </div>
            ))} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default UpcomingEvents;
