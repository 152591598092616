import React from "react";
import { cn } from "utilities/cn";

const FormContainer = ({ children, className }) => {
  return (
    <div className={cn("tw-max-w-9xl tw-p-10 tw-mx-auto", className)}>
      {children}
    </div>
  );
};

export default FormContainer;
