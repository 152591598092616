import { useEventFormContext } from "../../../context";
import DeleteTicketModal from "./DeleteModal";
import FormTicketModal from "./FormModal";
import FormTicket from "./Ticket";
import { PlusCircleIcon } from "assets/icons";
import { Button } from "components/shared/Button";
import React, { useEffect, useState } from "react";

const FormTickets = () => {
  const { event, updateEvent } = useEventFormContext();

  const [activeTicket, setActiveTicket] = useState({});

  const [openTicketModal, setOpenTicketModal] = useState(false);

  const [openDeleteTicketModal, setOpenDeleteTicketModal] = useState(false);

  const showTicketModal = (ticket) => {
    setActiveTicket(ticket);
    setOpenTicketModal(true);
  };

  const showDeleteTicketModal = (ticket) => {
    setActiveTicket(ticket);
    setOpenDeleteTicketModal(true);
  };

  const [tickets, setTickets] = useState(event?.ticketTypes || []);

  useEffect(() => {
    updateEvent({ ticketTypes: tickets });
  }, [updateEvent, tickets]);

  return (
    <>
      <FormTicketModal
        isOpen={openTicketModal}
        closeModal={() => setOpenTicketModal(false)}
        ticket={activeTicket}
        tickets={tickets}
        asEdit={!!activeTicket}
        updateTickets={setTickets}
      />

      <DeleteTicketModal
        isOpen={openDeleteTicketModal}
        closeModal={() => setOpenDeleteTicketModal(false)}
        ticket={activeTicket || {}}
        event={event}
        updateTickets={setTickets}
      />

      <div>
        <Button
          onClick={() => showTicketModal(null)}
          className="tw-bg-primary-800 tw-p-10 !tw-w-full !tw-h-14 tw-text-white !tw-text-lg tw-flex tw-justify-center"
          type="button"
        >
          <PlusCircleIcon className="tw-w-6 tw-h-6" />
          Add Ticket
        </Button>

        <div className="tw-p-6 tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-3 tw-gap-2">
          {tickets.map((ticket, Idx) => (
            <FormTicket
              key={Idx}
              ticket={ticket}
              updateTicket={() => showTicketModal(ticket)}
              deleteTicket={() => showDeleteTicketModal(ticket)}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default FormTickets;
