import { DELIVERY_TYPES } from "contexts/constants";
import { convertValueToSelectOption } from "utilities";
import * as yup from "yup";

export const TICKET_TYPE_VALUES = {
  Free: "Free",
  Paid: "Paid",
};

export const TICKET_TYPES = [TICKET_TYPE_VALUES.Free, TICKET_TYPE_VALUES.Paid];

export const initialValues = {
  deliveryType: convertValueToSelectOption(DELIVERY_TYPES[0]),
};

export const validationSchema = yup.object().shape({
  name: yup.string().label("Ticket name").required(),
  quantity: yup.string().label("Quantity").required(),
  type: yup.object().label("Ticket Type").required(),
  // price: yup.string().label("Ticket price").required(),
  price: yup
    .string()
    .label("Ticket price")
    .when("type", (type, schema) => {
      if (type?.value !== TICKET_TYPE_VALUES.Free) return schema.required();
      return schema;
    }),
  timeSlot: yup.object().label("Time Slot").required(),
  deliveryType: yup.object().label("Delivery Type").required(),
});

export const TIME_SLOTS = ["All Time"];

export const SELECT_FORM_KEYS = ["timeSlot", "deliveryType", "type"];
