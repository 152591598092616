import { SELECT_CUSTOM_CONTROL_STYLES } from "../constants";
import { countryList } from "./constant";
import { Button } from "components/shared/Button";
import TextInput from "components/shared/forms/TextInput";
import ThemedSelect from "components/shared/forms/ThemedSelect";
import PlaceSuggestionWrapper from "components/shared/placeSuggestionWrapper";
import { Field } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { convertArrayToSelectOptions } from "utilities";
import { cn } from "utilities/cn";

const AddressForm = ({ setFieldValue, event }) => {
  const [searchQuery, setSearchQuery] = useState(event?.address || "");

  const [showFullForm, setShowFullForm] = useState(false);

  const updateVenueFields = (value) => {
    if (value.region && (!value.state || !value.city)) {
      toast.info(
        "Unable to fetch complete address components, please enter address manually"
      );
      setShowFullForm(true);
    }

    setFieldValue("area", `${value.region}`);
    setFieldValue("address", value.street);
    setFieldValue("street", value.streetName);
    setFieldValue("state", value.state);
    setFieldValue("place_id", value.placeId);
    setFieldValue("longitude", value.longitude);
    setFieldValue("latitude", value.latitude);
    setFieldValue("city", value.city);
    setFieldValue("landmark", value.street);

    setFieldValue("country", {
      value: value.country,
      label: value.country,
    });
    setFieldValue("postalCode", value.pincode);

    if (value.countryCurrency) {
      setFieldValue("currency", {
        value: value.countryCurrency?.code,
        label: `${value.countryCurrency?.name} ${value.countryCurrency?.code}`,
      });
    }
  };

  const updateLocation = (value) => {
    setSearchQuery(value.region);
    setFieldValue("address", value.street);
    updateVenueFields(value);
  };

  return (
    <div>
      <div>
        <label className="font-weight-normal tw-ml-4">
          Enter Location <span className="text-red">*</span>
        </label>
        <PlaceSuggestionWrapper
          value={searchQuery}
          showIcon
          onChange={setSearchQuery}
          onSelect={updateLocation}
          inputClassName="text-capitalize !tw-py-1 !tw-border-gray-300"
          className="tw-w-full !tw-ml-0 !tw-px-0 tw-text-base"
          inputPlaceholder="Find address by place or city"
        />
      </div>

      <div
        className={cn(
          "tw-mt-6 tw-flex tw-flex-col tw-gap-6",
          !showFullForm && "tw-hidden"
        )}
      >
        <div>
          <Field
            component={TextInput}
            className="tw-input !tw-border-gray-300"
            name="street"
            id="street"
            placeholder="Enter Street Address"
            type="text"
          />
        </div>

        <div className="tw-grid tw-grid-cols-3 tw-gap-4 md:tw-gap-10">
          <div>
            <Field
              component={TextInput}
              className="tw-input !tw-border-gray-300"
              name="landmark"
              id="landmark"
              placeholder="Enter Landmark"
              type="text"
            />
          </div>

          <div>
            <Field
              component={TextInput}
              className="tw-input !tw-border-gray-300"
              name="city"
              id="city"
              placeholder="Enter City"
              type="text"
              required
            />
          </div>

          <div>
            <Field
              component={TextInput}
              className="tw-input !tw-border-gray-300"
              name="state"
              id="state"
              placeholder="Enter State"
              type="text"
              required
            />
          </div>
        </div>

        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
          <div>
            <Field
              component={ThemedSelect}
              className="mb-3"
              size="base"
              name="country"
              id="country"
              placeholder="Country"
              options={convertArrayToSelectOptions([...countryList])}
              customControlStyle={SELECT_CUSTOM_CONTROL_STYLES}
            />
          </div>

          <div>
            <Field
              component={TextInput}
              className="tw-input !tw-border-gray-300"
              name="postalCode"
              id="postalCode"
              placeholder="Enter Postal Code"
              type="text"
            />
          </div>
        </div>
      </div>

      <div className="tw-mt-3 tw-flex tw-justify-end">
        <Button
          className="tw-bg-secondary-700 tw-shadow-none tw-text-white !tw-rounded-full"
          onClick={() => setShowFullForm((oldValue) => !oldValue)}
          type="button"
        >
          Enter Venue Manually
        </Button>
      </div>
    </div>
  );
};

export default AddressForm;
