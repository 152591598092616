import { TrashIcon } from "assets/icons";
import { Button } from "components/shared/Button";
import React from "react";
import { cn } from "utilities/cn";

const EventFormVideo = ({
  className,
  canBeRemoved = true,
  video,
  removeVideo,
}) => {
  return (
    <div
      className={cn(
        "tw-rounded-md tw-relative tw-max-h-80 tw-overflow-auto tw-overflow-y-hidden",
        className
      )}
    >
      <video width="100%" height="100%" controls>
        <source src={video.url} type="video/mp4" />
        <source src={video.url} type="video/ogg" />
        Your browser does not support the video tag.
      </video>
      {canBeRemoved && (
        <div className="tw-absolute tw-top-0 tw-m-2 tw-right-0">
          <Button
            className="tw-bg-primary-500 tw-text-white xs:tw-rounded-full xs:tw-p-2 lg:tw-rounded-full lg:tw-p-2 tw-max-h-[32px]"
            onClick={() => removeVideo?.(video.url)}
          >
            <TrashIcon className="tw-w-6" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default EventFormVideo;
