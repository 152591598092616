export const restrictionNumber = [...Array(60)].map((each, index) => {
  return { id: index + 1, label: `${index + 1}+` };
});

export const DRESS_CODES = [
  "Formal wear",
  "Semi-formal wear",
  "Informal wear",
  "Business casual",
  "Smart casual",
  "Party Wear",
  "Sports Wear",
  "Swim Wear",
];

export const INVITATION_REQUIREMENTS = [
  {
    id: "StrictlyInvitation",
    label: "Strictly by invitation",
  },
  { id: "Yes", label: "ID required" },
  {
    id: "None",
    label: "No ID required",
  },
];
